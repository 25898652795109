import { connect } from 'react-redux';
import validate from '../../reusables/modals/offerRequestValidation';
import { InputText } from '../../reusables';
import { change, reduxForm } from 'redux-form';
import WarningIcon from '../../reusables/svg/WarningIcon.react.js';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';
import { requestOfferAccessV2 } from '../../../pages/offer/Offer-Actions/Offer-Actions';
import { openModal } from '../../Modal/modal-actions/modal-actions.js';
import config from '../../../config';
import intercom from '../../../utils/intercom';

import 'materialize-css';
import { VAST_PROMO_METHOD_SELECT_DEFAULT_VALUE } from '../../../pages/vast-pre-roll/values.js';
import { useEffect } from 'react';
import SelectCustom from '../../reusables/form/SelectCustom';
import { useState } from 'react';

const FORM_NAME = 'offerRequest';

const OfferRequestModal = (props) => {
  const {
    promotionMethods,
    dispatch,
    offer,
    fields: { options, additionalInfo, website },
    modalInner,
    modalProps,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const vastPreRollModal = modalProps.vastPreRoll;

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };
  const onSendRequest = async (answer_1, answer_2, answer_3) => {
    if (isLoading) {
      return;
    }

    intercom.trackEvent('Request Approval', {
      Brand: offer.name,
      OfferId: offer.id,
    });
    let offerIdPrerequisiteMediaPlacements = null;
    if (offer.media_placements) {
      offerIdPrerequisiteMediaPlacements = offer.media_placements.map((m) => m.name);
    }

    const question_id_1 = config.ID_APPROVAL_QUESTIONS_1;
    const question_id_2 = config.ID_APPROVAL_QUESTIONS_2;
    const question_id_3 = config.ID_APPROVAL_QUESTIONS_3;

    setIsLoading(true);

    try {
      await dispatch(
        requestOfferAccessV2(
          offer.id,
          offerIdPrerequisiteMediaPlacements,
          question_id_1,
          answer_1,
          question_id_2,
          answer_2,
          question_id_3,
          answer_3
        )
      );
      dispatch(
        openModal({
          name: 'OfferRequestSentModal',
          modalProps: {
            dispatch,
          },
        })
      );
      setErrorMessage('');
    } catch (e) {
      setErrorMessage(e);
    }

    setIsLoading(false);
  };

  useClickOutside(modalInner, closeOpenModal);

  const switchResult = (method) => {
    switch (method) {
      case 'Banners':
        return 'What is the website URL where the Banners will be displayed?';
      case 'Browser Extensions':
        return 'What is the Browser Extension download page URL?';
      case 'Domain Redirects':
        return 'What is the website URL of the redirected domain?';
      case 'Contextual':
        return 'What is the website URL where the emails are collected?';
      case 'Emails':
        return 'What is the website URL where the emails are collected?';
      case 'Instant Messaging':
        return 'What is the website URL where the Instant Messaging will be done?';
      case 'Interstitial Ads':
        return 'What is the website URL where the Interstitial Ads will be displayed?';
      case 'IM Ads':
        return 'What is the website URL where the Interstitial Ads will be displayed?';
      case 'Listing':
        return 'What is the website URL where the Listing will be displayed?';
      case 'Overlay':
        return 'What is the website URL where the Overlay will be displayed?';
      case 'Pops':
        return 'What is the website URL where the Pops will be displayed?';
      case 'Posts':
        return 'What is the website URL of the social media account where the posts will be displayed?';
      case 'Pre-rolls':
        return 'What is the website URL where the Pre-Rolls will be displayed?';
      case 'Push Notifications':
        return 'What is the website URL where the Push Notifications option will be displayed?';
      case 'Reviews':
        return 'What is the website URL where the Reviews will be displayed?';
      case 'Rewards':
        return 'What is the website URL where the Rewards will be displayed?';
      case 'SEA':
        return 'What is the website URL where the ads will be displayed?';
      case 'SEO':
        return 'What is the website URL where the Push Notifications option will be displayed?';
      case 'Stories':
        return 'What is the social account URL from which the Stories will be displayed?';
      case 'Tabs':
        return 'What is the website URL where the Tabs will be displayed?';
      case 'Text Messages':
        return 'What is the website URL where the phone numbers are collected?';
      case 'Video Uploads':
        return 'What is the website URL where the Video will be displayed?';
      case 'Watermark':
        return 'What is the website URL where the Watermarked content will be displayed?';
      case 'Widgets':
        return 'What is the website URL where the Widgets will be displayed?';
      default:
        return 'What is the website URL where the ad will be displayed?';
    }
  };

  useEffect(() => {
    if (vastPreRollModal) {
      dispatch(change(FORM_NAME, 'options', VAST_PROMO_METHOD_SELECT_DEFAULT_VALUE));
    }
  }, [vastPreRollModal]);

  return (
    <div id="offer-request-modal">
      <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
        <i className="material-icons">close</i>
      </span>
      <div className="offer-request-body customSelects">
        <div className="titleBox">
          <h2>Apply for approval</h2>
          <div className="subline">
            This offer has high sensitivity that requires us to screen all the traffic to conform to our advertiser&apos;s
            requests.
          </div>
        </div>
        <form
          className="offer-request-questions fields"
          onSubmit={(e) => {
            e.preventDefault();
            const protocol = 'https://';
            const websiteURL = !website.value.match(/^(?:(?:https?|ftp):\/\/)/i) ? protocol + website.value : website.value;
            onSendRequest(options.value, websiteURL, additionalInfo.value);
          }}
        >
          <div className="fieldRow" style={{ display: vastPreRollModal ? 'none' : 'block' }}>
            <SelectCustom
              id="offer-application"
              label="What promotion method will you be using?"
              onChange={options.onChange}
              touched={options.touched}
              value={vastPreRollModal ? VAST_PROMO_METHOD_SELECT_DEFAULT_VALUE : options?.value || ''}
              valueDisabled={'Choose'}
              showSelectedFieldCheckmark={false}
              options={promotionMethods?.map((m, i) => ({ value: m.name, text: m.name }))}
              error={options.error}
            />
          </div>
          <div className="fieldRow">
            <InputText
              field={website}
              id="website"
              label={{
                id: 'website',
                defaultMessage: switchResult(options?.value),
              }}
              labelClasses=""
              placeholder="https://www.example.com"
              type="text"
            />
          </div>
          <div className="fieldRow">
            <InputText
              field={additionalInfo}
              id="additionalInfo"
              label={{
                id: 'additionalInfo',
                defaultMessage: 'Add any other important information we should know to expedite your approval process.',
              }}
              labelClasses=""
              placeholder=" "
              type="text"
            />
          </div>
          <div className="buttonBox">
            <button className="waves-effect waves-light btn" disabled={website.error || options.error || additionalInfo.error}>
              Send my request
            </button>
          </div>
          {errorMessage && <div className="errorMessage">{errorMessage}</div>}
        </form>
      </div>
    </div>
  );
};

export default connect((state) => ({
  promotionMethods: state.application.lists.searchFields.MediaPlacements,
  offer: state.offer.offer,
  modalProps: state.modal.modalProps,
}))(
  reduxForm({
    form: FORM_NAME,
    fields: ['website', 'additionalInfo', 'options'],
    validate,
  })(OfferRequestModal)
);
