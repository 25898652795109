import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { v4 as uuidv4 } from 'uuid';
import CreativeAdTools from '../../components/reusables/creatives/CreativesAdTools';

const { LiveCamWidgetPage } = messages;

const LiveCamWidgetIFrame = ({ src }) => {
  const iconClasses = src ? 'enabled' : 'disabled';

  return (
    <div className="demoIframe z-depth-2" id="widget-iframe">
      {src ? (
        <CreativeAdTools adToolType="postitial" creativeCode={src} isLiveCamWidget />
      ) : (
        <div className="widget-iframe-wrapper">
          <div className={`widget-iframe-container ${iconClasses} desktop`}>
            <div className="iframe-placeholder">
              <div className="placeholder-content livecam-generator-iframe-placeholder">
                <div className="text">
                  <h3>
                    <FormattedHTMLMessage {...LiveCamWidgetPage.widgetPreview.emptyIFrame.header} />
                  </h3>

                  {LiveCamWidgetPage.widgetPreview.emptyIFrame.contents.map((content) => (
                    <p key={uuidv4()}>
                      <FormattedHTMLMessage {...content} />
                    </p>
                  ))}

                  <ul>
                    {LiveCamWidgetPage.widgetPreview.emptyIFrame.mfcPros.map((pro) => (
                      <li key={uuidv4()}>
                        <FormattedHTMLMessage {...pro} />
                      </li>
                    ))}
                  </ul>

                  {LiveCamWidgetPage.widgetPreview.emptyIFrame.infos.map((info) => (
                    <div className="muted" key={uuidv4()}>
                      <FormattedHTMLMessage {...info} />
                    </div>
                  ))}
                </div>
                <div className="video">
                  <div
                    style={{
                      padding: '56.25% 0 0 0',
                      position: 'relative',
                    }}
                  >
                    <iframe
                      allowFullScreen="true"
                      mozallowfullscreen="true"
                      src="https://player.vimeo.com/video/391530959"
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                      }}
                      webkitallowfullscreen="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

LiveCamWidgetIFrame.propTypes = {
  src: PropTypes.string.isRequired,
};

export default LiveCamWidgetIFrame;
