import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { openModal } from '../../components/Modal/modal-actions/modal-actions';
import { TrustPilotMailToSubject } from '../../utils/gamificationHelper';
import badgeBg from './BadgeBg';
import { Gtm } from '../../utils/gtm';

const BadgeCTA = ({ affId, dispatch, gamificationAffProgress, gamificationEventsData, selectedAchievementBadge }) => {
  if (!selectedAchievementBadge || selectedAchievementBadge?.isUnlocked) {
    return null;
  }
  let currentLevel = gamificationAffProgress?.progressData?.streak;
  if (gamificationEventsData?.status === 'end') {
    currentLevel = 0;
  }

  const ctaText = selectedAchievementBadge?.badgeData?.ctaText;

  const gtmEvent = (badge) => {
    Gtm.newEvent('badge_cta', false, 'click', 'string', badge.title);
  };

  const openGamificationModal = () => {
    if (currentLevel > 0 && (gamificationEventsData?.status === 'progress' || gamificationEventsData?.status === 'continue')) {
      dispatch(
        openModal({
          name: 'ContinuationStreakModal',
          modalProps: {
            dispatch,
            className: 'gamification-streak-modal',
          },
        })
      );
    } else if (currentLevel === 0 && gamificationEventsData?.status !== 'progress') {
      dispatch(
        openModal({
          name: 'WeekZeroModal',
          modalProps: {
            dispatch,
            className: 'gamification-streak-modal',
          },
        })
      );
    }
  };

  if (selectedAchievementBadge?.type === 'login_streak') {
    return (
      <button
        className="waves-effect waves-light btn bg-primary-color cta"
        onClick={() => {
          openGamificationModal();
          gtmEvent(selectedAchievementBadge);
        }}
      >
        {ctaText}
      </button>
    );
  } else if (selectedAchievementBadge?.type === 'trust_pilot') {
    return (
      <a href={TrustPilotMailToSubject(affId)} onClick={() => gtmEvent(selectedAchievementBadge)}>
        <button className="waves-effect waves-light btn bg-primary-color cta">{ctaText}</button>
      </a>
    );
  } else {
    if (selectedAchievementBadge?.badgeData?.ctaUrl && selectedAchievementBadge?.badgeData?.ctaUrl[0] === '/') {
      return (
        <Link
          activeClassName="active"
          id="cta-target-selected-badge"
          onClick={() => gtmEvent(selectedAchievementBadge)}
          onlyActiveOnIndex={false}
          to={selectedAchievementBadge?.badgeData?.ctaUrl}
        >
          <button className="waves-effect waves-light btn bg-primary-color cta">{ctaText}</button>
        </Link>
      );
    } else {
      return (
        <a href={selectedAchievementBadge?.badgeData?.ctaUrl} onClick={() => gtmEvent(selectedAchievementBadge)}>
          <button className="waves-effect waves-light btn bg-primary-color cta">{ctaText}</button>
        </a>
      );
    }
  }
};

BadgeCTA.propTypes = {
  dispatch: PropTypes.func.isRequired,
  gamificationAffProgress: PropTypes.shape({
    progressData: PropTypes.shape({
      streak: PropTypes.number,
    }),
  }),
  gamificationEventsData: PropTypes.shape({
    status: PropTypes.string,
  }),
  selectedAchievementBadge: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    level: PropTypes.number,
    isUnlocked: PropTypes.bool,
    badgeData: PropTypes.shape({
      ctaUrl: PropTypes.string,
      ctaText: PropTypes.string,
      progressive: PropTypes.bool,
      style: PropTypes.shape({
        glowColorCode: PropTypes.string,
      }),
      subtype: PropTypes.string,
      textLocked: PropTypes.string,
      textUnlocked: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    }),
  }),
};

export default connect((state) => ({
  gamificationAffProgress: state?.gamification?.eventsData?.notification?.affiliateProgress,
  gamificationEventsData: state?.gamification?.eventsData?.notification,
  affId: state.profile.data.affUserInfos.affiliate_id,
}))(BadgeCTA);
