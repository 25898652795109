import PropTypes from 'prop-types';
import BadgeBg from './BadgeBg';
import SelectedIconMobile from './SelectedIconMobile';
import { getBadgeIconSrc, getBadgeName } from '../../utils/gamificationHelper';
import { PadlockIcon } from './PadlockIcon';
import BadgeOwnership from './BadgeOwnership';
import { SpecialCardFooter } from './SpecialCardFooter';
import React, { useState } from 'react';
import ProgressBar from './ProgressBar';

const GamificationBadge = ({
  badgeId,
  badge,
  currentLevel,
  handleBadgeClick,
  imgClassName,
  selectedBadgeId,
  windowWidthSize,
  logoLevel,
  gamificationBadges,
  firstClickedBadgeId,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const isSelected = selectedBadgeId === badgeId;
  const iconSrc = getBadgeIconSrc(badge);
  const ownBy = (((badge?.badgeData?.affiliate_badge_count ?? 0) / 25000) * 100).toFixed(2);
  const isProgressive = badge.badgeData.progressive || badge.badgeData.subtype === 'upcoming_progressive';
  const isFirstClicked = firstClickedBadgeId === badgeId;
  const canAnimateHover = !isSelected && isHovered && badge?.type !== 'upcoming' && windowWidthSize >= 992;
  const shouldAnimate = isSelected && (!firstClickedBadgeId || !isFirstClicked);
  return (
    <div
      className={`current-gamification-bagde-wrapper `}
      onMouseEnter={() => setIsHovered(!isHovered)}
      onMouseLeave={() => setIsHovered(!isHovered)}
    >
      <div
        className={`current-gamification-bagde ${shouldAnimate ? 'selected' : ''} ${
          badge?.type === 'upcoming' ? 'upcoming-badge' : ''
        }`}
        key={badgeId}
        onClick={() => {
          if (isSelected) {
            handleBadgeClick(null, null);
          } else {
            handleBadgeClick(badgeId, badge);
          }
        }}
      >
        {isSelected && windowWidthSize <= 992 ? <SelectedIconMobile /> : null}
        <div className="badge-box">
          <BadgeBg />
          <img alt="badge" className={`badge ${imgClassName}`} src={iconSrc} />
          {!badge?.isUnlocked ? (
            <div className="padlock">
              <PadlockIcon windowWidthSize={windowWidthSize} />
            </div>
          ) : null}
          {badge?.type === 'login_streak' && badge?.isUnlocked ? (
            <div className={`level ${imgClassName}`}>{badge?.badgeData?.progressive ? currentLevel : badge.level}</div>
          ) : null}
        </div>
        <span className={`badge-name ${badge.badgeData.progressive ? 'progressive' : ''}`}>{getBadgeName(badge)}</span>
        {isProgressive ? (
          <ProgressBar
            badge={badge}
            gamificationBadges={gamificationBadges}
            isUpcoming={badge.type === 'upcoming'}
            logoLevel={logoLevel}
          />
        ) : null}

        <BadgeOwnership
          highlightBox={windowWidthSize < 992}
          isProgressiveAndLocked={!!(badge.badgeData.progressive && !badge?.isUnlocked)}
          isUpcoming={badge.type === 'upcoming'}
          ownBy={ownBy}
          windowWidthSize={windowWidthSize}
        />
        {isProgressive || windowWidthSize < 992 ? null : <div className="special-card-footer">{SpecialCardFooter()}</div>}
      </div>
      {shouldAnimate && windowWidthSize >= 992 ? (
        <div className="glowing-border" />
      ) : !shouldAnimate && windowWidthSize >= 992 && isSelected ? (
        <div className="hover-glowing-border" />
      ) : null}
      {canAnimateHover ? <div className="hover-glowing-border" /> : null}
    </div>
  );
};

GamificationBadge.propTypes = {
  badge: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    level: PropTypes.number,
    isUnlocked: PropTypes.bool,
    badgeData: PropTypes.shape({
      ctaUrl: PropTypes.string,
      progressive: PropTypes.bool,
      style: PropTypes.shape({
        glowColorCode: PropTypes.string,
      }),
      subtype: PropTypes.string,
      textLocked: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      textUnlocked: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      affiliate_badge_count: PropTypes.number,
    }),
  }),
  badgeId: PropTypes.string,
  handleBadgeClick: PropTypes.func,
  imgClassName: PropTypes.string,
  selectedBadgeId: PropTypes.string,
  windowWidthSize: PropTypes.number,
};

export default GamificationBadge;
