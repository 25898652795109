import Elite from './rankingIcon/Elite';
import Active from './rankingIcon/Active';
import Vip from './rankingIcon/VIP';
import RisingStar from './rankingIcon/RisingStar';
import consts from '../../utils/consts';
import RankingIcon from '../../components/reusables/svg/ranking/UpRankingIcon.react';
import React from 'react';
import {
  isActiveRankFromRankStatusName,
  isEliteRankFromRankStatusName,
  isRisingStarRankFromRankStatusName,
  isVIPRankFromRankStatusName,
} from '../../utils/rankingHelper';
import { connect } from 'react-redux';

const AchivementsRankingBox = ({ affStatus, user, affRank }) => {
  const isAffRank = isActiveRankFromRankStatusName(affStatus.rank_status_name);
  const isRisingStarRank = isRisingStarRankFromRankStatusName(affStatus.rank_status_name);
  const isVIPRank = isVIPRankFromRankStatusName(affStatus.rank_status_name);
  const isEliteRank = isEliteRankFromRankStatusName(affStatus.rank_status_name);

  const getAffStatusRankName = (statusRankName) => {
    switch (statusRankName) {
      case 'Active Affiliate':
        return 'Active Affiliate';
      case 'Rising Star Affiliate':
        return 'Rising Star Affiliate';
      case 'VIP Affiliate':
        return 'VIP Affiliate';
      case 'Elite Club':
        return 'Elite Club';
      default:
        return null;
    }
  };
  return (
    <div className="ranking-box">
      <div className="aff-info-box">
        {isEliteRank ? <Elite /> : null}
        {isAffRank ? <Active /> : null}
        {isVIPRank ? <Vip /> : null}
        {isRisingStarRank ? <RisingStar /> : null}
        <div className="info-box">
          <span className="aff-name">{`${user.first_name} ${user.last_name}`}</span>
          <span className="ranking-text">{getAffStatusRankName(affStatus.rank_status_name)}</span>
        </div>
      </div>
      <div className="aff-ranking-box">
        <span className={`ranking-number ${affRank.hidden_rank ? 'top-20-size' : ''}`}>
          {affRank.hidden_rank
            ? `TOP ${consts.DESCENDING_RANKS.HIDDEN_RANK.rank}`
            : affRank.current_aff_rank > 0
            ? affRank.current_aff_rank
            : null}
        </span>
        <span className="rank-indicator">
          {affRank.current_aff_rank > affRank.current_aff_yesterday_rank && (
            <span className="inverse-arrow">
              <RankingIcon />
            </span>
          )}
          {affRank.current_aff_rank < affRank.current_aff_yesterday_rank && <RankingIcon />}
          {affRank.current_aff_rank === affRank.current_aff_yesterday_rank && <i className="material-icons equal">remove</i>}
        </span>
      </div>
    </div>
  );
};

export default connect((state) => ({
  affStatus: state.profile.data.affStatus,
  user: state.profile.data.affUserInfos,
  affRank: state.profile.data.affRank,
}))(AchivementsRankingBox);
