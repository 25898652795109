import { FormattedMessage } from 'react-intl';
import EmailTickReact from '../../../components/reusables/svg/EmailTick.react';
import messages from '../../../i18n/base-en.js';
import { useEffect } from 'react';

const AccountRegistered = () => {
  const LinkBlog = (
    <a href="https://www.crakrevenue.com/blog/">
      <FormattedMessage {...messages.accountRegisteredLinkBlog} />
    </a>
  );

  messages.accountRegisteredReadBlog.values = { LinkBlog };
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      window.location.replace('/login');
    };

    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <div id="simplified-thanks-registering">
      <EmailTickReact />
      <div className="row">
        <h1>
          <FormattedMessage {...messages.accountRegisteredThanks} />
        </h1>
      </div>
      <div className="row">
        <FormattedMessage {...messages.accountRegisteredBeforeAccess} />
      </div>
      <div className="row blog-link">
        <p>
          <FormattedMessage {...messages.accountRegisteredReadBlog} />
        </p>
      </div>
    </div>
  );
};

export default AccountRegistered;
