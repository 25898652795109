import { connect } from 'react-redux';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import SelectedBadge from './achievements/SelectedBadge';
import AchievementBadgeSlider from './achievements/AchievementBadgeSlider';
import { upcomingBadge, upcomingBadgeProgressive } from '../badgeDataConfig';
import { Info } from '../components/reusables';
import AchivementsRankingBox from './achievements/AchivementsRankingBox';
import {
  getSpecialAchievementBadges,
  getStreakAchievementBadges,
  MAX_LOGIN_STREAK_BADGE_LEVEL,
} from '../utils/gamificationHelper';
import { Gtm } from '../utils/gtm';

const Achievements = ({
  affiliateBadges,
  affRank,
  affStatus,
  user,
  gamificationAffProgress,
  gamificationBadges,
  loginStreakBadges,
  badgeDataIsFetching,
  affiliateBadgesIsFetching,
  eventsDataIsFetching,
}) => {
  const [slideRight, setSlideRight] = useState(null);
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);
  const [selectedBadgeId, setSelectedBadgeId] = useState(null);
  const [selectedAchievementBadge, setSelectedAchievementBadge] = useState(null);
  const achievementsContainerRef = useRef(null);
  const selectedBadgeContainerRef = useRef(null);
  const [transformY, setTransformY] = useState(0);
  const [sectionHeight, setSectionHeight] = useState('100%');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [slidersWidth, setSlidersWidth] = useState('100%');
  const [selectedBadgeTimer, setSelectedBadgeTimer] = useState(null);
  const [numberOfProgressiveBadges, setNumberOfProgressiveBadges] = useState(0);
  const [firstClickedBadgeId, setFirstClickedBadgeId] = useState(null);
  const streakAchievementBadges = useMemo(
    () => (!gamificationBadges ? [] : getStreakAchievementBadges(gamificationBadges, affiliateBadges, gamificationAffProgress)),
    [affiliateBadges, gamificationBadges, gamificationAffProgress]
  );

  useEffect(() => {
    setNumberOfProgressiveBadges(windowWidthSize >= 992 ? 6 : 4);
  }, [windowWidthSize]);

  if (streakAchievementBadges.length < numberOfProgressiveBadges) {
    for (let i = 0; i <= numberOfProgressiveBadges - streakAchievementBadges.length; i++) {
      streakAchievementBadges.push(upcomingBadgeProgressive);
    }
  }

  const specialAchievementBadges = useMemo(
    () => (!gamificationBadges ? [] : getSpecialAchievementBadges(gamificationBadges, affiliateBadges, gamificationAffProgress)),
    [gamificationBadges, affiliateBadges, gamificationAffProgress]
  );

  if (specialAchievementBadges.length < 12) {
    for (let i = 0; i <= 12 - specialAchievementBadges.length; i++) {
      specialAchievementBadges.push(upcomingBadge);
    }
  }

  useEffect(() => {
    setHasLoaded(dataLoaded);
  }, [dataLoaded]);

  useEffect(() => {
    setDataLoaded(
      !(affiliateBadgesIsFetching && badgeDataIsFetching && eventsDataIsFetching) &&
        specialAchievementBadges.length >= 12 &&
        streakAchievementBadges.length >= numberOfProgressiveBadges
    );
  }, [
    badgeDataIsFetching,
    affiliateBadgesIsFetching,
    specialAchievementBadges.length,
    streakAchievementBadges,
    numberOfProgressiveBadges,
    eventsDataIsFetching,
  ]);

  useEffect(() => {
    () => {
      selectedBadgeTimer && clearTimeout(selectedBadgeTimer);
    };
  }, [selectedBadgeTimer]);

  const handleBadgeClick = (badgeId, badge) => {
    if (badge?.disabled) {
      return;
    }
    if (selectedBadgeTimer) {
      clearTimeout(selectedBadgeTimer);
    }
    if (!badge) {
      setSelectedBadgeTimer(
        setTimeout(() => {
          setSelectedAchievementBadge(badge);
        }, 700)
      );
    } else {
      setSelectedAchievementBadge(badge);

      if (badge.badgeData.progressive) {
        Gtm.newEvent('progressive_badge', false, 'click', 'string', `${badge.title} - ${badge.level}`);
      } else {
        Gtm.newEvent('signature_badge', false, 'click', 'string', `${badge.title} - ${badge.isUnlocked ? 'unlocked' : 'locked'}`);
      }
    }
    setSelectedBadgeId(badgeId);

    if (selectedBadgeContainerRef.current && achievementsContainerRef.current) {
      const selectedBadgeHeight = selectedBadgeContainerRef.current.offsetHeight;
      const dynamicOffset = selectedBadgeHeight + 20; // 20px gap
      setTransformY(badgeId !== null ? dynamicOffset : 0);
    }

    if (firstClickedBadgeId === null && badgeId !== null) {
      setFirstClickedBadgeId(badgeId);
    } else if (firstClickedBadgeId === badgeId && badgeId !== null) {
      setFirstClickedBadgeId(0);
    }

    if (badgeId === null) {
      setFirstClickedBadgeId(null);
    }

    setSlideRight(badgeId !== null);
    if (slideRight) {
      window.scroll({ top: 0, behavior: 'smooth' });
    } else {
      setTimeout(() => {
        window.scroll({ top: 0, behavior: 'smooth' });
      }, 100);
    }
  };

  useEffect(() => {
    if (windowWidthSize >= 992) {
      const achievementHeight = achievementsContainerRef.current.offsetHeight;
      setSectionHeight(achievementHeight);
    }
  }, [achievementsContainerRef?.current?.offsetHeight, windowWidthSize]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(window.innerWidth);

      if (selectedBadgeContainerRef.current && slideRight) {
        const selectedBadgeHeight = selectedBadgeContainerRef.current.offsetHeight;
        const achievementHeight = achievementsContainerRef.current.offsetHeight;

        const dynamicOffset = selectedBadgeHeight + 20; // 20px gap
        const selectedBadgeWidth = selectedBadgeContainerRef.current.offsetWidth;
        const achievementWidth = achievementsContainerRef.current.offsetWidth;
        setSlidersWidth(achievementWidth - selectedBadgeWidth);
        setTransformY(dynamicOffset);
        if (windowWidthSize <= 992) {
          setSectionHeight(selectedBadgeHeight + achievementHeight);
        }
      }

      if (windowWidthSize >= 992 && !slideRight) {
        setSlidersWidth('100%');
      }

      if (selectedBadgeId === null) {
        const achievementHeight = achievementsContainerRef.current.offsetHeight;
        setSectionHeight(achievementHeight);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [slideRight, windowWidthSize, selectedBadgeId]);

  const currentLevel = gamificationAffProgress?.progressData.streak;
  const logoLevel = currentLevel >= MAX_LOGIN_STREAK_BADGE_LEVEL ? MAX_LOGIN_STREAK_BADGE_LEVEL : currentLevel;
  return (
    <div className="container-fluid app-container" id="achievements">
      <div className="header">
        <h1>
          Achievements
          <Info
            title="Click here for more informations on Achievements"
            url="https://support.crakrevenue.com/knowledge-base/crakrevenue-achievement-program/"
          />
        </h1>
        <AchivementsRankingBox />
      </div>
      <div className="section-container" style={{ height: sectionHeight }}>
        <SelectedBadge
          currentLoginStreak={currentLevel}
          gamificationBadges={loginStreakBadges}
          hasLoaded={hasLoaded}
          logoLevel={logoLevel}
          selectedAchievementBadge={selectedAchievementBadge}
          selectedBadgeContainerRef={selectedBadgeContainerRef}
          slideRight={slideRight}
          windowWidthSize={windowWidthSize}
        />

        <div
          className={`achievements-container ${
            hasLoaded && slideRight !== null ? (slideRight ? 'slide-right' : 'slide-left') : ''
          }`}
          ref={achievementsContainerRef}
          style={{
            transform: windowWidthSize <= 992 ? `translateY(${transformY}px)` : 'unset',
            transition: windowWidthSize <= 992 ? 'transform 0.7s cubic-bezier(0, 1.25, 1, 1)' : 'unset',
          }}
        >
          <AchievementBadgeSlider
            achievementBadges={streakAchievementBadges}
            currentLevel={currentLevel}
            dataLoaded={dataLoaded}
            firstClickedBadgeId={firstClickedBadgeId}
            handleBadgeClick={handleBadgeClick}
            loginStreakBadges={loginStreakBadges}
            logoLevel={logoLevel}
            sectionClassName="streak-section"
            selectedBadgeId={selectedBadgeId}
            slidersWidth={slidersWidth}
            title="Progressive Badges"
            uid="streak"
            windowWidthSize={windowWidthSize}
          />
          <AchievementBadgeSlider
            achievementBadges={specialAchievementBadges}
            dataLoaded={dataLoaded}
            firstClickedBadgeId={firstClickedBadgeId}
            handleBadgeClick={handleBadgeClick}
            logoLevel={logoLevel}
            sectionClassName="special-section"
            selectedBadgeId={selectedBadgeId}
            slideRight={slideRight}
            slidersWidth={slidersWidth}
            title="Signature Badges"
            uid="special"
            useGrid
            windowWidthSize={windowWidthSize}
          />
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  affStatus: state.profile.data.affStatus,
  user: state.profile.data.affUserInfos,
  affInfos: state.profile.data.affInfos,
  gamificationEventsStats: state.gamification.eventsData?.notification?.stats,
  gamificationAffProgress: state.gamification.eventsData?.notification?.affiliateProgress,
  gamificationBadges: state.gamification.gamificationBadges,
  badgeDataIsFetching: state.gamification.badgeDataIsFetching,
  eventsDataIsFetching: state.gamification.eventsDataIsFetching,
  affiliateBadgesIsFetching: state.gamification.affiliateBadgesIsFetching,
  loginStreakBadges: state.gamification.loginStreakBadges,
  affiliateBadges: state.gamification.affiliateBadges,
  affRank: state.profile.data.affRank,
}))(Achievements);
