import { cloneElement, useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/grid';

// Import Swiper modules
import { EffectCoverflow, Navigation, Autoplay, Grid } from 'swiper';

export default function SwiperSlider({
  children,
  verticalsAsString,
  loop = true,
  uid,
  spaceBetween = null,
  slidesPerView = 'auto',
  setIsSlidable,
  isSlidable = true,
  slidersWidth,
  useGrid = false,
  numberOfRows = 2,
  swiperParams = {},
}) {
  const carouselRef = useRef();
  const [active, setActive] = useState(false);
  const currentSlideLink = useRef(null);
  let theChildren = null;
  let childrenCleaned = [];

  if (children) {
    childrenCleaned = [];
    theChildren = children.map((child) => {
      const theChild = child;
      if (theChild.props.className) {
        childrenCleaned.push(
          cloneElement(theChild, {
            ...theChild.props,
            className: theChild.props.className + ' swiper-slide',
          })
        );
      } else {
        childrenCleaned.push(
          cloneElement(theChild, {
            ...theChild.props,
            className: 'swiper-slide',
          })
        );
      }

      return theChild;
    });
  }

  useEffect(() => {
    const checkSlidable = () => {
      if (setIsSlidable) {
        if (!carouselRef.current) {
          return;
        }

        const container = carouselRef.current?.el;
        if (container) {
          const slides = container.querySelectorAll('.swiper-slide');

          const containerWidth = container.clientWidth;
          const totalSlidesWidth = Array.from(slides).reduce((acc, slide) => acc + slide.offsetWidth + spaceBetween, 0);

          if (useGrid) {
            setIsSlidable(totalSlidesWidth / numberOfRows > containerWidth);
          } else {
            setIsSlidable(totalSlidesWidth > containerWidth);
          }
        }
      }
    };

    setTimeout(checkSlidable, 0);

    window.addEventListener('resize', checkSlidable);

    return () => {
      window.removeEventListener('resize', checkSlidable);
    };
  }, [theChildren, spaceBetween, setIsSlidable, useGrid, numberOfRows]);

  useEffect(() => {
    if (slidersWidth && carouselRef.current?.update) {
      carouselRef.current.update();
    }
  }, [slidersWidth]);

  useEffect(() => {
    setActive(false);
  }, [verticalsAsString]);

  useEffect(() => {
    if (!active) {
      setActive(true);
    }
  }, [active]);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.update();
    }
  }, [carouselRef.current]);

  useEffect(() => {
    const swiper = carouselRef.current;

    if (!swiper) {
      return;
    }

    const nextButton = document.querySelector(`.swiper-next${uid ? `-${uid}` : ''}`);
    const prevButton = document.querySelector(`.swiper-prev${uid ? `-${uid}` : ''}`);

    const handleNext = () => swiper.slideNext();
    const handlePrev = () => swiper.slidePrev();

    nextButton.addEventListener('click', handleNext);
    prevButton.addEventListener('click', handlePrev);

    // Cleanup on unmount
    return () => {
      nextButton.removeEventListener('click', handleNext);
      prevButton.removeEventListener('click', handlePrev);
    };
  }, [carouselRef.current]);

  if (!active) {
    return null;
  }

  const shouldHaveTwoRows = children.length > 6;

  return (
    <Swiper
      grabCursor={isSlidable}
      grid={shouldHaveTwoRows && useGrid ? { rows: numberOfRows, fill: 'row' } : undefined}
      loop={loop}
      modules={[EffectCoverflow, Navigation, Autoplay, Grid]}
      noSwiping={isSlidable}
      observeParents
      observeSlideChildren
      onSwiper={(swiper) => (carouselRef.current = swiper)}
      onTouchStart={(event, targetParams) => {
        const targetLink = targetParams?.target?.closest('a')?.href;
        currentSlideLink.current = targetLink;
      }}
      onTransitionEnd={(event) => {
        const threshold = 15;
        let touchLength = event?.touches?.diff;
        touchLength = Math.abs(touchLength);
        const targetLink = currentSlideLink.current;

        if (touchLength && touchLength < threshold && targetLink) {
          window.location.href = targetLink;
        }
      }}
      preventClicks
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      {...swiperParams}
    >
      {theChildren.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
}
