import PropTypes from 'prop-types';
import { useSlider } from './SliderContext.react';
import { Gtm } from '../../../utils/gtm';
import { useEffect, useState } from 'react';

const SignupFormSlide = ({
  children,
  index,
  isSkippable,
  ctaText = 'Next',
  isValid,
  nextFunction,
  title,
  timerBeforeAction = false,
  trackingEvents,
}) => {
  const { nextSlide, previousSlide, totalSlides, currentIndex } = useSlider();
  const [timeLeft, setTimeLeft] = useState(10);

  const mustRead = timerBeforeAction && currentIndex === index && timeLeft > 0;

  useEffect(() => {
    if (mustRead) {
      if (timeLeft > 0) {
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
      }
    }
  }, [index, timeLeft, mustRead]);

  return (
    <div className="field-container col-md-4">
      {title ? <h2>{title}</h2> : null}
      {children}
      <div className="control-container">
        {index !== 0 && (
          <button className="prev-button" onClick={previousSlide} tabIndex={-1}>
            Back
          </button>
        )}
        <button
          className={`${index === 0 ? 'full-width' : ''} next-button btn ${mustRead ? 'loading-btn' : ''}`}
          disabled={mustRead ? true : isValid !== undefined ? !isValid : false}
          onClick={() => {
            if (trackingEvents) {
              for (const t of trackingEvents) {
                Gtm.ga4Event(t.eventName, t.conversion, t.eventParameters);
              }
            }
            if (nextFunction) {
              nextFunction();
            } else {
              nextSlide();
            }
          }}
          tabIndex={-1}
        >
          <span>{ctaText}</span>
        </button>
        {isSkippable ? (
          <button className="next-button" onClick={nextFunction ? nextFunction : nextSlide}>
            Skip
          </button>
        ) : null}
      </div>
      {mustRead ? <span className="time-left">{timeLeft}s</span> : null}
      <div className="pagination">{Math.ceil(((index + 1) / totalSlides) * 100)}%</div>
      <progress className="pagination-progress" max={totalSlides} value={index + 1} />
    </div>
  );
};

SignupFormSlide.propTypes = {
  children: PropTypes.node.isRequired,
  ctaText: PropTypes.string,
  index: PropTypes.number.isRequired,
  isSkippable: PropTypes.bool,
  isValid: PropTypes.bool,
  nextFunction: PropTypes.func,
  timerBeforeAction: PropTypes.bool,
  title: PropTypes.string,
  trackingEvents: PropTypes.array,
};

export default SignupFormSlide;
