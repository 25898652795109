import React from 'react';
import './styles/OfferListingChip.scss';
import { STATUSES } from './data';
import { OfferListingApprovalStatusIcon } from './OfferListingApprovalStatusIcon.react';

const styles = {
  [STATUSES.APPROVED]: { color: '#0E700E', background: '#EAF6ED' },
  [STATUSES.PENDING]: { color: '#FF8705', background: '#FDF2DC' },
  [STATUSES.REJECTED]: { color: '#B10E1C', background: '#FDF3F4' },
  [STATUSES.APPROVAL_REQUIRED]: { color: '#444444', background: '#F8F8F8' },
};

const texts = {
  [STATUSES.APPROVED]: 'Approved',
  [STATUSES.PENDING]: 'Pending',
  [STATUSES.REJECTED]: 'Rejected',
  [STATUSES.APPROVAL_REQUIRED]: 'Required',
};

const OfferListingApprovalStatus = ({ approval_status }) => {
  if (!approval_status) {
    approval_status = STATUSES.APPROVAL_REQUIRED;
  }

  const style = styles[approval_status];
  const text = texts[approval_status];

  return (
    <div className="offerListingChip" style={style}>
      <OfferListingApprovalStatusIcon approval_status={approval_status} />
      {text}
    </div>
  );
};

export default OfferListingApprovalStatus;
