/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { HomeAccountManager } from '../../../pages/home';
import MainNavigationTab from './MainNavigationTab/MainNavigationTab';

import { resetOffer } from '../../../pages/offer/Offer-Actions/Offer-Actions';
import { changeMainMenuState } from '../../Application/actions/application-actions';

import config from '../../../config';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';

import AffiliateResources from '../../reusables/svg/MenuIcons/AffiliateResources';
import Cam from '../../reusables/svg/MenuIcons/Cam';
import Community from '../../reusables/svg/MenuIcons/Community';
import Dashboard from '../../reusables/svg/MenuIcons/Dashboard';
import Dating from '../../reusables/svg/MenuIcons/Dating';
import AI from '../../reusables/svg/MenuIcons/AI';
import KnowledgeBase from '../../reusables/svg/MenuIcons/KnowledgeBase';
import LiveCamWidget from '../../reusables/svg/MenuIcons/LiveCamWidget';
import PaymentHistory from '../../reusables/svg/MenuIcons/PaymentHistory';
import Profile from '../../reusables/svg/MenuIcons/Profile';
import Smartlink from '../../reusables/svg/MenuIcons/Smartlink';
import Statistics from '../../reusables/svg/MenuIcons/Statistics';
import Logout from '../../reusables/svg/MenuIcons/Logout';
import Referrals from '../../reusables/svg/MenuIcons/Referrals';
import JerkmateIcon from '../../reusables/svg/MenuIcons/JerkmateIcon';
import JerkmateNewSticker from '../../reusables/svg/Jerkmate/JerkmateNewSticker';
import { offerHasNewTag } from '../../Jerkmate/helper';
import {
  dispatchsetOffersFromMenuClick,
  dispatchsetSeeAllOffersFromMenuClick,
  setOffersSearchFilters,
} from '../../Offers/actions/offers-actions';
import { JERKMATE_OFFER_TAG } from '../../../pages/jerkmate2/JerkmateOfferProvider';
import Onlyfans from '../../reusables/svg/MenuIcons/OnlyFans';
import NewIcon from '../../reusables/svg/MenuIcons/NewIcon';
import FansRevenue from '../../reusables/svg/MenuIcons/FansRevenue';
import consts from '../../../utils/consts';
import PreRollAds from '../../reusables/svg/MenuIcons/PreRollAds';
import { USE_VAST_PREROLL, VAST_PREROLL_PATH } from '../../../pages/vast-pre-roll/values';
import TrustBox from './TrustpilotBox.react';
import AllIcon from '../../reusables/svg/MenuIcons/AllIcon';
import OffersIcon from '../../reusables/svg/MenuIcons/OffersIcon';
import AchievementsIcon from '../../reusables/svg/MenuIcons/AchievementsIcon';
import { getFeatureFlagValueFromLocalStorage } from '../../../utils/featureFlagHelper';

const SUB_MENU_TYPES = Object.freeze({
  ACCOUNT: 'ACCOUNT',
  OFFERS: 'OFFERS',
  PROMO_TOOLS: 'PROMO_TOOLS',
  OTHER: 'OTHERS',
});

const SUB_MENU_LOCAL_STORAGE_KEY = 'subMenuStatus';

const MainNavigation = ({
  affiliateManager,
  handleLogout,
  isConnected,
  mainMenuIsOpen,
  skinConfigurations,
  dispatch,
  allOffers,
  jerkmateOffers,
  offersIsFetching,
  search,
}) => {
  const { AFFILIATE_MANAGER_DEFAULT_ID, VERTICALS_SUB_MENU } = config;
  const SKIN = SkinConfigurationsUtils.getSkinName(skinConfigurations);
  const MENU_ITEMS = SkinConfigurationsUtils.getMenuItems(SKIN);
  const location = window.location.toString();
  const isDisabled = isConnected ? undefined : 'disabled';
  const classNames = ['z-depth-1', mainMenuIsOpen ? 'open' : undefined, isDisabled];

  const [subMenuOpen, setSubMenuOpen] = useState({
    [SUB_MENU_TYPES.ACCOUNT]: false,
    [SUB_MENU_TYPES.OFFERS]: false,
    [SUB_MENU_TYPES.PROMO_TOOLS]: false,
    [SUB_MENU_TYPES.ACCOUNT]: false,
    [SUB_MENU_TYPES.OTHER]: false,
  });

  const isAccountSectionOpen = subMenuOpen[SUB_MENU_TYPES.ACCOUNT];
  const isOfferSectionOpen = subMenuOpen[SUB_MENU_TYPES.OFFERS];
  const isToolsSectionOpen = subMenuOpen[SUB_MENU_TYPES.PROMO_TOOLS];
  const isOtherSectionOpen = subMenuOpen[SUB_MENU_TYPES.OTHER];

  const setSubMenu = (subMenuName) => {
    const subMenu = subMenuOpen[subMenuName];

    const open = !subMenu;
    const subMenuStatus = {
      ...subMenuOpen,
      [subMenuName]: open,
    };

    setSubMenuOpen(subMenuStatus);

    try {
      const subMenuStatusJSON = JSON.stringify(subMenuStatus);
      localStorage.setItem(SUB_MENU_LOCAL_STORAGE_KEY, subMenuStatusJSON);
    } catch (e) {
      console.error('sub menu local storage not available');
    }
  };

  const [jerkmateOffersCount, setJerkmateOffersCount] = useState(0);

  useEffect(() => {
    let count = 0;

    jerkmateOffers.forEach((offer) => {
      const { tags } = offer;

      const taggedOffer = tags.find(({ name }) => name === JERKMATE_OFFER_TAG);

      if (taggedOffer) {
        count++;
      }
    });

    setJerkmateOffersCount(count);
  }, [jerkmateOffers]);

  const nbCamOffers = [];
  const nbOnlyfansOffers = [];
  const nbDatingOffers = [];
  const nbAIOffers = [];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        dispatch(changeMainMenuState(true));
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (allOffers.length > 0) {
    allOffers.map((offer) => {
      offer.verticals.map((verticals) => {
        switch (verticals.id) {
          case VERTICALS_SUB_MENU.cam:
            nbCamOffers.push(offer);
            break;
          case VERTICALS_SUB_MENU.onlyFans:
            nbOnlyfansOffers.push(offer);
            break;
          case VERTICALS_SUB_MENU.dating:
            nbDatingOffers.push(offer);
            break;
          case VERTICALS_SUB_MENU.ai:
            nbAIOffers.push(offer);
            break;
        }
      });
    });
  }

  const seeAllScrollTo = () => {
    setTimeout(() => {
      document.getElementById('offers-list').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }, 1000);
  };

  const changeMenuState = () => {
    const pageWrap = document.getElementById('page-wrap');
    if (location.includes('jerkmate')) {
      const scrollY = pageWrap.style.top;
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    } else {
      if (!mainMenuIsOpen) {
        pageWrap.style.top = `-${window.scrollY}px`;
        pageWrap.style.position = 'fixed';
      } else {
        pageWrap.style.position = '';
        pageWrap.style.top = '';
      }
    }
  };

  const setOffersFromMenuClick = (verticals) => {
    if (offersIsFetching) {
      return;
    }

    search = {
      ...consts.OFFERS_FIND_ALL,
      filters: {
        ...(search.filters = {
          Vertical: verticals,
          Id: [],
        }),
      },
    };
    dispatch(dispatchsetOffersFromMenuClick(verticals));
    dispatch(setOffersSearchFilters(search));
    dispatch(resetOffer());
    dispatch(dispatchsetSeeAllOffersFromMenuClick(verticals.length === 0));

    if (window.innerWidth <= 992) {
      dispatch(changeMainMenuState(!mainMenuIsOpen));
      changeMenuState();
    }
  };

  const generateStatusManager = () =>
    new SkinConfigurationsUtils(skinConfigurations).getPropertyValue('Menu', 'statusManagerLabel', 'statusManager') ||
    'Your Affiliate Manager';

  const verticalHasNewOffers = (offerLists, isJerkmate = false) =>
    offerLists && offerLists.reduce((acc, offer) => acc || offerHasNewTag(offer), false) ? (
      isJerkmate ? (
        <JerkmateNewSticker />
      ) : (
        <NewIcon />
      )
    ) : null;

  const openSectionIcon = (isOpen) => {
    if (!isOpen) {
      return (
        <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.35225 5.97725C3.55195 5.77755 3.86445 5.7594 4.08466 5.92279L4.14775 5.97725L9 10.8293L13.8523 5.97725C14.052 5.77755 14.3645 5.7594 14.5847 5.92279L14.6477 5.97725C14.8474 6.17695 14.8656 6.48945 14.7022 6.70966L14.6477 6.77275L9.39775 12.0227C9.19805 12.2224 8.88555 12.2406 8.66534 12.0772L8.60225 12.0227L3.35225 6.77275C3.13258 6.55308 3.13258 6.19692 3.35225 5.97725Z"
            fill="#00BD00"
          />
        </svg>
      );
    } else {
      return (
        <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.6477 12.0227C14.448 12.2224 14.1355 12.2406 13.9153 12.0772L13.8523 12.0227L9 7.17075L4.14775 12.0227C3.94805 12.2224 3.63555 12.2406 3.41534 12.0772L3.35225 12.0227C3.15255 11.823 3.1344 11.5105 3.29779 11.2903L3.35225 11.2273L8.60225 5.97725C8.80195 5.77755 9.11445 5.7594 9.33466 5.92279L9.39775 5.97725L14.6477 11.2273C14.8674 11.4469 14.8674 11.8031 14.6477 12.0227Z"
            fill="#00BD00"
          />
        </svg>
      );
    }
  };

  useEffect(() => {
    const updateFooterPosition = () => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (window.innerWidth <= 992) {
        const footer = document.querySelector('.sticky-aff-manager-info');

        if (isIOS) {
          // Detect the viewport's safe-area-inset-bottom and adjust accordingly
          const safeAreaInsetBottom =
            parseInt(getComputedStyle(document.documentElement).getPropertyValue('env(safe-area-inset-bottom)')) || 0;

          if (footer) {
            // Adjust the bottom margin of the footer dynamically
            footer.style.marginBottom = `${80 + safeAreaInsetBottom}px`;
          }
        } else {
          if (footer) {
            // Adjust the bottom margin of the footer dynamically
            footer.style.marginBottom = `50px`;
          }
        }
      }
    };

    updateFooterPosition();

    window.addEventListener('resize', updateFooterPosition);

    return () => {
      window.removeEventListener('resize', updateFooterPosition);
    };
  }, [mainMenuIsOpen]);

  useEffect(() => {
    try {
      let subMenusLocalStorage = localStorage.getItem(SUB_MENU_LOCAL_STORAGE_KEY);
      if (subMenusLocalStorage) {
        subMenusLocalStorage = JSON.parse(subMenusLocalStorage);
        setSubMenuOpen(subMenusLocalStorage);
      }
    } catch (e) {
      //safety fallback: no log needed
    }
  }, []);

  return (
    <>
      <div className={`mainNavigation ${classNames.join(' ')}`} id="main-navigation">
        <nav>
          <div className="scrollable">
            <ul className="mainList">
              {MENU_ITEMS.DASHBOARD_IS_VISIBLE ? (
                <li>
                  <ul>
                    <li className="mainItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-dashboard"
                        onlyActiveOnIndex
                        to="/"
                      >
                        <MainNavigationTab children={<Dashboard />} title="Dashboard" />
                      </Link>
                    </li>
                    <li className="mainItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-statistics"
                        onlyActiveOnIndex={false}
                        to="/statistics"
                      >
                        <MainNavigationTab children={<Statistics />} title="Statistics" />
                      </Link>
                    </li>
                    <li className="mainItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-statistics"
                        onlyActiveOnIndex={false}
                        to="/offers"
                      >
                        <MainNavigationTab children={<OffersIcon />} title="Offers" />
                      </Link>
                    </li>
                    {getFeatureFlagValueFromLocalStorage('menuAchievements') ? (
                      <li className="mainItem">
                        <Link
                          activeClassName="active"
                          className={isDisabled}
                          id="main-navigation-statistics"
                          onlyActiveOnIndex={false}
                          to="/achievements"
                        >
                          <MainNavigationTab children={<AchievementsIcon />} newIcon={<NewIcon />} title="Achievements" />
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {MENU_ITEMS.PROFILE_IS_VISIBLE ? (
                <li className="sub-menu-handle">
                  <span className="sectionHeader" onClick={() => setSubMenu(SUB_MENU_TYPES.ACCOUNT)}>
                    account {openSectionIcon(isAccountSectionOpen)}
                  </span>
                  {isAccountSectionOpen ? (
                    <ul>
                      <li className="sectionItem">
                        <Link
                          activeClassName="active"
                          className={isDisabled}
                          id="main-navigation-payment-history"
                          onlyActiveOnIndex={false}
                          to="payment-history"
                        >
                          <MainNavigationTab children={<PaymentHistory />} title="Payments" />
                        </Link>
                      </li>
                      <li className="sectionItem">
                        <Link
                          activeClassName="active"
                          className={isDisabled}
                          id="main-navigation-account"
                          onlyActiveOnIndex={false}
                          to="/profile"
                        >
                          <MainNavigationTab children={<Profile />} title="Profile" />
                        </Link>
                      </li>
                      <li className="sectionItem">
                        <Link
                          activeClassName="active"
                          className={isDisabled}
                          id="main-navigation-account"
                          onlyActiveOnIndex={false}
                          to="/referrals"
                        >
                          <MainNavigationTab children={<Referrals />} title="Referrals" />
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
              ) : null}
              {MENU_ITEMS.OFFERS_IS_VISIBLE ? (
                <li className="sub-menu-handle">
                  <span className="sectionHeader" onClick={() => setSubMenu(SUB_MENU_TYPES.OFFERS)}>
                    offers {openSectionIcon(isOfferSectionOpen)}
                  </span>
                  {isOfferSectionOpen ? (
                    <ul>
                      <li className="sectionItem">
                        <Link
                          activeClassName="active-pink"
                          className={`${isDisabled} jerkmate-nav`}
                          id="main-navigation-jerkmate"
                          onClick={() => changeMenuState()}
                          onlyActiveOnIndex={false}
                          to="/jerkmate"
                        >
                          <MainNavigationTab
                            children={<JerkmateIcon />}
                            newIcon={verticalHasNewOffers(jerkmateOffers, true)}
                            title={`Jerkmate (${jerkmateOffersCount})`}
                          />
                        </Link>
                      </li>
                      <li className="sectionItem" onClick={() => setOffersFromMenuClick([VERTICALS_SUB_MENU.cam])}>
                        <Link
                          activeClassName="active"
                          className={`${isDisabled} offers-nav`}
                          id="main-navigation-offers"
                          onlyActiveOnIndex={false}
                          to={`/offers?vertical=${VERTICALS_SUB_MENU.cam}`}
                        >
                          <MainNavigationTab
                            children={<Cam />}
                            newIcon={verticalHasNewOffers(nbCamOffers)}
                            title={`Cam (${nbCamOffers.length})`}
                          />
                        </Link>
                      </li>
                      <li className="sectionItem" onClick={() => setOffersFromMenuClick([VERTICALS_SUB_MENU.dating])}>
                        <Link
                          activeClassName="active"
                          className={`${isDisabled} offers-nav`}
                          id="main-navigation-offers"
                          onlyActiveOnIndex={false}
                          to={`/offers?vertical=${VERTICALS_SUB_MENU.dating}`}
                        >
                          <MainNavigationTab
                            children={<Dating />}
                            newIcon={verticalHasNewOffers(nbDatingOffers)}
                            title={`Dating (${nbDatingOffers.length})`}
                          />
                        </Link>
                      </li>
                      <li className="sectionItem" onClick={() => setOffersFromMenuClick([VERTICALS_SUB_MENU.ai])}>
                        <Link
                          activeClassName="active"
                          className={`${isDisabled} offers-nav`}
                          id="main-navigation-offers"
                          onlyActiveOnIndex={false}
                          to={`/offers?vertical=${VERTICALS_SUB_MENU.ai}`}
                        >
                          <MainNavigationTab
                            children={<AI />}
                            newIcon={verticalHasNewOffers(nbAIOffers)}
                            title={`AI (${nbAIOffers.length})`}
                          />
                        </Link>
                      </li>
                      <li className="sectionItem" onClick={() => setOffersFromMenuClick([VERTICALS_SUB_MENU.onlyFans])}>
                        <Link
                          activeClassName="active"
                          className={`${isDisabled} onlyfans-nav`}
                          id="main-navigation-offers"
                          onlyActiveOnIndex={false}
                          to={`/offers?vertical=${VERTICALS_SUB_MENU.onlyFans}`}
                        >
                          {/* eslint-disable-next-line react/jsx-pascal-case */}
                          <MainNavigationTab
                            children={<Onlyfans />}
                            newIcon={verticalHasNewOffers(nbOnlyfansOffers)}
                            title={`OnlyFans (${nbOnlyfansOffers.length})`}
                          />
                        </Link>
                      </li>
                      <li
                        className="seeAllOffers"
                        onClick={() => {
                          setOffersFromMenuClick([]);
                          seeAllScrollTo();
                        }}
                      >
                        <Link
                          activeClassName={location.includes('seeAll') ? 'active' : ''}
                          className={isDisabled}
                          id="main-navigation-offers"
                          onlyActiveOnIndex={false}
                          to="/offers#seeAll"
                        >
                          <MainNavigationTab newIcon={<AllIcon />} title={`All offers (${allOffers.length})`} />
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
              ) : null}
              {MENU_ITEMS.PROMOTOOLS_IS_VISIBLE ? (
                <li className="sub-menu-handle">
                  <span className="sectionHeader" onClick={() => setSubMenu(SUB_MENU_TYPES.PROMO_TOOLS)}>
                    promo tools {openSectionIcon(isToolsSectionOpen)}
                  </span>
                  {isToolsSectionOpen ? (
                    <ul>
                      <li className="sectionItem">
                        <Link
                          activeClassName="active"
                          className={isDisabled}
                          id="main-navigation-smartlinks"
                          onlyActiveOnIndex={false}
                          to="/smartlink"
                        >
                          <MainNavigationTab children={<Smartlink />} title="Smartlinks" />
                        </Link>
                      </li>
                      <li className="sectionItem">
                        <Link
                          activeClassName="active"
                          className={isDisabled}
                          id="main-navigation-live-cam-widget"
                          onlyActiveOnIndex={false}
                          to="/live-cam-widget"
                        >
                          <MainNavigationTab children={<LiveCamWidget />} title="Live Cam Widget" />
                        </Link>
                      </li>
                      {USE_VAST_PREROLL ? (
                        <li className="sectionItem">
                          <Link
                            activeClassName="active"
                            className={isDisabled}
                            id="main-navigation-pre-roll-ads"
                            onlyActiveOnIndex={false}
                            to={`/${VAST_PREROLL_PATH}`}
                          >
                            <MainNavigationTab children={<PreRollAds />} title="Pre-Roll Ads" />
                          </Link>
                        </li>
                      ) : null}
                      <li className="seeAllPromotools">
                        <Link
                          activeClassName="active"
                          className={isDisabled}
                          id="main-navigation-promotools"
                          onlyActiveOnIndex={false}
                          to="promotools"
                        >
                          <MainNavigationTab newIcon={<AllIcon />} title="All Tools" />
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
              ) : null}

              {MENU_ITEMS.KNOWLEDGE_BASE_IS_VISIBLE ? (
                <li className="sub-menu-handle">
                  <span className="sectionHeader" onClick={() => setSubMenu(SUB_MENU_TYPES.OTHER)}>
                    others {openSectionIcon(isOtherSectionOpen)}
                  </span>
                  {isOtherSectionOpen ? (
                    <ul className="last-list">
                      <li className="sectionItem">
                        <a
                          className={isDisabled}
                          href="https://www.reddit.com/r/CrakRevenue/"
                          id="main-navigation-reddit"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <MainNavigationTab children={<Community />} title="Community" />
                        </a>
                      </li>
                      <li className="sectionItem">
                        <a
                          className={isDisabled}
                          href="https://www.crakrevenue.com/affiliate/affiliate-marketing-tools/"
                          id="main-navigation-affiliate-resources"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <MainNavigationTab children={<AffiliateResources />} title="Affiliate Resources" />
                        </a>
                      </li>
                      <li className="sectionItem">
                        <a
                          className={isDisabled}
                          href="https://support.crakrevenue.com/"
                          id="main-navigation-knowledge-base"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <MainNavigationTab children={<KnowledgeBase />} title="Knowledge Base" />
                        </a>
                      </li>
                      <li className="sectionItem">
                        <a
                          className={`${isDisabled} fansrevenue`}
                          href="https://www.fansrevenue.com/?utm_source=crakrevenue&utm_medium=referral&utm_campaign=menulink&r=189324"
                          id="main-navigation-fansrevenue"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <MainNavigationTab children={<FansRevenue />} title="Creator’s Platform" />
                        </a>
                      </li>
                      {window.innerWidth <= 992 && (
                        <li className="sectionItem">
                          <a href="/" id="main-navigation-logout" onClick={handleLogout}>
                            <MainNavigationTab children={<Logout />} title="Logout" />
                          </a>
                        </li>
                      )}
                      <li className="mainItem trust-pilot">
                        <TrustBox />
                      </li>
                    </ul>
                  ) : null}
                </li>
              ) : null}
            </ul>
            {!!affiliateManager && affiliateManager.id !== JSON.stringify(AFFILIATE_MANAGER_DEFAULT_ID) && (
              <ul className="sticky-aff-manager-info">
                <li className="mainItem">
                  <HomeAccountManager
                    affiliateManager={affiliateManager}
                    className="menu-left"
                    isVisible={[]}
                    menuLeft
                    skinConfigurations={skinConfigurations}
                    statusManager={generateStatusManager()}
                  />
                </li>
              </ul>
            )}
          </div>
        </nav>
      </div>
      {window.innerWidth <= 992 && mainMenuIsOpen ? (
        <div
          className="main-nav-dimmer"
          onClick={() => {
            dispatch(changeMainMenuState(false));
            const scrollY = document.getElementById('page-wrap').style.top;
            document.getElementById('page-wrap').style.position = '';
            document.getElementById('page-wrap').style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
          }}
        />
      ) : null}
    </>
  );
};

MainNavigation.propTypes = {
  affiliateManager: PropTypes.any,
  dispatch: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  mainMenuIsOpen: PropTypes.bool.isRequired,
  offersIsFetching: PropTypes.bool.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  affiliateManager: state.profile.data.affiliateManager,
  skinConfigurations: state.skinConfigurations.data,
  allOffers: state.application.lists.allOffers,
  jerkmateOffers: state.jerkmate.offers,
  offersIsFetching: state.offers.offersIsFetching,
  search: state.offers.search,
}))(MainNavigation);
