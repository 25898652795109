export default {
  crakrevenue: {
    id: 'crakrevenue',
    description: 'CrakRevenue',
    defaultMessage: 'CrakRevenue',
  },
  mfc: {
    id: 'mfc',
    description: 'MFC',
    defaultMessage: 'MFC',
  },
  whaleshub: {
    id: 'whaleshub',
    description: 'WhalesHub',
    defaultMessage: 'WhalesHub',
  },
  dynamicMessage: {
    id: 'dynamicMessage',
    description: 'Dynamic Message',
    defaultMessage: '{message}',
  },
  genericTextActive: {
    id: 'genericText.active',
    description: 'Active',
    defaultMessage: 'Active',
  },
  genericTextAdd: {
    id: 'genericText.add',
    description: 'Add',
    defaultMessage: 'Add',
  },
  genericTextAddress: {
    id: 'genericText.address',
    description: 'Address',
    defaultMessage: 'Address',
  },
  genericTextAddress2: {
    id: 'genericText.address2',
    description: 'Address Line 2',
    defaultMessage: 'Address Line 2',
  },
  genericTextAddressAptSuite: {
    id: 'genericText.address2',
    description: 'Apt, Suite, Unit, Building',
    defaultMessage: 'Apt, Suite, Unit, Building',
  },
  genericTextAlignment: {
    id: 'genericText.alignment',
    description: 'Alignment',
    defaultMessage: 'Alignment',
  },
  genericTextMoreColumns: {
    id: 'genericText.moreColumns',
    description: 'More Columns',
    defaultMessage: 'More Columns',
  },
  genericTextMoreInfosOnText: {
    id: 'genericText.moreInfosOnText',
    description: 'More infos on {text}',
    defaultMessage: 'More infos on {text}',
  },
  genericTextMoreDataOptions: {
    id: 'genericText.moreDataOptions',
    description: 'More Data Options',
    defaultMessage: 'More Data Options',
  },
  genericTextMoreFilters: {
    id: 'genericText.moreFilters',
    description: 'More Filters',
    defaultMessage: 'More Filters',
  },
  genericTextAlreadyHaveAnAccount: {
    id: 'genericText.alreadyHaveAnAccount',
    description: 'Already have an account?',
    defaultMessage: 'Already have an account?',
  },
  genericTextAlreadyHaveAnAccountLink: {
    id: 'genericText.alreadyHaveAnAccount',
    description: 'who cares?',
    defaultMessage: 'Hello, now: {link}!',
  },
  genericTextAmount: {
    id: 'genericText.amount',
    description: 'Amount',
    defaultMessage: 'Amount',
  },
  genericInvoice: {
    id: 'genericText.invoice',
    description: 'Details',
    defaultMessage: 'Details',
  },
  genericTextApply: {
    id: 'genericText.apply',
    description: 'Apply',
    defaultMessage: 'Apply',
  },
  genericTextApplyChanges: {
    id: 'genericText.applyChanges',
    description: 'Generic Text - Apply Changes',
    defaultMessage: 'APPLY CHANGES',
  },
  genericTextApplyForApproval: {
    id: 'genericText.applyForApproval',
    description: 'Generic Text - Apply For Approval',
    defaultMessage: 'Apply for approval',
  },
  genericTextRefresh: {
    id: 'genericText.refresh',
    description: 'Refresh',
    defaultMessage: 'Refresh',
  },
  genericTextApps: {
    id: 'genericText.apps',
    description: 'Apps',
    defaultMessage: 'Apps',
  },
  genericTextAsian: {
    id: 'genericText.asian',
    description: 'Asian',
    defaultMessage: 'Asian',
  },
  genericTextBack: {
    id: 'genericText.back',
    description: 'Back',
    defaultMessage: 'Back',
  },
  genericTextBackToDashboard: {
    id: 'genericText.backToDashboard',
    description: 'Generic Text - Back To Dashboard',
    defaultMessage: 'Back to dashboard',
  },
  genericTextBackground: {
    id: 'genericText.background',
    description: 'Background',
    defaultMessage: 'Background',
  },
  genericTextBackgroundCheck: {
    id: 'genericText.backgroundCheck',
    description: 'Background Check',
    defaultMessage: 'Background Check',
  },
  genericTextBBW: {
    id: 'genericText.BBW',
    description: 'BBW',
    defaultMessage: 'BBW',
  },
  genericTextBeauty: {
    id: 'genericText.beauty',
    description: 'Beauty',
    defaultMessage: 'Beauty',
  },
  genericTextBinaryOptions: {
    id: 'genericText.binaryOptions',
    description: 'Binary Options',
    defaultMessage: 'Binary Options',
  },
  genericTextBoth: {
    id: 'genericText.both',
    description: 'Both',
    defaultMessage: 'Both',
  },
  genericTextBrand: {
    id: 'genericText.brand',
    description: 'Brand',
    defaultMessage: 'Brand',
  },
  genericTextBrands: {
    id: 'genericText.brands',
    description: 'Brands',
    defaultMessage: 'Brands',
  },
  genericTextBorder: {
    id: 'genericText.border',
    description: 'Border',
    defaultMessage: 'Border',
  },
  genericTextCams: {
    id: 'genericText.cams',
    description: 'Cams',
    defaultMessage: 'Cams',
  },
  genericTextCasino: {
    id: 'genericText.casino',
    description: 'Casino',
    defaultMessage: 'Casino',
  },
  genericTextCancel: {
    id: 'genericText.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel',
  },
  genericTextAny: {
    id: 'genericText.any',
    description: 'Any',
    defaultMessage: 'Any',
  },
  genericTextCannotReachTheServer: {
    id: 'genericText.cannotReachTheServer',
    description: 'Cannot reach the server',
    defaultMessage: 'Cannot reach the server',
  },
  genericTextCards: {
    id: 'genericText.cards',
    description: 'Cards',
    defaultMessage: 'Cards',
  },
  genericTextChoose: {
    id: 'genericText.choose',
    description: 'Choose',
    defaultMessage: 'Choose',
  },
  genericTextChooseMaxTwoElements: {
    id: 'genericTextChooseMaxTwoElements.choose',
    description: 'Choose (max 2)',
    defaultMessage: 'Choose (max 2)',
  },
  genericTextAvailableOnOtherTemplates: {
    id: 'genericText.availableOnOtherTemplates',
    description: 'Available on other templates',
    defaultMessage: 'Available on other templates',
  },
  genericTextClearAllFilters: {
    id: 'genericText.clearAllFilters',
    description: 'Clear all filters',
    defaultMessage: 'Clear all filters',
  },
  genericTextResetAllFilters: {
    id: 'genericText.resetAllFilters',
    description: 'Reset all filters',
    defaultMessage: 'Reset all filters',
  },
  genericTextCloseFilters: {
    id: 'genericText.closeFilters',
    description: 'Close Filters',
    defaultMessage: 'Close Filters',
  },
  genericTextCity: {
    id: 'genericText.city',
    description: 'City',
    defaultMessage: 'City',
  },
  genericTextCode: {
    id: 'genericText.code',
    description: 'Code',
    defaultMessage: 'Code',
  },
  genericTextCollapsed: {
    id: 'genericText.collapsed',
    description: 'Collapsed',
    defaultMessage: 'Collapsed',
  },
  genericTextCompanyName: {
    id: 'genericText.companyName',
    description: 'Company Name',
    defaultMessage: 'Company Name',
  },
  genericTextContinue: {
    id: 'genericText.continue',
    description: 'Continue',
    defaultMessage: 'Continue',
  },
  genericTextCreatives: {
    id: 'genericText.creatives',
    description: 'Creatives',
    defaultMessage: 'Creatives',
  },
  genericTextCopy: {
    id: 'genericText.copy',
    description: 'Copy',
    defaultMessage: 'Copy',
  },
  genericTextSend: {
    id: 'genericText.send',
    description: 'Send',
    defaultMessage: 'Send',
  },
  genericTextTextCopied: {
    id: 'genericText.textCopied',
    description: 'The text: "{text}" has been added to clipboard',
    defaultMessage: 'The text: "{text}" has been added to clipboard',
  },
  genericTextCountry: {
    id: 'genericText.country',
    description: 'Country',
    defaultMessage: 'Country',
  },
  genericRegistartionNumber: {
    id: 'genericText.registrationNumber',
    description: 'Registration Number',
    defaultMessage: 'Registration Number',
  },
  genericGstTaxId: {
    id: 'genericText.gstTaxId',
    description: 'GST / HST Tax ID',
    defaultMessage: 'GST / HST Tax ID (Same as business number)',
  },
  genericPstTaxId: {
    id: 'genericText.pstTaxId',
    description: 'PST Tax ID',
    defaultMessage: 'PST Tax ID (if applicable)',
  },
  genericTextAcceptedCountry: {
    id: 'genericText.country',
    description: 'Accepted Country',
    defaultMessage: 'Accepted Country',
  },
  genericTextExcludedCountry: {
    id: 'genericText.excludedCountry',
    description: 'Excluded Country',
    defaultMessage: 'Excluded Country',
  },
  genericTextCurrentBalance: {
    id: 'genericText.currentBalance',
    description: 'Current balance',
    defaultMessage: 'Current balance',
  },
  genericTextComments: {
    id: 'genericText.comments',
    description: 'Comments',
    defaultMessage: 'Comments',
  },
  genericTextCustom: {
    id: 'genericText.custom',
    description: 'Custom',
    defaultMessage: 'Custom',
  },
  genericTextDate: {
    id: 'genericText.date',
    description: 'Date',
    defaultMessage: 'Date',
  },
  genericTextDating: {
    id: 'genericText.dating',
    description: 'Dating',
    defaultMessage: 'Dating',
  },
  genericTextDesktop: {
    id: 'genericText.desktop',
    description: 'Desktop',
    defaultMessage: 'Desktop',
  },
  genericTextDelete: {
    id: 'genericText.delete',
    description: 'Delete',
    defaultMessage: 'Delete',
  },
  genericTextDetails: {
    id: 'genericText.details',
    description: 'Details',
    defaultMessage: 'Details',
  },
  genericTextDisplay: {
    id: 'genericText.display',
    description: 'Display',
    defaultMessage: 'Display',
  },
  genericTextEbony: {
    id: 'genericText.ebony',
    description: 'Ebony',
    defaultMessage: 'Ebony',
  },
  genericTextEntertainment: {
    id: 'genericText.entertainment',
    description: 'Entertainment',
    defaultMessage: 'Entertainment',
  },
  genericTextEPC: {
    id: 'genericText.EPC',
    description: 'EPC',
    defaultMessage: 'EPC',
  },
  genericTextEnglish: {
    id: 'genericText.english',
    description: 'English',
    defaultMessage: 'English',
  },
  genericTextEmail: {
    id: 'genericText.email',
    description: 'Email',
    defaultMessage: 'Email',
  },
  genericAffiliateId: {
    id: 'genericText.affiliateId',
    description: 'Affiliate ID',
    defaultMessage: 'Affiliate ID',
  },
  genericTextExpanded: {
    id: 'genericText.expanded',
    description: 'Expanded',
    defaultMessage: 'Expanded',
  },
  genericTextFacebook: {
    id: 'genericText.facebook',
    description: 'Facebook',
    defaultMessage: 'Facebook',
  },
  genericTextFilter: {
    id: 'genericText.filter',
    description: 'Apply Filter',
    defaultMessage: 'Apply Filter',
  },
  genericTextFilters: {
    id: 'genericText.filters',
    description: 'Filters',
    defaultMessage: 'Filters',
  },
  genericTextFilterBy: {
    id: 'genericText.filterBy',
    description: 'Filter by:',
    defaultMessage: 'Filter by:',
  },
  genericTextFirstName: {
    id: 'genericText.firstName',
    description: 'First name',
    defaultMessage: 'First name',
  },
  genericTextFrench: {
    id: 'genericText.french',
    description: 'French',
    defaultMessage: 'French',
  },
  genericTextGaming: {
    id: 'genericText.gaming',
    description: 'Gaming',
    defaultMessage: 'Gaming',
  },
  genericTextGambling: {
    id: 'genericText.gambling',
    description: 'Gambling',
    defaultMessage: 'Gambling',
  },
  genericTextGeneral: {
    id: 'genericText.general',
    description: 'General',
    defaultMessage: 'General',
  },
  genericTextGoal: {
    id: 'genericText.goal',
    description: 'Goal',
    defaultMessage: 'Goal',
  },
  genericTextGoals: {
    id: 'genericText.goals',
    description: 'Goals',
    defaultMessage: 'Goals',
  },
  genericTextHardcore: {
    id: 'genericText.hardcore',
    description: 'Hardcore',
    defaultMessage: 'Hardcore',
  },
  genericTextHealth: {
    id: 'genericText.health',
    description: 'Health',
    defaultMessage: 'Health',
  },
  genericTextId: {
    id: 'genericText.Id',
    description: 'Id',
    defaultMessage: 'Id',
  },
  genericTextInvoices: {
    id: 'genericText.invoices',
    description: 'Invoices',
    defaultMessage: 'Invoices',
  },
  genericTextInstagram: {
    id: 'genericText.instagram',
    description: 'Instagram',
    defaultMessage: 'Instagram',
  },
  genericTextIntroduction: {
    id: 'genericText.introduction',
    description: 'Introduction',
    defaultMessage: 'Introduction',
  },
  genericTextJoinDate: {
    id: 'genericText.joinDate',
    description: 'Join date',
    defaultMessage: 'Join date',
  },
  genericTextLangue: {
    id: 'genericText.langue',
    description: 'Français',
    defaultMessage: 'Français',
  },
  genericTextLanguage: {
    id: 'genericText.language',
    description: 'Language',
    defaultMessage: 'Language',
  },
  genericTextLanguages: {
    id: 'genericText.languages',
    description: 'Languages',
    defaultMessage: 'Languages',
  },
  genericTextLastName: {
    id: 'genericText.lastName',
    description: 'Last name',
    defaultMessage: 'Last name',
  },
  genericTextLast7Days: {
    id: 'genericText.last7Days',
    description: 'Last 7 days',
    defaultMessage: 'Last 7 days',
  },
  genericTextLastMonth: {
    id: 'genericText.lastMonth',
    description: 'Last month',
    defaultMessage: 'Last month',
  },
  genericTextLast3Months: {
    id: 'genericText.last3Months',
    description: 'Last 3 months',
    defaultMessage: 'Last 3 months',
  },
  genericTextLastYear: {
    id: 'genericText.lastYear',
    description: 'Last year',
    defaultMessage: 'Last year',
  },
  genericTextAllTime: {
    id: 'genericText.allTime',
    description: 'All time',
    defaultMessage: 'All time',
  },
  genericTextLink: {
    id: 'genericText.link',
    description: 'Link',
    defaultMessage: 'Link',
  },
  genericTextList: {
    id: 'genericText.list',
    description: 'List',
    defaultMessage: 'List',
  },
  genericTextLogin: {
    id: 'genericText.login',
    description: 'Login',
    defaultMessage: 'Login',
  },
  genericTextLogo: {
    id: 'genericText.logo',
    description: 'Logo',
    defaultMessage: 'Logo',
  },
  genericTextSignUp: {
    id: 'genericText.signUp',
    description: 'Sign Up',
    defaultMessage: 'Sign Up',
  },
  genericTextLogout: {
    id: 'genericText.logout',
    description: 'Logout',
    defaultMessage: 'Logout',
  },
  genericTextMaleEnhancement: {
    id: 'genericText.maleEnhancement',
    description: 'Male Enhancement',
    defaultMessage: 'Male Enhancement',
  },
  genericTextManualSelection: {
    id: 'genericText.manualSelection',
    description: 'Manual selection',
    defaultMessage: 'Manual selection',
  },
  genericTextMaximum: {
    id: 'genericText.maximum',
    description: 'Maximum',
    defaultMessage: 'Maximum',
  },
  genericTextMinimum: {
    id: 'genericText.minimum',
    description: 'Minimum',
    defaultMessage: 'Minimum',
  },
  genericTextMenu: {
    id: 'genericText.menu',
    description: 'Menu',
    defaultMessage: 'Menu',
  },
  genericTextMenuDisplay: {
    id: 'genericText.menuDisplay',
    description: 'Menu display',
    defaultMessage: 'Menu display',
  },
  genericTextMilf: {
    id: 'genericText.milf',
    description: 'Milf',
    defaultMessage: 'Milf',
  },
  genericTextMixed: {
    id: 'genericText.mixed',
    description: 'Mixed',
    defaultMessage: 'Mixed',
  },
  genericTextMinimumPayout: {
    id: 'genericText.minimumPayout',
    description: 'Minimum payout',
    defaultMessage: 'Minimum payout',
  },
  genericTextMobile: {
    id: 'genericText.mobile',
    description: 'Mobile',
    defaultMessage: 'Mobile',
  },
  genericTextMobileApps: {
    id: 'genericText.mobileApps',
    description: 'Mobile Apps',
    defaultMessage: 'Mobile Apps',
  },
  genericTextName: {
    id: 'genericText.name',
    description: 'Name',
    defaultMessage: 'Name',
  },
  genericTextNiche: {
    id: 'genericText.niche',
    description: 'Niche',
    defaultMessage: 'Niche',
  },
  genericTextNoResults: {
    id: 'genericText.noResults',
    description: "There's no results",
    defaultMessage: "There's no results",
  },
  genericTextNoSpecificNiche: {
    id: 'genericText.noSpecificNiche',
    description: 'No specific niche',
    defaultMessage: 'No specific niche',
  },
  genericTextNutrition: {
    id: 'genericText.nutrition',
    description: 'Nutrition',
    defaultMessage: 'Nutrition',
  },
  genericTextNutras: {
    id: 'genericText.nutras',
    description: 'Nutras',
    defaultMessage: 'Nutras',
  },
  genericTextApprovals: {
    id: 'genericText.approvals',
    description: 'Approvals',
    defaultMessage: 'Approvals',
  },
  genericTextNextPayment: {
    id: 'genericText.nextPayment',
    description: 'Next payment',
    defaultMessage: 'Next payment',
  },
  genericTextLastPayment: {
    id: 'genericText.lastPayment',
    description: 'Last payment',
    defaultMessage: 'Last payment',
  },
  genericTextNo: {
    id: 'genericText.no',
    description: 'No',
    defaultMessage: 'No',
  },
  genericTextOffers: {
    id: 'genericText.offers',
    description: 'Offers',
    defaultMessage: 'Offers',
  },
  genericTextOffersCam: {
    id: 'genericText.offersCam',
    description: 'Cam',
    defaultMessage: 'Cam',
  },
  genericTextOffersCBD: {
    id: 'genericText.offersCBD',
    description: 'CBD',
    defaultMessage: 'CBD',
  },
  genericTextOffersDating: {
    id: 'genericText.offersDating',
    description: 'Dating',
    defaultMessage: 'Dating',
  },
  genericTextOffersHealth: {
    id: 'genericText.offersHealth',
    description: 'Health',
    defaultMessage: 'Health',
  },
  genericTextOffersSeeAll: {
    id: 'genericText.offersSeeAll',
    description: 'See All',
    defaultMessage: 'See All',
  },
  genericTextMyOffers: {
    id: 'genericText.myOffers',
    description: 'My Offer Requests',
    defaultMessage: 'My Offer Requests',
  },
  genericTextMyOffersDetails: {
    id: 'genericText.myOffersDetails',
    description:
      'All offers displayed here are offers that require an approval. You can filter by approval status by using the "Status" filter. For offers that are enabled by default and do not require approval, please refer to the ',
    defaultMessage:
      'All offers displayed here are offers that require an approval. You can filter by approval status by using the "Status" filter. For offers that are enabled by default and do not require approval, please refer to the ',
  },
  genericTextMyOffersLink: {
    id: 'genericText.myOffersLink',
    description: 'Offers section',
    defaultMessage: 'Offers section',
  },
  genericTextOn: {
    id: 'genericText.on',
    description: 'of',
    defaultMessage: 'of',
  },
  genericTextOr: {
    id: 'genericText.or',
    description: 'or',
    defaultMessage: 'or',
  },
  genericTextMandatoryInput: {
    id: 'genericText.mandatoryInput',
    description: 'Generic Text - Mandatory Input',
    defaultMessage: ' *',
  },
  genericTextOptionalInput: {
    id: 'genericText.optionalInput',
    description: '(Optional)',
    defaultMessage: '(Optional)',
  },
  genericTextOrganization: {
    id: 'genericText.organization',
    description: 'Organisation',
    defaultMessage: 'Organisation',
  },
  genericTextOther: {
    id: 'genericText.other',
    description: 'Other',
    defaultMessage: 'Other',
  },
  genericTextPassword: {
    id: 'genericText.password',
    description: 'Password',
    defaultMessage: 'Password',
  },
  genericTextCurrentPassword: {
    id: 'genericText.currentPassword',
    description: 'Current Password',
    defaultMessage: 'Current Password',
  },
  genericTextPayment: {
    id: 'genericText.payment',
    description: 'Payment',
    defaultMessage: 'Payment',
  },
  genericTextPayments: {
    id: 'genericText.payments',
    description: 'Payments',
    defaultMessage: 'Payments',
  },
  genericTextPayout: {
    id: 'genericText.payout',
    description: 'Payout',
    defaultMessage: 'Payout',
  },
  genericTextPayoutType: {
    id: 'genericText.payoutType',
    description: 'Payout Type',
    defaultMessage: 'Payout Type',
  },
  genericTopPerformingCountries: {
    id: 'genericText.topPerformingCountries',
    description: 'Performs in',
    defaultMessage: 'Performs in',
  },
  genericTextPaysites: {
    id: 'genericText.paysites',
    description: 'Paysites',
    defaultMessage: 'Paysites',
  },
  genericTextPending: {
    id: 'genericText.pending',
    description: 'Pending',
    defaultMessage: 'Pending',
  },
  genericTextApproved: {
    id: 'genericText.approved',
    description: 'Approved',
    defaultMessage: 'Approved',
  },
  genericTextRejected: {
    id: 'genericText.rejected',
    description: 'Rejected',
    defaultMessage: 'Rejected',
  },
  genericTextNotYetApplied: {
    id: 'genericText.notYetApplied',
    description: 'Not Yet Applied',
    defaultMessage: 'Not Yet Applied',
  },
  genericTextPleaseSpecify: {
    id: 'genericText.pleaseSpecify',
    description: 'Please specify',
    defaultMessage: 'Please specify',
  },
  genericTextPPL: {
    id: 'genericText.PPL',
    description: 'PPL',
    defaultMessage: 'PPL',
  },
  genericTextPPS: {
    id: 'genericText.PPS',
    description: 'PPS',
    defaultMessage: 'PPS',
  },
  genericTextPreview: {
    id: 'genericText.preview',
    description: 'Preview',
    defaultMessage: 'Preview',
  },
  genericTextPreviewModal: {
    id: 'genericText.previewModal',
    description: 'Preview in browser',
    defaultMessage: 'Preview in browser',
  },
  genericTextPreviewType: {
    id: 'genericText.previewType',
    description: 'Preview type:',
    defaultMessage: 'Preview type:',
  },
  genericTextSortingLandingPage: {
    id: 'genericText.sortingLandingPage',
    description: 'Sort by:',
    defaultMessage: 'Sort by:',
  },
  genericTextPrevious: {
    id: 'genericText.previous',
    description: 'Previous',
    defaultMessage: 'Previous',
  },
  genericTextProvince: {
    id: 'genericText.province',
    description: 'Province',
    defaultMessage: 'Province',
  },
  genericTextFormat: {
    id: 'genericText.format',
    description: 'Format',
    defaultMessage: 'Format',
  },
  genericTextRawText: {
    id: 'genericText.rawText',
    description: 'Raw text',
    defaultMessage: 'Raw text',
  },
  genericTextRating: {
    id: 'genericText.rating',
    description: 'Rating',
    defaultMessage: 'Rating',
  },
  genericTextCommunity: {
    id: 'genericText.community',
    description: 'Community',
    defaultMessage: 'Community',
  },
  genericTextRegion: {
    id: 'genericText.region',
    description: 'Region',
    defaultMessage: 'Region',
  },
  genericTextReport: {
    id: 'genericText.report',
    description: 'Report',
    defaultMessage: 'Report',
  },
  genericTextReportName: {
    id: 'genericText.reportName',
    description: 'Report name',
    defaultMessage: 'Report name',
  },
  genericTextRevshare: {
    id: 'genericText.revshare',
    description: 'Revshare',
    defaultMessage: 'Revshare',
  },
  genericTextSave: {
    id: 'genericText.save',
    description: 'Save',
    defaultMessage: 'Save',
  },
  genericTextSaveChanges: {
    id: 'genericText.saveChanges',
    description: 'Save changes',
    defaultMessage: 'Save changes',
  },
  genericTextSearch: {
    id: 'genericText.search',
    description: 'Search',
    defaultMessage: 'Search',
  },
  genericTextSettings: {
    id: 'genericText.settings',
    description: 'Settings',
    defaultMessage: 'Settings',
  },
  genericTextSignIn: {
    id: 'genericText.signIn',
    description: 'Sign in',
    defaultMessage: 'Sign in',
  },
  genericTextSize: {
    id: 'genericText.size',
    description: 'Size',
    defaultMessage: 'Size',
  },
  genericTextSkype: {
    id: 'genericText.skype',
    description: 'Skype',
    defaultMessage: 'Skype',
  },
  genericTextSnapchat: {
    id: 'genericText.snapchat',
    description: 'Snapchat',
    defaultMessage: 'Snapchat',
  },
  genericTextState: {
    id: 'genericText.state',
    description: 'State',
    defaultMessage: 'State',
  },
  genericTextIframeSize: {
    id: 'genericText.iframeSize',
    description: 'iFrame dimensions',
    defaultMessage: 'iFrame dimension',
  },
  genericTextIframeDetails: {
    id: 'genericText.iframeDetails',
    description: 'Details',
    defaultMessage: 'Details',
  },
  genericTextStatus: {
    id: 'genericText.status',
    description: 'Status',
    defaultMessage: 'Status',
  },
  genericTextDueDate: {
    id: 'genericText.dueDate',
    description: 'Due Date',
    defaultMessage: 'Due Date',
  },
  genericTextAcceptedPromotionMethod: {
    id: 'genericText.acceptedPromotionMethod',
    description: 'Accepted Promotion Method',
    defaultMessage: 'Accepted Promotion Method',
  },
  genericTextMedia: {
    id: 'genericText.status',
    description: 'Status',
    defaultMessage: 'Status',
  },
  genericTextPaid: {
    id: 'genericText.paid',
    description: 'Paid',
    defaultMessage: 'Paid',
  },
  genericTextStreetAddress: {
    id: 'genericText.streetAddress',
    description: 'Street address',
    defaultMessage: 'Street address',
  },
  genericTextSuccess: {
    id: 'genericText.success',
    description: 'Success',
    defaultMessage: 'Success',
  },
  genericTextCarriedOver: {
    id: 'genericText.carriedOver',
    description: 'Carried Over',
    defaultMessage: 'Carried Over',
  },
  genericTextSurveys: {
    id: 'genericText.surveys',
    description: 'Surveys',
    defaultMessage: 'Surveys',
  },
  genericTextTags: {
    id: 'genericText.Tags',
    description: 'Tags',
    defaultMessage: 'Tags',
  },
  genericTextTargeting: {
    id: 'genericText.targeting',
    description: 'Targeting',
    defaultMessage: 'Targeting',
  },
  genericTextTaxId: {
    id: 'genericText.taxId',
    description: 'Tax ID',
    defaultMessage: 'Tax ID',
  },
  genericTextTeens: {
    id: 'genericText.teens',
    description: 'Teens',
    defaultMessage: 'Teens',
  },
  genericTextTermsAndConditions: {
    id: 'genericText.termsAndConditions',
    description: 'Terms & conditions',
    defaultMessage: 'Terms & conditions',
  },
  genericTextTitleInOrganisation: {
    id: 'genericText.titleInOrganisation',
    description: 'Title / Function in organisation',
    defaultMessage: 'Title / Function in organisation',
  },
  genericTextToggleMenu: {
    id: 'genericText.toggleMenu',
    description: 'Toggle menu',
    defaultMessage: 'Toggle menu',
  },
  genericTextTwitter: {
    id: 'genericText.twitter',
    description: 'Twitter',
    defaultMessage: 'Twitter',
  },
  genericTextType: {
    id: 'genericText.type',
    description: 'Type',
    defaultMessage: 'Type',
  },
  genericTextText: {
    id: 'genericText.text',
    description: 'Text',
    defaultMessage: 'Text',
  },
  genericTextVertical: {
    id: 'genericText.certical',
    description: 'Vertical',
    defaultMessage: 'Vertical',
  },
  genericTextViewMode: {
    id: 'genericText.viewMode',
    description: 'View mode',
    defaultMessage: 'View mode',
  },
  genericTextViewSite: {
    id: 'genericText.viewSite',
    description: 'View site',
    defaultMessage: 'View site',
  },
  genericTextWebsite: {
    id: 'genericText.website',
    description: 'Website',
    defaultMessage: 'Website',
  },
  genericTextYes: {
    id: 'genericText.yes',
    description: 'Yes',
    defaultMessage: 'Yes',
  },
  genericTextZipCode: {
    id: 'genericText.zipCode',
    description: 'Zip code',
    defaultMessage: 'Zip code',
  },
  genericTextPostalCode: {
    id: 'genericText.postalCode',
    description: 'Postal code',
    defaultMessage: 'Postal code',
  },
  genericTextSeeDetails: {
    id: 'genericText.seeDetails',
    description: 'See Details',
    defaultMessage: 'See Details',
  },
  genericTextAll: {
    id: 'genericText.all',
    description: 'All',
    defaultMessage: 'All',
  },
  genericTextDefault: {
    id: 'genericText.default',
    description: 'Default',
    defaultMessage: 'Default',
  },
  genericTextAgree: {
    id: 'genericText.agree',
    description: 'Agree',
    defaultMessage: 'Agree',
  },
  genericTextReset: {
    id: 'genericText.reset',
    description: 'Reset',
    defaultMessage: 'Reset',
  },
  genericTextSelectAll: {
    id: 'genericText.selectAll',
    description: 'Select All',
    defaultMessage: 'Select All',
  },
  genericTextUnselectAll: {
    id: 'genericText.unselectAll',
    description: 'Unselect All',
    defaultMessage: 'Unselect All',
  },
  genericTextPrivacyPolicy: {
    id: 'genericText.privacyPolicy',
    description: 'Privacy policy',
    defaultMessage: 'Privacy policy',
  },
  genericTextKnowledgeBase: {
    id: 'genericText.KnowledgeBase',
    description: 'Knowledge Base',
    defaultMessage: 'Knowledge Base',
  },
  genericTextAffiliateResources: {
    id: 'genericText.AffiliateResources',
    description: 'Affiliate Resources',
    defaultMessage: 'Affiliate Resources',
  },
  genericTextSupport: {
    id: 'genericText.Support',
    description: 'Support',
    defaultMessage: 'Support',
  },
  genericTextNoFraudOrAbuse: {
    id: 'genericText.noFraudOrAbuse',
    description:
      'I understand that my account will be terminated and all commissions forfeited if I fraudulently generate leads, sales, or spam.',
    defaultMessage:
      'I understand that my account will be terminated and all commissions forfeited if I fraudulently generate leads, sales, or spam.',
  },
  genericTextBlog: {
    id: 'genericText.blog',
    description: 'Blog',
    defaultMessage: 'Blog',
  },
  genericTextBackOffers: {
    id: 'genericText.backOffers',
    description: 'Back Offers',
    defaultMessage: 'Back Offers',
  },
  genericTextExtraEarnings: {
    id: 'genericText.earnings',
    description: 'EXTRA EARNINGS',
    defaultMessage: 'EXTRA EARNINGS',
  },
  genericTextExtraEarningsDescription: {
    id: 'genericText.earningsDescription',
    description: 'Generic - Extra Earning Description',
    defaultMessage: '(To disable this, uncheck the following boxes)',
  },
  genericTextPopUnders: {
    id: 'genericText.popUners',
    description: 'Pop-Unders',
    defaultMessage: 'Pop-Unders',
  },
  genericTextByDay: {
    id: 'genericText.byDay',
    description: 'By Day',
    defaultMessage: '/ day',
  },
  genericTextToday: {
    id: 'genericText.today',
    description: 'Today',
    defaultMessage: 'Today',
  },
  genericTextYesterday: {
    id: 'genericText.yesterday',
    description: 'Yesterday',
    defaultMessage: 'Yesterday',
  },
  genericTextThisPeriod: {
    id: 'genericText.thisPeriod',
    description: 'This period',
    defaultMessage: 'This period',
  },
  genericTextLastPeriod: {
    id: 'genericText.lastPeriod',
    description: 'Last Period',
    defaultMessage: 'Last period',
  },
  genericTextImA: {
    id: 'genericText.imA',
    description: "I'm a",
    defaultMessage: "I'm a",
  },
  genericTextImAn: {
    id: 'genericText.imAn',
    description: "I'm an",
    defaultMessage: "I'm an",
  },
  genericTextAdvertiser: {
    id: 'genericText.advertiser',
    description: 'advertiser',
    defaultMessage: 'advertiser',
  },
  genericTextAffiliate: {
    id: 'genericText.affiliate',
    description: 'affiliate',
    defaultMessage: 'affiliate',
  },
  genericTextIGotIt: {
    id: 'genericText.iGotIt',
    description: 'I got it!',
    defaultMessage: 'I got it!',
  },
  genericTextDone: {
    id: 'genericText.done',
    description: 'Done',
    defaultMessage: 'Done',
  },
  genericTextDontRemindMe: {
    id: 'genericText.dontRemindMe',
    description: "Don't remind me",
    defaultMessage: "Don't remind me",
  },
  genericTextPayoutTypes: {
    cpa_flat: {
      id: 'genericText.payoutTypesCPAFlat',
      description: 'CPA Flat',
      defaultMessage: 'CPA',
    },
    cpa_percentage: {
      id: 'genericText.payoutTypesCPAPercentage',
      description: 'CPA Percentage',
      defaultMessage: 'Revshare',
    },
    cpa_both: {
      id: 'genericText.payoutTypesCPABoth',
      description: 'CPA Both',
      defaultMessage: 'CPA',
    },
  },
  genericTextEnable: {
    id: 'genericText.enable',
    description: 'Enable',
    defaultMessage: 'Enable',
  },
  genericTextDisable: {
    id: 'genericText.disable',
    description: 'Disable',
    defaultMessage: 'Disable',
  },
  genericTextVideos: {
    id: 'genericTextVideos',
    description: 'Videos',
    defaultMessage: 'Videos',
  },
  genericTextImages: {
    id: 'genericTextImages',
    description: 'Images',
    defaultMessage: 'Images',
  },
  genericTextPx: {
    id: 'genericTextPx',
    description: 'Pixel unit',
    defaultMessage: 'px',
  },
  genericTextPercentage: {
    id: 'genericTextPercentage',
    description: 'Percentage unit',
    defaultMessage: '&percnt;',
  },
  genericTextAffiliateAccountUpdate: {
    id: 'genericText.affiliateAccountUpdate',
    description: 'Profile Update',
    defaultMessage: 'Profile Update',
  },
  genericTextNotifications: {
    id: 'genericText.notifications',
    description: 'Notifications',
    defaultMessage: 'Notifications',
  },
  genericTextApprovalRequired: {
    id: 'genericText.approvalRequired',
    description: 'Approval Required',
    defaultMessage: 'Approval Required',
  },
  genericTextAudience: {
    id: 'genericText.audience',
    description: 'Audience',
    defaultMessage: 'Audience',
  },
  genericTextShow: {
    id: 'genericText.show',
    description: 'Show',
    defaultMessage: 'Show',
  },
  genericTextRewards: {
    id: 'genericText.Rewards',
    description: 'Rewards',
    defaultMessage: 'Rewards',
  },
  genericTextSmartLink: {
    id: 'genericText.SmartLink',
    description: 'Smartlinks',
    defaultMessage: 'Smartlinks',
  },
  genericTextStep: {
    id: 'genericText.step',
    description: 'Step',
    defaultMessage: 'Step',
  },
  genericTextYourCode: {
    id: 'genericText.yourCode',
    description: 'Your code',
    defaultMessage: 'Your code',
  },
  genericTextYourLink: {
    id: 'genericText.yourLink',
    description: 'Your link',
    defaultMessage: 'Your link',
  },
  genericTextTryItNow: {
    id: 'genericText.tryItNow',
    description: 'Try it now',
    defaultMessage: 'Try it now',
  },
  genericTextNetworkName: {
    id: 'genericText.networkName',
    description: 'Network name',
    defaultMessage: 'Network name',
  },
  genericTextPromoCode: {
    id: 'genericText.promoCode',
    description: 'Promo code',
    defaultMessage: 'Promo code',
  },
  genericTextConfirmation: {
    id: 'genericText.confirmation',
    description: 'Confirmation',
    defaultMessage: 'Confirmation',
  },
  genericTextPayoutDetails: {
    id: 'genericText.payoutDetails',
    description: 'Payout details',
    defaultMessage: 'Payout details',
  },
  genericTextSomethingWrong: {
    id: 'genericTextSomethingWrong',
    description: 'Something went wrong... please contact support.',
    defaultMessage: 'Something went wrong... please contact support.',
  },
  genericTextWarning: {
    id: 'genericText.warning',
    description: 'Warning',
    defaultMessage: 'Warning',
  },
  genericTextClearAll: {
    id: 'genericText.clearAll',
    description: 'Clear all',
    defaultMessage: 'Clear all',
  },
  genericTextGoToBillingInformation: {
    id: 'genericText.GoToBillingInformation',
    description: 'Go to billing infos',
    defaultMessage: 'Go to billing infos',
  },
  genericTextGoToIdentity: {
    id: 'genericText.GoToIdentity',
    description: 'Verify my identity',
    defaultMessage: 'Verify my identity',
  },
  genericTextAccessToProfileIdentity: {
    id: 'genericText.AccessToProfileIdentity',
    description: 'Access Profile',
    defaultMessage: 'Access Profile',
  },
  genericTextGoToUserDetails: {
    id: 'genericText.GoToUserDetails',
    description: 'Go to user details',
    defaultMessage: 'Go to user details',
  },
  genericTextErrorContactSupport: {
    id: 'genericText.contactSupport',
    description: 'Something went wrong, please contact support.',
    defaultMessage: 'Something went wrong, please contact support.',
  },
  genericTextIntercomErrorMessage: {
    id: 'genericText.intercomErrorMessage',
    description: 'intent, location and error',
    defaultMessage: 'Intent: {intent}\nLocation: {location}\nError: {error}',
  },
  genericTextApplyOfferReturn: {
    id: 'genericText.ApplyOfferReturn',
    description:
      'Our team will review your application within the next 2 business days. You will receive a notification of the status of your request directly in the platform.',
    defaultMessage:
      'Our team will review your application within the next 2 business days. You will receive a notification of the status of your request directly in the platform.',
  },
  genericTextComingSoon: {
    id: 'genericText.ComingSoon',
    description: 'Coming soon!',
    defaultMessage: 'Coming soon!',
  },
  genericTextReadMore: {
    id: 'genericText.ReadMore',
    description: 'Read more',
    defaultMessage: 'Read more',
  },
  genericTextErrorWhileLoadingImage: {
    id: 'genericText.ErrorWhileLoadingImage',
    description: 'Error while loading image',
    defaultMessage: 'Error while loading image',
  },
  genericTextImportant: {
    id: 'genericText.Important',
    description: 'Generic Text - Important',
    defaultMessage: 'Important',
  },
  genericTextEmptyLabel: {
    id: 'genericText.emptyLabel',
    description: 'Generic Text - Empty Label',
    defaultMessage: ' ',
  },
  genericTextErrorStats: {
    id: 'genericText.errorStats',
    description: 'Generic Text - Error Stats',
    defaultMessage: 'Sorry, error loading stats please refresh.',
  },
  genericTextPromoTools: {
    id: 'genericText.promoTools',
    description: 'Promo Tools',
    defaultMessage: 'Promo Tools',
  },
  genericTextJerkmate: {
    id: 'genericText.Jerkmate',
    description: 'Jerkmate',
    defaultMessage: 'Jerkmate',
  },
  vastPreRoll: {
    id: 'genericText.vastPreRoll',
    description: 'VAST Tag Pre-Roll Ads',
    defaultMessage: 'VAST Tag Pre-Roll Ads',
  },
  achievements: {
    id: 'genericText.achievements',
    description: 'Achievements',
    defaultMessage: 'Achievements',
  },
  dateRange: {
    id: 'genericText.dateRange',
    description: 'Date Range',
    defaultMessage: 'Date Range',
  },
};
