import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { changeMainMenuState } from '../Application/actions/application-actions';
import { fetchDismissAlerts } from '../Application/actions/alerts-actions';
import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';
import { Gtm } from '../../utils/gtm';
import RankStatus from '../reusables/svg/RankStatus.react';
import PaymentHistoryGraph from '../reusables/PaymentHistoryGraph.react';
import { getWhiteLogoFromSkinName, shouldDisplayNextLevelLogo } from '../../utils/logoHelper.js';
import Tooltip from '../reusables/Tooltip.react';
import { getAffiliateRankClass } from '../../utils/rankingHelper.js';
import { filterVastUrlInNotifactions } from '../../pages/vast-pre-roll/values.js';
import { openModal } from '../Modal/modal-actions/modal-actions';
import NexLvlChristmasLogo from './NextLvlChristmasLogo';
import { MAX_LOGIN_STREAK_BADGE_LEVEL } from '../../utils/gamificationHelper.js';

const Header = ({
  affStatus,
  currentPeriodPayout,
  handleLogout,
  isConnected,
  mainMenuIsOpen,
  minimumPayoutAmount,
  newAlerts,
  newAlertsIsFetching,
  nextPaymentAmount,
  paymentHistory,
  paymentTerm,
  user,
  lastPeriodPayout,
  isCurrentPeriodPayout,
  routes,
  skinConfigurations,
  dispatch,
  hideNotifications,
  gamificationBadges,
  gamificationAffProgress,
  gamificationEventsIsFetching,
  gamificationBagesIsFetching,
  gamificationEventsData,
  isOverrided,
}) => {
  const hasAccessTo = {};
  const maxAlerts = 9;

  const [notificationIsOpen, setNotificationIsOpen] = useState(false);
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);

  const [overflowActive, setIsOverflowActive] = useState(false);

  const [alerts, setAlerts] = useState([]);

  const overflowRef = useRef();

  const skin = new SkinConfigurationsUtils(skinConfigurations);
  hasAccessTo.ProfileNotifications = skin.hasAccessTo('ProfileNotifications');

  const url = new URL(window.location.href);

  useEffect(() => {
    function handleResize() {
      setWindowWidthSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      document.removeEventListener('click', hideNotifications);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isEllipsisActive = (ref) => ref.offsetWidth < ref.scrollWidth;

  useEffect(() => {
    if (windowWidthSize <= 991 && mainMenuIsOpen) {
      dispatch(changeMainMenuState(false));
    }

    if (overflowRef.current && windowWidthSize >= 992) {
      setIsOverflowActive(isEllipsisActive(overflowRef.current));
    }
  }, [windowWidthSize]);

  const closeNotifications = useCallback(() => {
    setNotificationIsOpen(false);
    setAlerts([]);
  }, [setNotificationIsOpen, setAlerts]);

  useEffect(() => {
    const removeListener = () => document.removeEventListener('click', closeNotifications);

    if (notificationIsOpen) {
      document.addEventListener('click', closeNotifications);
    } else {
      removeListener();
    }

    return () => {
      removeListener();
    };
  }, [notificationIsOpen, closeNotifications]);

  const displayNotification = (e) => {
    e.stopPropagation();

    if (newAlertsIsFetching) {
      return;
    }

    Gtm.event(`${routes[1].title.description}`, 'Click', 'notification icon');

    const openingNotifications = !notificationIsOpen;
    const dissmissAllAlerts = newAlerts?.length && openingNotifications && !isOverrided;

    if (dissmissAllAlerts) {
      dispatch(fetchDismissAlerts(newAlerts.map((newAlert) => newAlert.id)));
    }

    if (openingNotifications) {
      setNotificationIsOpen(true);
    } else {
      closeNotifications();
    }
  };

  const changeMenuState = () => {
    if (isConnected) {
      dispatch(changeMainMenuState(!mainMenuIsOpen));
      const pageWrap = document.getElementById('page-wrap');
      if (!mainMenuIsOpen) {
        pageWrap.style.top = `-${window.scrollY}px`;
        pageWrap.style.position = 'fixed';
      } else {
        const scrollY = pageWrap.style.top;
        pageWrap.style.position = '';
        pageWrap.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  };

  const rankStatusClassName = getAffiliateRankClass(affStatus.rank_status_name);
  const SKIN_NAME = skin.getSkinName().toLowerCase();
  const menuLinkClasses = [
    'menu-burger',
    'menu-link',
    mainMenuIsOpen ? 'open' : undefined,
    !isConnected ? 'disabled' : undefined,
    rankStatusClassName,
  ].join(' ');

  const regexOfferLink = /"(.*?)"/;

  const getAffStatusRankName = (statusRankName) => {
    switch (statusRankName) {
      case 'Active Affiliate':
        return skin.getPropertyValue('Header', 'activeUserLabel', 'activeUser') || 'Active Affiliate';
      case 'Rising Star Affiliate':
        return skin.getPropertyValue('Header', 'proUserLabel', 'proUser') || 'Rising Star Affiliate';
      case 'VIP Affiliate':
        return skin.getPropertyValue('Header', 'vipUserLabel', 'vipUser') || 'VIP Affiliate';
      case 'Elite Club':
        return skin.getPropertyValue('Header', 'eliteUserLabel', 'eliteUser') || 'Elite Club';
      default:
        return null;
    }
  };

  let username = `${user.first_name} ${user.last_name}`;

  if (username.length > 50) {
    username = `<span>${user.first_name} </br> ${user.last_name}</span>`;
  }

  const isBrokenModal = url.searchParams.get('isBrokenModal') === 'true';
  const streakWeek = parseInt(url.searchParams.get('streakweek'));
  let currentLevel = streakWeek === 0 ? 0 : streakWeek || gamificationAffProgress?.progressData.streak;
  if (gamificationEventsData?.status === 'end' || isBrokenModal) {
    currentLevel = 0;
  }
  const logoLevel =
    currentLevel >= MAX_LOGIN_STREAK_BADGE_LEVEL ? MAX_LOGIN_STREAK_BADGE_LEVEL : currentLevel === 0 ? 1 : currentLevel;

  const openGamificationModal = () => {
    if (streakWeek >= 0) {
      if (streakWeek >= 1 && !isBrokenModal) {
        dispatch(
          openModal({
            name: 'ContinuationStreakModal',
            modalProps: {
              dispatch,
              className: 'gamification-streak-modal',
              streakWeek,
            },
          })
        );
      } else if (streakWeek === 0 || (streakWeek >= 0 && isBrokenModal)) {
        dispatch(
          openModal({
            name: 'WeekZeroModal',
            modalProps: {
              dispatch,
              className: 'gamification-streak-modal',
              streakWeek,
            },
          })
        );
      }
    } else {
      if (currentLevel > 0 && (gamificationEventsData?.status === 'progress' || gamificationEventsData?.status === 'continue')) {
        dispatch(
          openModal({
            name: 'ContinuationStreakModal',
            modalProps: {
              dispatch,
              className: 'gamification-streak-modal',
              streakWeek,
            },
          })
        );
      } else if (currentLevel === 0 && gamificationEventsData?.status !== 'progress') {
        dispatch(
          openModal({
            name: 'WeekZeroModal',
            modalProps: {
              dispatch,
              className: 'gamification-streak-modal',
              streakWeek,
            },
          })
        );
      }
    }
    Gtm.newEvent('gamified_header', false, 'click', 'string', 'header_link');
  };
  const weekText = () => (currentLevel > 1 ? 'Weeks Streak' : 'Week Streak');

  useEffect(() => {
    if (newAlerts?.length && !newAlertsIsFetching) {
      const vastFilteredAlerts = filterVastUrlInNotifactions(newAlerts);

      setAlerts(vastFilteredAlerts);
    }
  }, [newAlerts, newAlertsIsFetching, filterVastUrlInNotifactions]);

  return (
    <div className="container-fluid z-depth-2" id="site-header">
      <nav className="row header-container">
        <span className={menuLinkClasses} onClick={changeMenuState}>
          <div className="icon">
            <span />
            <span />
            <span />
          </div>
          <FormattedMessage {...messages.genericTextMenu} />
        </span>

        <div className="col-lg-3 col-md-3 col-sm-8 col-xs-8 header-logo">
          {shouldDisplayNextLevelLogo() ? (
            <Tooltip
              className="next-lvl-tooltip"
              closeIcon
              content={
                <p>
                  Level Up Contest is Live - Climb our affiliate ranking & win awesome prizes! <br />
                  <a href="https://www.crakrevenue.com/contest" rel="noreferrer" target="_blank">
                    Learn more
                  </a>
                </p>
              }
              isFixed
              position={windowWidthSize >= 992 ? 'right' : 'bottom'}
            >
              <Link
                className="logo"
                onClick={() => {
                  Gtm.event(`${routes[1].title.description}`, 'Click', 'navbar logo');
                }}
                onlyActiveOnIndex={false}
                to="/"
              >
                <NexLvlChristmasLogo />
              </Link>
            </Tooltip>
          ) : (
            <Link
              className="logo"
              onClick={() => {
                Gtm.event(`${routes[1].title.description}`, 'Click', 'navbar logo');
              }}
              onlyActiveOnIndex={false}
              to="/"
            >
              <img
                alt={SKIN_NAME}
                height={skin.getPropertyValue('Header', 'img.logo', 'height') || '35px'}
                src={getWhiteLogoFromSkinName(SKIN_NAME)}
              />
            </Link>
          )}
        </div>

        {isConnected ? (
          <ul className="utils">
            {streakWeek >= 0 || (!gamificationEventsIsFetching && !gamificationBagesIsFetching) ? (
              <li
                className="hidden-xs hidden-sm rank-gamification-box"
                onClick={() => {
                  openGamificationModal();
                }}
                ref={overflowRef}
              >
                <div className="icon-link username">
                  <span className="user-img">
                    <RankStatus rank={rankStatusClassName} />
                  </span>
                  <div className="user-infos">
                    <div className="user-name">{`${user.first_name} ${user.last_name}`}</div>

                    <div className="user-status"> {getAffStatusRankName(affStatus.rank_status_name)} </div>
                  </div>
                </div>
                <div className="gamification-infos">
                  <div className="badge-streak">
                    <img alt="badge" className="badge" src={gamificationBadges[logoLevel - 1]?.badgeData.thumbnailMiniUrl} />
                    <span className={`badge-level ${gamificationBadges[logoLevel - 1]?.badgeData.subtype}`}>{currentLevel}</span>
                  </div>
                  <div className="week-streak">
                    <span className="week-text">{weekText()}</span>
                    <span className="lvl-text">{`Lvl ${currentLevel}`}</span>
                  </div>
                </div>
              </li>
            ) : null}
            {(windowWidthSize <= 991 || windowWidthSize >= 1226) && (
              <li className="payout hidden-xs hidden-sm">
                <PaymentHistoryGraph
                  currentPeriodPayout={currentPeriodPayout}
                  hasReachedMinimumPayout={paymentHistory.asReachedMinimumPayout}
                  isCurrentPeriodPayout={isCurrentPeriodPayout}
                  lastPeriodHasInvoice={paymentHistory.lastPeriodHasInvoice}
                  lastPeriodPayout={lastPeriodPayout}
                  minimumPayoutAmount={minimumPayoutAmount}
                  nextPaymentAmount={nextPaymentAmount}
                  paymentTerm={paymentTerm}
                />
              </li>
            )}
            <li className="mobile-gamification-badge">
              <div className="gamification-infos" onClick={openGamificationModal}>
                <div className="badge-streak">
                  <img alt="badge" className="badge" src={gamificationBadges[logoLevel - 1]?.badgeData.thumbnailMiniUrl} />
                  <span className={`badge-level ${gamificationBadges[logoLevel - 1]?.badgeData.subtype}`}>{currentLevel}</span>
                </div>
              </div>
            </li>
            {hasAccessTo.ProfileNotifications ? (
              <li className="notifications" data-cy="notifications" onClick={displayNotification}>
                <i className={'material-icons ' + (newAlerts.length > 0 ? 'animated' : '')}>&#xE7F4;</i>
                {newAlerts.length > 0 && (
                  <span className="number bg-primary-color">
                    {newAlerts.length < maxAlerts && newAlerts.length}
                    {newAlerts.length >= maxAlerts && maxAlerts + '+'}
                  </span>
                )}
                {notificationIsOpen ? (
                  <div className="notifications-list">
                    <div className="z-depth-2">
                      {alerts.length ? (
                        <ul>
                          {alerts
                            .filter((value, index) => index < maxAlerts)
                            .map((newAlert) => (
                              <li key={newAlert.description}>
                                {newAlert.description.match(regexOfferLink) ? (
                                  <Link
                                    className="liWithLink"
                                    to={
                                      newAlert.description.match(regexOfferLink)
                                        ? newAlert.description.match(regexOfferLink)[1]
                                        : ''
                                    }
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: newAlert.description,
                                      }}
                                    />
                                  </Link>
                                ) : (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: newAlert.description,
                                    }}
                                  />
                                )}
                              </li>
                            ))}
                        </ul>
                      ) : (
                        <Link className="no-new-notifications" onlyActiveOnIndex={false} to="/profile/notifications">
                          <FormattedMessage {...messages.notificationsNoNew} />
                        </Link>
                      )}
                      <Link className="see-all-notifications" onlyActiveOnIndex={false} to="/profile/notifications">
                        <FormattedMessage {...messages.notificationsSeeAll} />
                      </Link>
                    </div>
                  </div>
                ) : null}
              </li>
            ) : null}
            <li
              className="hidden-xs hidden-sm logout"
              data-cy="logout"
              onClick={(e) => {
                handleLogout(e);
                Gtm.event(`${routes[1].title.description}`, 'Click', 'login/logout');
              }}
            >
              <FormattedMessage {...messages.genericTextLogout} />
              <i className="material-icons">&#xE879;</i>
            </li>
          </ul>
        ) : null}
      </nav>
    </div>
  );
};

Header.propTypes = {
  affStatus: PropTypes.object.isRequired,
  currentPeriodPayout: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isCurrentPeriodPayout: PropTypes.any,
  lastPeriodPayout: PropTypes.number.isRequired,
  mainMenuIsOpen: PropTypes.bool.isRequired,
  minimumPayoutAmount: PropTypes.number.isRequired,
  newAlerts: PropTypes.array.isRequired,
  nextPaymentAmount: PropTypes.number.isRequired,
  paymentHistory: PropTypes.object.isRequired,
  paymentTerm: PropTypes.string.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect((state) => ({
  currentPeriodPayout:
    (state.home.currentPeriodPayouts.totals &&
      state.home.currentPeriodPayouts.totals.Stat &&
      +parseFloat(state.home.currentPeriodPayouts.totals.Stat.payout).toFixed(2)) ||
    0,
  isCurrentPeriodPayout: state.home.currentPeriodPayouts.totals || null,
  minimumPayoutAmount: +parseFloat(state.profile.data.customProfile.affiliate.minimum_payout.amount).toFixed(2) || 0,
  nextPaymentAmount: +parseFloat(state.profile.data.paymentHistory.next.amount).toFixed(2) || 0,
  paymentTerm: state.profile.data.customProfile.affiliate.payment_term || '',
  paymentHistory: state.profile.data.paymentHistory,
  skinConfigurations: state.skinConfigurations.data,
  lastPeriodPayout:
    (state.home.lastPeriodPayouts.totals &&
      state.home.lastPeriodPayouts.totals.Stat &&
      +parseFloat(state.home.lastPeriodPayouts.totals.Stat.payout).toFixed(2)) ||
    0,
  gamificationEventsIsFetching: state.gamification.eventsDataIsFetching,
  gamificationBagesIsFetching: state.gamification.badgeDataIsFetching,
  gamificationBadges: state.gamification.loginStreakBadges,
  gamificationAffProgress: state?.gamification?.eventsData?.notification?.affiliateProgress,
  gamificationEventsData: state?.gamification?.eventsData?.notification,
  isOverrided: state.profile.data.isOverrided,
}))(Header);
