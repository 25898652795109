import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import React from 'react';
import WarningIcon from './svg/WarningIcon.react';

import { getLocalStorageValue, setLocalStorageValue, getSessionStorageValue, setSessionStorageValue } from '../../utils/helpers';
import { withRouter } from 'react-router';

const AlertHeader = ({ children, className, CustomIcon, iconColor, iconIsVisible, localStorageString, useSession, location }) => {
  const getStorageValue = () => {
    if (useSession) {
      return getSessionStorageValue(localStorageString);
    }
    return getLocalStorageValue(localStorageString);
  };
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (location.pathname !== '/achievements') {
      setShow(!(getStorageValue() === 'false'));
    } else {
      setShow(false);
    }
  }, [getStorageValue, location]);

  const setStorageValue = (value) => {
    if (useSession) {
      return setSessionStorageValue(localStorageString, value);
    }
    return setLocalStorageValue(localStorageString, value);
  };

  const handleCloseClick = (e) => {
    e.preventDefault();

    setStorageValue(false);

    setShow(false);
  };
  return show ? (
    <div className={`alert-header ${className} ${iconIsVisible && 'iconVisible'}`}>
      <div className="row title-block">
        <div className="col-md-12">
          {iconIsVisible ? <WarningIcon color={iconColor} /> : null}
          {CustomIcon ? <CustomIcon color={iconColor} /> : null}
          <p>
            {children}
            <span className="x-circle" onClick={handleCloseClick}>
              <i className="material-icons">close</i>
            </span>
          </p>
        </div>
      </div>
    </div>
  ) : null;
};

AlertHeader.defaultProps = {
  className: '',
  CustomIcon: false,
  iconColor: '#f7cb2c',
  iconIsVisible: false,
  useSession: false,
};

AlertHeader.propTypes = {
  CustomIcon: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  iconIsVisible: PropTypes.bool,
  localStorageString: PropTypes.string,
  useSession: PropTypes.bool,
};

export default withRouter(AlertHeader);
