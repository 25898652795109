export const getFeatureFlagValueFromUrlSearchParams = (featureFlageName) => {
  try {
    const url = new URL(window.location.href);
    return url.searchParams.get(featureFlageName) ?? null;
  } catch (e) {
    console.log('unable to get feature flag from url search params');
    return null;
  }
};

export const getFeatureFlagValueFromLocalStorage = (featureFlageName) => {
  try {
    const featureFlag = localStorage.getItem(featureFlageName);
    return featureFlag ?? null;
  } catch (e) {
    console.log('Unable to get feature flag from local storage:', e);
    return null;
  }
};
