import CloseModalIcon from '../../reusables/svg/Gamification/CloseModalIcon.react';
import BadgeBg from '../../reusables/svg/Gamification/BadgeBg.react';
import RankStatus from '../../reusables/svg/RankStatus.react';
import { getAffiliateRankClass } from '../../../utils/rankingHelper';
import { connect } from 'react-redux';
import StatIconBg from '../../reusables/svg/Gamification/StatIconBg.react';
import ModalFooter from '../../reusables/svg/Gamification/ModalFooter.react';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';
import React, { useEffect, useState } from 'react';
import Tooltip from '../../reusables/Tooltip.react';
import { Info } from '../../reusables';
import moment from 'moment';
import { Gtm } from '../../../utils/gtm';
import BronzeCoin from './svg/coins/BronzeCoin';
import ClickBronze from './svg/clicks/ClickBronze';
import ClickSilver from './svg/clicks/ClickSilver';
import ClickGold from './svg/clicks/Clickgold';
import ClickEmerald from './svg/clicks/ClickEmerald';
import SilverCoin from './svg/coins/SilverCoin';
import GoldCoin from './svg/coins/GoldCoin';
import EmeraldCoin from './svg/coins/EmeraldCoin';
import AnimatedNumber from '../../reusables/AnimatedNumber';
import { MAX_LOGIN_STREAK_BADGE_LEVEL } from '../../../utils/gamificationHelper';

const GamificationStreakModal = React.memo(
  ({
    affStatus,
    gamificationBadges,
    gamificationEventsStats,
    gamificationAffProgress,
    user,
    dispatch,
    modalInner,
    streakWeek,
    isImpression,
  }) => {
    const [firstTitle, setFirstTitle] = useState();
    const [secondTitle, setSecondTitle] = useState();
    const today = new Date();
    const sunday = new Date(moment().startOf('week').toDate());
    const diffTime = Math.abs(sunday - today);
    const daysStreakThisWeek = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const dayName = today.toLocaleDateString('en-US', { weekday: 'long' });
    const [coins, setCoins] = useState([]);
    const [arrows, setArrows] = useState([]);

    const whaleClassName = (img) => {
      if (img.includes('2')) {
        return 'lvl-2';
      } else if (img.includes('3')) {
        return 'lvl-3';
      } else if (img.includes('4')) {
        return 'lvl-4';
      }
      return 'lvl-1';
    };

    const currentLevel = streakWeek || gamificationAffProgress?.progressData.streak;
    const weekText = () => (currentLevel > 1 ? 'Weeks' : 'Week');
    const logoLevel = currentLevel >= MAX_LOGIN_STREAK_BADGE_LEVEL ? MAX_LOGIN_STREAK_BADGE_LEVEL : currentLevel;

    const payoutTotal = Math.round((parseInt(gamificationEventsStats.payout.sinceStreakStart) + Number.EPSILON) * 100) / 100;

    const clicksTotal = gamificationEventsStats.clicks.sinceStreakStart;

    const createComponentsCoin = (Component, count, keyName) =>
      Array(payoutTotal > 0 ? count : 0)
        .fill(null)
        .map((_, index) => <Component key={`${keyName}-${index + 1}`} />);

    const createComponentsArrow = (Component, count, keyName) =>
      Array(clicksTotal > 0 ? count : 0)
        .fill(null)
        .map((_, index) => <Component key={`${keyName}-${index + 1}`} />);

    const bronzeArrows = createComponentsArrow(ClickBronze, 5, 'ClickBronze');
    const silverArrows = createComponentsArrow(ClickSilver, 9, 'ClickSilver');
    const goldArrows = createComponentsArrow(ClickGold, 12, 'ClickGold');
    const emeraldArrows = createComponentsArrow(ClickEmerald, MAX_LOGIN_STREAK_BADGE_LEVEL, 'ClickEmerald');
    const bronzeCoins = createComponentsCoin(BronzeCoin, 5, 'BronzeCoin');
    const silverCoins = createComponentsCoin(SilverCoin, 9, 'SilverCoin');
    const goldCoins = createComponentsCoin(GoldCoin, 12, 'GoldCoin');
    const emeraldCoins = createComponentsCoin(EmeraldCoin, MAX_LOGIN_STREAK_BADGE_LEVEL, 'EmeraldCoin');

    useEffect(() => {
      switch (gamificationBadges[logoLevel - 1]?.badgeData.subtype) {
        case 'bronze':
          setFirstTitle('Connection made!');
          setSecondTitle('GOOD JOB!');
          setCoins(bronzeCoins);
          setArrows(bronzeArrows);
          break;
        case 'silver':
          setFirstTitle('Streak preserved');
          setSecondTitle('FANTASTIC!');
          setCoins(silverCoins);
          setArrows(silverArrows);
          break;
        case 'gold':
          setFirstTitle('YOU’RE UNSTOPPABLE');
          setSecondTitle('KEEP GOING!');
          setCoins(goldCoins);
          setArrows(goldArrows);
          break;
        case 'emerald':
          setFirstTitle('Streak extended');
          setSecondTitle('PHENOMENAL!');
          setCoins(emeraldCoins);
          setArrows(emeraldArrows);
          break;
        default:
          setFirstTitle('YOU’RE UNSTOPPABLE');
          setSecondTitle('KEEP GOING!');
          setCoins(bronzeCoins);
          setArrows(bronzeArrows);
          break;
      }
    }, []);
    const closeOpenModal = (isNotContinueBtn = true) => {
      dispatch(closeModalAnimation());
      if (isNotContinueBtn) {
        Gtm.newEvent('gamified_ranking_modal', false, 'click', 'string', `close_${currentLevel}`);
      } else {
        Gtm.newEvent('gamified_ranking_modal', false, 'click', 'string', `continue_${currentLevel}`);
      }
    };

    useClickOutside(modalInner, closeOpenModal);

    useEffect(() => {
      if (isImpression && typeof ga === 'function') {
        Gtm.newEvent('gamified_ranking_modal', false, 'impression');
      }
    }, []);

    return (
      <>
        <div className={gamificationBadges[logoLevel - 1]?.badgeData.subtype === 'emerald' ? 'upper-modal' : ''}>
          <div className="header-modal">
            <div className="rotate-title">
              <span className="first-title">{firstTitle}</span>
              <span className="second-title">{secondTitle}</span>
            </div>
            <button onClick={() => closeOpenModal()} type="button">
              <CloseModalIcon />
            </button>
          </div>
          <div className="badge-section">
            <BadgeBg />
            <div className={`badge-container ${gamificationBadges[logoLevel - 1]?.badgeData.subtype}`}>
              <img
                alt="badge"
                className={`badge ${gamificationBadges[logoLevel - 1]?.badgeData.subtype}`}
                src={gamificationBadges[logoLevel - 1].badgeData.thumbnailUrl}
              />
              <span className={`badge-level ${gamificationBadges[logoLevel - 1]?.badgeData.subtype}`}>{currentLevel}</span>
            </div>
          </div>
        </div>
        <div className="week-streak-section">
          <span className="week-text">
            {weekText()} Streak
            <Tooltip
              closeIcon
              content={
                <p>
                  Log in at least once a week to keep your streak going strong and unlock new badges!{' '}
                  <a
                    href="https://support.crakrevenue.com/knowledge-base/crakrevenue-achievement-program/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Learn more
                  </a>
                </p>
              }
              isHtml
              onOpen={() => Gtm.newEvent('gamified_ranking_modal', false, 'click', 'string', 'tooltip')}
              position="left"
            >
              <Info className="more-info" />
            </Tooltip>
          </span>
          <div className="progress-bar">
            {logoLevel < MAX_LOGIN_STREAK_BADGE_LEVEL && (
              <div className="whale-lvl">
                <img
                  className={`${whaleClassName(gamificationBadges[logoLevel - 1].badgeData.whaleUrl)} ${
                    whaleClassName(gamificationBadges[logoLevel - 1].badgeData.whaleUrl) === 'lvl-4' ? 'left-lvl-4' : ''
                  }`}
                  src={gamificationBadges[logoLevel - 1].badgeData.whaleUrl}
                />
                <span className="lvl-chip">{`Lvl ${currentLevel}`}</span>
              </div>
            )}
            {[...Array(7).keys()].map((bar, key) => (
              <div className={`${bar === daysStreakThisWeek - 1 && 'current-day-container'}`} key={key}>
                <div
                  className={`bar ${bar < daysStreakThisWeek && 'active'} ${bar === daysStreakThisWeek - 1 && 'current-day'}`}
                  key={key}
                />
                {bar === daysStreakThisWeek - 1 ? <p className="current-day-name">{dayName}</p> : null}
              </div>
            ))}
            <div className="whale-lvl right">
              <img
                className={whaleClassName(
                  gamificationBadges[logoLevel >= MAX_LOGIN_STREAK_BADGE_LEVEL ? logoLevel - 1 : logoLevel].badgeData.whaleUrl
                )}
                src={gamificationBadges[logoLevel >= MAX_LOGIN_STREAK_BADGE_LEVEL ? logoLevel - 1 : logoLevel].badgeData.whaleUrl}
              />
              <span className="lvl-chip">{`Lvl ${
                currentLevel < MAX_LOGIN_STREAK_BADGE_LEVEL ? currentLevel + 1 : currentLevel
              }`}</span>
            </div>
          </div>
        </div>
        <div className="aff-info-section">
          <div className="aff-info-header">
            <div className="user-infos-box">
              <div className="rank-icon-box">
                <RankStatus rank={getAffiliateRankClass(affStatus.rank_status_name)} />
              </div>
              <div className="user-infos">
                <span className="user-name">{`${user.first_name} ${user.last_name}`}</span>
                <span className="user-status"> {affStatus.rank_status_name} </span>
              </div>
            </div>
            <div className="streak-info">
              <div className="streak-box">
                <p className="weeks-nb">
                  <span>{streakWeek || gamificationAffProgress.progressData.streak}</span> {weekText()} Streak
                </p>
                <span className="lvl">{`Lvl ${currentLevel}`}</span>
              </div>
              <div className="badge-box">
                <img
                  alt="badge-mini"
                  className={gamificationBadges[logoLevel - 1]?.badgeData.subtype}
                  src={gamificationBadges[logoLevel - 1].badgeData.thumbnailMiniUrl}
                />
              </div>
            </div>
          </div>
          <div className="stats">
            <h3>
              Since You Started{' '}
              <span className="break-after">
                ({streakWeek || gamificationAffProgress.progressData.streak} {weekText()} Ago)
              </span>
            </h3>
            <div className="all-stats">
              <div className="stat-box clicks">
                {arrows.map((arrow, index) => (
                  <div className="arrow" key={index}>
                    {arrow}
                  </div>
                ))}
                <span className="title">Clicks</span>
                <div className="stat-icon">
                  <StatIconBg />
                  <div className="stat-icon-box">
                    <img alt="stat-icon" src={gamificationBadges[logoLevel - 1].badgeData.statClickImgUrl} />
                  </div>
                </div>
                <div className="stat-number">
                  <span className="real-stat">
                    <AnimatedNumber delay={2000} duration={2000} value={clicksTotal} />
                  </span>
                </div>
              </div>
              <div className="stat-box  payout">
                {coins.map((coin, index) => (
                  <div className="coin" key={index}>
                    {coin}
                  </div>
                ))}
                <span className="title">Payout</span>
                <div className="stat-icon">
                  <StatIconBg />
                  <div className="stat-icon-box">
                    <img src={gamificationBadges[logoLevel - 1].badgeData.statPayoutImgUrl} />
                  </div>
                </div>
                <div className="stat-number">
                  <span className="real-stat">
                    $
                    <AnimatedNumber delay={2000} duration={2000} value={payoutTotal} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn-large btn gamification-btn" onClick={() => closeOpenModal(false)}>
          Continue
        </button>
        <ModalFooter />
      </>
    );
  }
);

GamificationStreakModal.displayName = 'GamificationStreakModal';

export default connect((state) => ({
  affStatus: state.profile.data.affStatus,
  user: state.profile.data.affUserInfos,
  affInfos: state.profile.data.affInfos,
  gamificationEventsStats: state.gamification.eventsData.notification.stats,
  gamificationEventsNotif: state.gamification.eventsData.notification,
  gamificationAffProgress: state.gamification.eventsData.notification.affiliateProgress,
  gamificationBadges: state.gamification.loginStreakBadges,
}))(GamificationStreakModal);
