const Active = () => (
  <svg fill="none" height="45" viewBox="0 0 35 45" width="35" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 9.87832V33.3162L17.1113 43.1982L34.2226 33.3162V9.87832L17.1113 0L0 9.87832Z" fill="#011800" />
    <path
      d="M17.1112 37.6816L1.59131 28.7209V10.7995L17.1112 1.83883L32.6311 10.7995V28.7209L17.1112 37.6816Z"
      stroke="#00BD00"
    />
    <path d="M0 33.3162L17.1113 43.1982L34.2226 33.3162V35.1216L17.1113 45L0 35.1216V33.3162Z" fill="#00BD00" />
    <path
      clipRule="evenodd"
      d="M28.0676 17.6293C25.2706 19.9046 24.6861 20.3227 21.9298 22.6461C19.1846 24.9733 16.4689 27.3485 13.7718 29.7423C13.3908 29.2835 6.55362 20.8222 6.39453 20.6446V19.2683L9.8131 16.4935L14.1381 22.0135L24.8711 12.4792L28.0676 16.2567V17.633V17.6293Z"
      fill="#000F11"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M28.0676 16.253C25.2706 18.5283 24.6861 18.9464 21.9298 21.2698C19.1846 23.597 16.4689 25.9722 13.7718 28.366C13.3908 27.9072 6.55362 19.4459 6.39453 19.2683C8.51449 17.5442 9.11015 17.0669 9.8131 16.4935L14.1381 21.6435C16.9092 19.3275 22.1813 14.873 24.8711 12.4792L25.3261 13.0157L25.4963 13.2192L26.3991 14.281L26.9318 14.9063L28.0713 16.2567L28.0676 16.253Z"
      fill="url(#paint0_radial_2067_37736)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M28.0676 16.253C25.2669 18.5283 24.6861 18.9464 21.9298 21.2698C19.1846 23.597 16.4689 25.9722 13.7718 28.3659C13.3908 27.9072 6.7793 19.7196 6.39453 19.2682C7.01239 18.7355 7.31947 18.5468 7.93733 18.014L14.0974 25.802C16.2581 23.9521 18.9663 21.5806 21.1454 19.7455C23.7944 17.5257 24.5381 16.9152 26.9281 14.9062L28.0676 16.2567V16.253Z"
      fill="url(#paint1_radial_2067_37736)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      clipRule="evenodd"
      d="M26.3955 14.2774C18.9849 20.53 14.8486 23.4602 14.6081 23.1716C14.3788 22.8793 18.1007 19.4977 25.4928 13.2156L26.3955 14.2774Z"
      fill="url(#paint2_radial_2067_37736)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      clipRule="evenodd"
      d="M9.81319 16.4934L8.42578 17.6514C9.48761 19.4902 12.5029 21.5214 14.1382 21.6472L9.81319 16.4971V16.4934Z"
      fill="url(#paint3_radial_2067_37736)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M16.5834 23.0567C16.5501 23.0419 17.4824 22.054 18.6663 20.8516C19.8503 19.6492 20.8344 18.6873 20.864 18.7021C20.8936 18.7169 19.965 19.7047 18.781 20.9071C17.5971 22.1095 16.613 23.0715 16.5834 23.0567Z"
      fill="url(#paint4_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M16.5466 18.7391C16.5614 18.7058 17.5492 19.6382 18.7516 20.8221C19.954 22.006 20.916 22.9902 20.9012 23.0198C20.8864 23.053 19.8985 22.1207 18.6961 20.9368C17.4937 19.7529 16.5318 18.7687 16.5466 18.7391Z"
      fill="url(#paint5_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M20.8604 22.9901C20.9677 22.8828 20.102 21.8469 18.9292 20.6777C17.7564 19.5049 16.7204 18.6429 16.6168 18.7465C16.5132 18.8501 17.3753 19.8897 18.5481 21.0588C19.7209 22.2316 20.7569 23.0937 20.8604 22.9901Z"
      fill="url(#paint6_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M19.3951 21.5252C19.4284 21.4919 19.162 21.17 18.7958 20.8074C18.4332 20.4448 18.1113 20.1785 18.078 20.2081C18.0447 20.2377 18.3111 20.5632 18.6774 20.9258C19.0399 21.2884 19.3618 21.5548 19.3951 21.5252Z"
      fill="url(#paint7_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M19.6322 21.7619C19.6766 21.7175 19.3103 21.2809 18.8183 20.7852C18.3262 20.2894 17.8859 19.9268 17.8415 19.9712C17.7971 20.0156 18.1634 20.4522 18.6555 20.9479C19.1512 21.4437 19.5878 21.8063 19.6322 21.7619Z"
      fill="url(#paint8_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M16.6168 22.9901C16.7241 23.0974 17.76 22.2317 18.9291 21.0589C20.102 19.8861 20.964 18.8501 20.8604 18.7465C20.7531 18.6392 19.7172 19.505 18.5481 20.6778C17.379 21.8506 16.5132 22.8866 16.6168 22.9901Z"
      fill="url(#paint9_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M18.0818 21.5252C18.1151 21.5585 18.437 21.2922 18.7996 20.9259C19.1622 20.5633 19.4285 20.2414 19.3989 20.2081C19.3693 20.1748 19.0438 20.4412 18.6812 20.8075C18.3186 21.1701 18.0522 21.4919 18.0818 21.5252Z"
      fill="url(#paint10_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M17.8416 21.7621C17.886 21.8065 18.3225 21.4402 18.8183 20.9482C19.3141 20.4524 19.6766 20.0158 19.6322 19.9714C19.5878 19.927 19.1513 20.2933 18.6555 20.7854C18.1597 21.2811 17.7972 21.7177 17.8416 21.7621Z"
      fill="url(#paint11_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M16.5834 22.2725C16.5501 22.2577 17.4824 21.2699 18.6663 20.0674C19.8503 18.865 20.8344 17.9031 20.864 17.9179C20.8973 17.9327 19.965 18.9205 18.781 20.1229C17.5971 21.3254 16.613 22.2873 16.5834 22.2725Z"
      fill="url(#paint12_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M16.5466 17.9511C16.5614 17.9178 17.5492 18.8501 18.7516 20.034C19.954 21.2179 20.916 22.2021 20.9012 22.2317C20.8864 22.265 19.8985 21.3326 18.6961 20.1487C17.4937 18.9648 16.5318 17.9807 16.5466 17.9511Z"
      fill="url(#paint13_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M22.0001 23.3417C22.1074 23.2344 20.9864 21.9432 19.4991 20.4596C18.0118 18.9723 16.7206 17.855 16.617 17.9586C16.5097 18.0659 17.6307 19.3571 19.118 20.8407C20.6053 22.3243 21.8965 23.4453 22.0001 23.3417Z"
      fill="url(#paint14_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M20.1427 21.4842C20.176 21.4509 19.8283 21.0514 19.3695 20.5926C18.9107 20.1338 18.5074 19.786 18.4778 19.8193C18.4483 19.8526 18.7923 20.2522 19.2511 20.711C19.7099 21.1698 20.1131 21.5175 20.1427 21.4842Z"
      fill="url(#paint15_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M20.4425 21.7877C20.4869 21.7433 20.0133 21.1995 19.3881 20.5705C18.7591 19.9416 18.2152 19.4717 18.1709 19.5161C18.1265 19.5605 18.6 20.1043 19.2253 20.7333C19.8542 21.3623 20.3981 21.8321 20.4425 21.7877Z"
      fill="url(#paint16_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M16.6168 22.2021C16.7241 22.3094 17.7601 21.4436 18.9292 20.2708C20.102 19.098 20.964 18.062 20.8604 17.9585C20.7532 17.8512 19.7172 18.7169 18.5481 19.8897C17.3753 21.0625 16.5132 22.0985 16.6168 22.2021Z"
      fill="url(#paint17_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M18.0818 20.7372C18.1151 20.7705 18.437 20.5041 18.7996 20.1378C19.1622 19.7752 19.4285 19.4533 19.3989 19.42C19.3693 19.3867 19.0438 19.6531 18.6812 20.0194C18.3186 20.382 18.0522 20.7039 18.0818 20.7372Z"
      fill="url(#paint18_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M17.8416 20.9777C17.886 21.0221 18.3225 20.6558 18.8183 20.1637C19.3141 19.6717 19.6766 19.2314 19.6322 19.187C19.5878 19.1426 19.1513 19.5089 18.6555 20.0009C18.1597 20.493 17.7972 20.9333 17.8416 20.9777Z"
      fill="url(#paint19_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <g opacity="0.5" style={{ mixBlendMode: 'screen' }}>
      <path
        d="M19.2213 20.3597C18.9549 20.0933 18.5257 20.0933 18.2594 20.3597C17.993 20.6261 17.993 21.0553 18.2594 21.3216C18.5257 21.588 18.9549 21.588 19.2213 21.3216C19.4877 21.0553 19.4877 20.6261 19.2213 20.3597Z"
        fill="url(#paint20_radial_2067_37736)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M18.744 22.0764C19.4265 22.0764 19.9797 21.5232 19.9797 20.8407C19.9797 20.1582 19.4265 19.605 18.744 19.605C18.0615 19.605 17.5083 20.1582 17.5083 20.8407C17.5083 21.5232 18.0615 22.0764 18.744 22.0764Z"
        fill="url(#paint21_radial_2067_37736)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <g style={{ mixBlendMode: 'color-dodge' }}>
      <path
        d="M19.2213 19.679C18.9549 19.4127 18.5257 19.4127 18.2594 19.679C17.993 19.9454 17.993 20.3746 18.2594 20.641C18.5257 20.9074 18.9549 20.9074 19.2213 20.641C19.4877 20.3746 19.4877 19.9454 19.2213 19.679Z"
        fill="url(#paint22_radial_2067_37736)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M18.744 21.3957C19.4265 21.3957 19.9797 20.8425 19.9797 20.16C19.9797 19.4776 19.4265 18.9243 18.744 18.9243C18.0615 18.9243 17.5083 19.4776 17.5083 20.16C17.5083 20.8425 18.0615 21.3957 18.744 21.3957Z"
        fill="url(#paint23_radial_2067_37736)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <path
      d="M10.9492 21.9876C10.9307 21.9802 11.4856 21.392 12.1886 20.6742C12.8915 19.9565 13.4798 19.3867 13.4983 19.3941C13.5168 19.4015 12.9618 19.9898 12.2589 20.7075C11.5559 21.4216 10.9677 21.995 10.9492 21.9876Z"
      fill="url(#paint24_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M10.9268 19.4201C10.9342 19.4016 11.5225 19.9566 12.2402 20.6595C12.958 21.3625 13.5278 21.9508 13.5204 21.9693C13.513 21.9878 12.9247 21.4328 12.2069 20.7298C11.4929 20.0269 10.9194 19.4386 10.9268 19.4201Z"
      fill="url(#paint25_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M13.4945 21.9506C13.5574 21.8877 13.0432 21.2698 12.3439 20.5743C11.6447 19.875 11.0305 19.3608 10.9676 19.4237C10.9047 19.4866 11.419 20.1044 12.1182 20.8C12.8175 21.4955 13.4316 22.0135 13.4945 21.9506Z"
      fill="url(#paint26_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M12.6215 21.0775C12.64 21.059 12.4809 20.8666 12.2663 20.652C12.0517 20.4375 11.8593 20.2784 11.8408 20.2969C11.8223 20.3154 11.9814 20.5078 12.196 20.7223C12.4106 20.9369 12.603 21.096 12.6215 21.0775Z"
      fill="url(#paint27_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M12.7619 21.218C12.7878 21.1921 12.5696 20.9294 12.2773 20.6371C11.985 20.3449 11.7223 20.1266 11.6964 20.1525C11.6705 20.1784 11.8888 20.4411 12.1811 20.7333C12.4771 21.0293 12.736 21.2439 12.7619 21.218Z"
      fill="url(#paint28_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M10.9676 21.9506C11.0305 22.0135 11.6483 21.4992 12.3439 20.8C13.0431 20.1007 13.5574 19.4866 13.4945 19.4237C13.4316 19.3608 12.8137 19.875 12.1182 20.5743C11.4226 21.2735 10.9047 21.8877 10.9676 21.9506Z"
      fill="url(#paint29_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M11.8408 21.0775C11.8593 21.096 12.0517 20.9369 12.2663 20.7223C12.4809 20.5078 12.64 20.3154 12.6215 20.2969C12.603 20.2784 12.4106 20.4375 12.196 20.652C11.9814 20.8666 11.8223 21.059 11.8408 21.0775Z"
      fill="url(#paint30_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M11.6965 21.218C11.7224 21.2439 11.985 21.0256 12.2773 20.7333C12.5733 20.4374 12.7879 20.1784 12.762 20.1525C12.7361 20.1266 12.4734 20.3449 12.1811 20.6371C11.8851 20.9331 11.6706 21.1921 11.6965 21.218Z"
      fill="url(#paint31_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M10.9492 21.5216C10.9307 21.5142 11.4856 20.9259 12.1886 20.2082C12.8915 19.4941 13.4798 18.9206 13.4983 18.928C13.5168 18.9354 12.9618 19.5237 12.2589 20.2415C11.5559 20.9555 10.9677 21.529 10.9492 21.5216Z"
      fill="url(#paint32_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M10.9268 18.9502C10.9342 18.9317 11.5225 19.4866 12.2402 20.1896C12.958 20.8925 13.5278 21.4808 13.5204 21.4993C13.513 21.5178 12.9247 20.9628 12.2069 20.2599C11.4929 19.5569 10.9194 18.9687 10.9268 18.9502Z"
      fill="url(#paint33_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M14.1715 22.1615C14.2344 22.0986 13.5684 21.329 12.6842 20.4448C11.7999 19.5605 11.0304 18.8946 10.9675 18.9575C10.9046 19.0204 11.5706 19.7899 12.4548 20.6742C13.339 21.5584 14.1086 22.2244 14.1715 22.1615Z"
      fill="url(#paint34_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M13.0652 21.0552C13.0837 21.0367 12.8802 20.7962 12.6028 20.5225C12.3253 20.2487 12.0922 20.0415 12.07 20.06C12.0478 20.0785 12.255 20.319 12.5325 20.5927C12.8062 20.8665 13.043 21.0737 13.0652 21.0552Z"
      fill="url(#paint35_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M13.2464 21.233C13.2723 21.2071 12.9911 20.8815 12.6174 20.5078C12.2438 20.1341 11.9182 19.853 11.8923 19.8789C11.8664 19.9048 12.1476 20.2303 12.5212 20.604C12.8949 20.9777 13.2205 21.2589 13.2464 21.233Z"
      fill="url(#paint36_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M10.9676 21.4806C11.0305 21.5435 11.6484 21.0292 12.3439 20.33C13.0395 19.6307 13.5574 19.0166 13.4945 18.9537C13.4316 18.8908 12.8138 19.4051 12.1182 20.1043C11.419 20.8036 10.9047 21.4177 10.9676 21.4806Z"
      fill="url(#paint37_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M11.8408 20.6112C11.8593 20.6297 12.0517 20.4706 12.2663 20.256C12.4809 20.0415 12.64 19.8491 12.6215 19.8306C12.603 19.8121 12.4106 19.9712 12.196 20.1857C11.9814 20.4003 11.8223 20.5927 11.8408 20.6112Z"
      fill="url(#paint38_radial_2067_37736)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M11.6964 20.7519C11.7223 20.7778 11.985 20.5596 12.2773 20.2673C12.5696 19.975 12.7878 19.7123 12.7619 19.6864C12.736 19.6605 12.4734 19.8788 12.1811 20.1711C11.8888 20.4634 11.6705 20.726 11.6964 20.7519Z"
      fill="url(#paint39_radial_2067_37736)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <g opacity="0.5" style={{ mixBlendMode: 'screen' }}>
      <path
        d="M12.5175 20.3854C12.3584 20.2263 12.1031 20.2263 11.944 20.3854C11.7849 20.5445 11.7849 20.7998 11.944 20.9589C12.1031 21.118 12.3584 21.118 12.5175 20.9589C12.6766 20.7998 12.6766 20.5445 12.5175 20.3854Z"
        fill="url(#paint40_radial_2067_37736)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M12.2328 21.4068C12.6395 21.4068 12.9691 21.0772 12.9691 20.6706C12.9691 20.264 12.6395 19.9343 12.2328 19.9343C11.8262 19.9343 11.4966 20.264 11.4966 20.6706C11.4966 21.0772 11.8262 21.4068 12.2328 21.4068Z"
        fill="url(#paint41_radial_2067_37736)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <g style={{ mixBlendMode: 'color-dodge' }}>
      <path
        d="M12.5175 19.9787C12.3584 19.8196 12.1031 19.8196 11.944 19.9787C11.7849 20.1378 11.7849 20.3931 11.944 20.5522C12.1031 20.7112 12.3584 20.7112 12.5175 20.5522C12.6766 20.3931 12.6766 20.1378 12.5175 19.9787Z"
        fill="url(#paint42_radial_2067_37736)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M12.2328 21.0035C12.6395 21.0035 12.9691 20.6739 12.9691 20.2673C12.9691 19.8606 12.6395 19.531 12.2328 19.531C11.8262 19.531 11.4966 19.8606 11.4966 20.2673C11.4966 20.6739 11.8262 21.0035 12.2328 21.0035Z"
        fill="url(#paint43_radial_2067_37736)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <defs>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.6238 19.4472) rotate(19.78) scale(9.52315 9.52314)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.3558 20.1922) rotate(19.78) scale(9.14207)"
        gradientUnits="userSpaceOnUse"
        id="paint1_radial_2067_37736"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.11" stopColor="#FEFCF9" />
        <stop offset="0.26" stopColor="#FDF4E9" />
        <stop offset="0.42" stopColor="#FBE8CE" />
        <stop offset="0.6" stopColor="#F8D6A9" />
        <stop offset="0.78" stopColor="#F4BF7A" />
        <stop offset="0.98" stopColor="#F0A440" />
        <stop offset="1" stopColor="#F0A139" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(19.0742 12.6341) rotate(19.78) scale(16.9041)"
        gradientUnits="userSpaceOnUse"
        id="paint2_radial_2067_37736"
        r="1"
      >
        <stop />
        <stop offset="1" stopColor="#9DBF43" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(12.0272 16.7482) rotate(19.78) scale(3.13368)"
        gradientUnits="userSpaceOnUse"
        id="paint3_radial_2067_37736"
        r="1"
      >
        <stop />
        <stop offset="1" stopColor="#9DBF43" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2979.09 -1380.06) rotate(24.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint4_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(951.672 -1975.5) rotate(114.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint5_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(30.4735 5.94134) rotate(135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint6_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(30.4732 5.94152) rotate(135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint7_radial_2067_37736"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(30.4735 5.94146) rotate(135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint8_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-5.78731 -6.49512) rotate(-135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint9_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-5.78738 -6.49493) rotate(-135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint10_radial_2067_37736"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-5.78716 -6.49485) rotate(-135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint11_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2979.34 -1380.96) rotate(24.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint12_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(951.627 -1976.19) rotate(114.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint13_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(24.5341 -0.80045) rotate(135) scale(0.276112 3.81684)"
        gradientUnits="userSpaceOnUse"
        id="paint14_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(24.534 -0.800644) rotate(135) scale(0.0855381 1.18244)"
        gradientUnits="userSpaceOnUse"
        id="paint15_radial_2067_37736"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(24.5341 -0.800519) rotate(135) scale(0.116357 1.60846)"
        gradientUnits="userSpaceOnUse"
        id="paint16_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-5.79507 -7.287) rotate(-135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint17_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-5.79518 -7.28679) rotate(-135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint18_radial_2067_37736"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-5.79495 -7.28677) rotate(-135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint19_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(18.7252 20.8334) rotate(45) scale(0.680754)"
        gradientUnits="userSpaceOnUse"
        id="paint20_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(18.7252 20.8334) rotate(45) scale(1.23571)"
        gradientUnits="userSpaceOnUse"
        id="paint21_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(18.7252 20.1585) rotate(45) scale(0.680754)"
        gradientUnits="userSpaceOnUse"
        id="paint22_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(18.7252 20.1585) rotate(45) scale(1.23571)"
        gradientUnits="userSpaceOnUse"
        id="paint23_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2984.89 -1379.91) rotate(24.82) scale(0.0396613 1.71535)"
        gradientUnits="userSpaceOnUse"
        id="paint24_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(944.209 -1973.63) rotate(114.82) scale(0.0396613 1.71535)"
        gradientUnits="userSpaceOnUse"
        id="paint25_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.9095 5.81707) rotate(135) scale(0.164158 1.78642)"
        gradientUnits="userSpaceOnUse"
        id="paint26_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.9096 5.81714) rotate(135) scale(0.0509455 0.554407)"
        gradientUnits="userSpaceOnUse"
        id="paint27_radial_2067_37736"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.9095 5.81706) rotate(135) scale(0.0691852 0.752897)"
        gradientUnits="userSpaceOnUse"
        id="paint28_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-12.3554 -6.73296) rotate(-135) scale(0.164158 1.78642)"
        gradientUnits="userSpaceOnUse"
        id="paint29_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-12.3553 -6.73289) rotate(-135) scale(0.0509455 0.554407)"
        gradientUnits="userSpaceOnUse"
        id="paint30_radial_2067_37736"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-12.3554 -6.73297) rotate(-135) scale(0.0691852 0.752898)"
        gradientUnits="userSpaceOnUse"
        id="paint31_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2985.03 -1380.45) rotate(24.82) scale(0.0396613 1.71535)"
        gradientUnits="userSpaceOnUse"
        id="paint32_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(944.188 -1974.04) rotate(114.82) scale(0.0396613 1.71535)"
        gradientUnits="userSpaceOnUse"
        id="paint33_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(17.7341 -0.836878) rotate(135) scale(0.164158 2.26924)"
        gradientUnits="userSpaceOnUse"
        id="paint34_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(17.7341 -0.836888) rotate(135) scale(0.0509455 0.704247)"
        gradientUnits="userSpaceOnUse"
        id="paint35_radial_2067_37736"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(17.734 -0.836745) rotate(135) scale(0.0691852 0.956384)"
        gradientUnits="userSpaceOnUse"
        id="paint36_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-12.361 -7.20331) rotate(-135) scale(0.164158 1.78642)"
        gradientUnits="userSpaceOnUse"
        id="paint37_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-12.3609 -7.20328) rotate(-135) scale(0.0509455 0.554407)"
        gradientUnits="userSpaceOnUse"
        id="paint38_radial_2067_37736"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-12.361 -7.20314) rotate(-135) scale(0.0691852 0.752898)"
        gradientUnits="userSpaceOnUse"
        id="paint39_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(12.2164 20.6658) rotate(45) scale(0.403272)"
        gradientUnits="userSpaceOnUse"
        id="paint40_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(12.2166 20.666) rotate(45) scale(0.736249)"
        gradientUnits="userSpaceOnUse"
        id="paint41_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(12.2164 20.2631) rotate(45) scale(0.403272)"
        gradientUnits="userSpaceOnUse"
        id="paint42_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(12.2166 20.2631) rotate(45) scale(0.736249)"
        gradientUnits="userSpaceOnUse"
        id="paint43_radial_2067_37736"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default Active;
