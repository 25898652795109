const Elite = () => (
  <svg fill="none" height="45" viewBox="0 0 35 45" width="35" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 9.87832V33.3162L17.1113 43.1982L34.2226 33.3162V9.87832L17.1113 0L0 9.87832Z" fill="#011800" />
    <path
      d="M17.1112 37.6816L1.59131 28.7209V10.7995L17.1112 1.83883L32.6311 10.7995V28.7209L17.1112 37.6816Z"
      stroke="#00BD00"
    />
    <path d="M0 33.3162L17.1113 43.1982L34.2226 33.3162V35.1216L17.1113 45L0 35.1216V33.3162Z" fill="#00BD00" />
    <path
      d="M23.6771 17.4554L23.6253 17.5072L22.9112 18.2397L22.3637 18.8021L16.7512 8.47981V8.47241L11.098 18.8169L9.77345 17.4591L5.35596 12.9232L6.83216 25.4727V25.4801L6.86915 26.5605V27.6408C6.86915 28.7285 7.74969 29.6091 8.83742 29.6091H24.6131C25.7009 29.6091 26.5814 28.7285 26.5814 27.6408V26.5605L26.6221 25.4801L28.0983 12.9269L23.6771 17.4591V17.4554Z"
      fill="#000F11"
    />
    <path
      d="M28.0981 12.9232L23.9692 19.3016L23.1737 20.1192L22.6003 20.7038L16.751 9.9449V9.9375L10.861 20.7186L11.0978 18.8132L5.35206 12.9195L4.87109 14.5733L6.40649 26.7343V26.7417L6.44349 27.8665V28.9912C6.44349 30.1233 7.36102 31.0445 8.49684 31.0445H24.9422C26.0743 31.0445 26.9956 30.127 26.9956 28.9912V27.8665L27.0363 26.7417L28.5754 14.577L28.0944 12.9232H28.0981Z"
      fill="#000F11"
    />
    <path
      d="M6.86523 27.4041H26.5812V27.6519C26.5812 28.7397 25.6969 29.6202 24.6129 29.6202H8.8335C7.74577 29.6202 6.86523 28.736 6.86523 27.6519V27.4041Z"
      fill="url(#paint0_radial_2067_38173)"
    />
    <path d="M10.3467 18.0733L6.74315 24.7624L5.35205 12.9343L10.3467 18.0733Z" fill="url(#paint1_radial_2067_38173)" />
    <path
      d="M26.5812 26.5715H6.86523L16.7473 8.4834V8.4871L16.751 8.4945L26.5812 26.5715Z"
      fill="url(#paint2_radial_2067_38173)"
    />
    <path
      d="M8.93709 16.6155C8.93709 16.6155 7.98995 19.5938 6.12158 19.4828L6.74314 24.7586L10.3467 18.0695L8.93709 16.6155Z"
      fill="url(#paint3_radial_2067_38173)"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path d="M23.1035 18.0733L26.7071 24.7624L28.0982 12.9343L23.1035 18.0733Z" fill="url(#paint4_radial_2067_38173)" />
    <path
      d="M24.5131 16.6155C24.5131 16.6155 25.4603 19.5938 27.3286 19.4828L26.7071 24.7586L23.1035 18.0695L24.5131 16.6155Z"
      fill="url(#paint5_radial_2067_38173)"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      clipRule="evenodd"
      d="M13.6323 26.5715C13.5361 24.5959 12.7259 18.2175 12.4854 16.2751L10.0103 20.8147C10.3765 22.3686 11.0129 24.9695 11.2016 26.5567L13.6323 26.5752V26.5715Z"
      fill="url(#paint6_radial_2067_38173)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      clipRule="evenodd"
      d="M19.7847 26.5715C19.8809 24.5959 20.6911 18.2175 20.9316 16.2751L23.4067 20.8147C23.0404 22.3686 22.4041 24.9695 22.2154 26.5567L19.7847 26.5752V26.5715Z"
      fill="url(#paint7_radial_2067_38173)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      clipRule="evenodd"
      d="M16.7473 8.4873C16.7473 8.4873 11.3456 21.2218 6.86523 26.5717L16.7473 8.4873Z"
      fill="url(#paint8_linear_2067_38173)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.7471 8.4873C16.7471 8.4873 22.0969 21.2218 26.581 26.5717L16.7471 8.4873Z"
      fill="url(#paint9_linear_2067_38173)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.86523 27.4041H26.5812C26.5812 27.4041 15.6558 27.8924 6.86523 27.4041Z"
      fill="url(#paint10_radial_2067_38173)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'overlay' }}
    />
    <path
      clipRule="evenodd"
      d="M22.0973 27.5668L26.5851 27.4041V27.626C26.5851 28.7286 25.6897 29.6239 24.5872 29.6239H20.3584C20.3584 29.6239 21.7643 29.1244 22.0973 27.5668Z"
      fill="url(#paint11_radial_2067_38173)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      clipRule="evenodd"
      d="M11.353 27.5668L6.86523 27.4041V27.626C6.86523 28.7286 7.76057 29.6239 8.8631 29.6239H13.0919C13.0919 29.6239 11.686 29.1244 11.353 27.5668Z"
      fill="url(#paint12_radial_2067_38173)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      clipRule="evenodd"
      d="M15.2529 20.3338L15.4934 23.3713L16.7476 23.7265L18.0055 23.3713L18.246 20.3338L16.7476 19.5828L15.2529 20.3338Z"
      fill="url(#paint13_radial_2067_38173)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.7828 23.9522L15.4932 23.3714L15.2527 20.3339L14.4609 19.8862L14.7828 23.9522Z"
      fill="url(#paint14_radial_2067_38173)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      clipRule="evenodd"
      d="M15.2527 20.3338L16.7474 19.5828V18.7429L14.4609 19.8861L15.2527 20.3338Z"
      fill="url(#paint15_radial_2067_38173)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.7473 23.7265L15.4931 23.3713L14.7827 23.9522L16.7473 24.5109V23.7265Z"
      fill="url(#paint16_radial_2067_38173)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.7157 23.9522L18.0054 23.3714L18.2459 20.3339L19.0376 19.8862L18.7157 23.9522Z"
      fill="url(#paint17_radial_2067_38173)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.246 20.3338L16.7476 19.5828V18.7429L19.0377 19.8861L18.246 20.3338Z"
      fill="url(#paint18_radial_2067_38173)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.7476 23.7265L18.0055 23.3713L18.7158 23.9522L16.7476 24.5109V23.7265Z"
      fill="url(#paint19_radial_2067_38173)"
      fillRule="evenodd"
    />
    <g opacity="0.53" style={{ mixBlendMode: 'screen' }}>
      <path
        clipRule="evenodd"
        d="M15.9778 19.801C15.5524 20.0304 14.9456 20.1599 14.4609 19.8861L15.2527 20.3338L16.7474 19.5865V18.7429C16.7474 19.1906 16.3811 19.5828 15.9778 19.801Z"
        fill="#C0FF5B"
        fillRule="evenodd"
      />
    </g>
    <g opacity="0.53" style={{ mixBlendMode: 'screen' }}>
      <path
        clipRule="evenodd"
        d="M17.2951 23.6969C17.8612 23.5119 18.4753 23.7598 18.7121 23.9522L18.0018 23.3713L16.7476 23.7265V24.1779V24.3185C16.7476 24.0262 16.8918 23.8264 17.2951 23.6932V23.6969Z"
        fill="#C0FF5B"
        fillRule="evenodd"
      />
    </g>
    <path
      clipRule="evenodd"
      d="M14.461 19.8862L16.7511 18.7429V18.3804L14.1206 19.6938L14.461 19.8862Z"
      fill="#000F11"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.7829 23.9523L14.461 19.8862L14.1206 19.6938L14.4795 24.2038L14.7829 23.9523Z"
      fill="#000F11"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.7474 24.5108L14.7829 23.9521L14.4795 24.2037L16.7511 24.8475L16.7474 24.5108Z"
      fill="#000F11"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M19.0377 19.8862L16.7476 18.7429V18.3804L19.3781 19.6938L19.0377 19.8862Z"
      fill="#000F11"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.7158 23.9523L19.0377 19.8862L19.3781 19.6938L19.0192 24.2038L18.7158 23.9523Z"
      fill="#000F11"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.7476 24.5108L18.7158 23.9521L19.0192 24.2037L16.7476 24.8475V24.5108Z"
      fill="#000F11"
      fillRule="evenodd"
    />
    <g opacity="0.53" style={{ mixBlendMode: 'screen' }}>
      <path
        clipRule="evenodd"
        d="M14.2725 19.6197C15.0161 19.6012 16.1334 19.0574 16.5922 18.458L14.2725 19.6197Z"
        fill="#C0FF5B"
        fillRule="evenodd"
      />
    </g>
    <path
      clipRule="evenodd"
      d="M13.917 29.6203L13.6987 27.6224L11.353 27.5669L11.4825 29.6018L13.917 29.6203Z"
      fill="url(#paint20_radial_2067_38173)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      clipRule="evenodd"
      d="M19.4775 29.6203L19.6514 27.5928L22.097 27.5669L21.9083 29.6018L19.4775 29.6203Z"
      fill="url(#paint21_radial_2067_38173)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M18.6122 21.0923C18.5789 21.0775 19.5112 20.0897 20.6951 18.8873C21.8791 17.6849 22.8632 16.7229 22.8928 16.7377C22.9224 16.7525 21.9938 17.7403 20.8098 18.9428C19.6259 20.1452 18.6418 21.1071 18.6122 21.0923Z"
      fill="url(#paint22_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M18.5754 16.7748C18.5902 16.7415 19.578 17.6738 20.7804 18.8577C21.9828 20.0417 22.9448 21.0258 22.93 21.0554C22.9152 21.0887 21.9273 20.1564 20.7249 18.9724C19.5225 17.7885 18.5606 16.8044 18.5754 16.7748Z"
      fill="url(#paint23_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M22.8893 21.0257C22.9965 20.9184 22.1308 19.8825 20.958 18.7134C19.7852 17.5406 18.7492 16.6785 18.6456 16.7821C18.5421 16.8857 19.4041 17.9253 20.5769 19.0945C21.7497 20.2673 22.7857 21.1293 22.8893 21.0257Z"
      fill="url(#paint24_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M21.4239 19.5606C21.4572 19.5273 21.1908 19.2054 20.8246 18.8428C20.462 18.4802 20.1401 18.2139 20.1068 18.2435C20.0735 18.2731 20.3399 18.5986 20.7062 18.9612C21.0687 19.3238 21.3906 19.5902 21.4239 19.5606Z"
      fill="url(#paint25_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M21.661 19.7973C21.7054 19.7529 21.3391 19.3163 20.8471 18.8206C20.355 18.3248 19.9147 17.9622 19.8703 18.0066C19.8259 18.051 20.1922 18.4876 20.6843 18.9833C21.1801 19.4791 21.6166 19.8417 21.661 19.7973Z"
      fill="url(#paint26_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M18.6417 21.0258C18.749 21.1331 19.7849 20.2673 20.954 19.0945C22.1269 17.9217 22.9889 16.8858 22.8853 16.7822C22.778 16.6749 21.7421 17.5406 20.573 18.7134C19.4039 19.8863 18.5381 20.9222 18.6417 21.0258Z"
      fill="url(#paint27_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M20.1067 19.5606C20.14 19.5939 20.4619 19.3276 20.8245 18.9613C21.1871 18.5987 21.4534 18.2768 21.4238 18.2435C21.3942 18.2102 21.0687 18.4766 20.7061 18.8429C20.3435 19.2055 20.0771 19.5273 20.1067 19.5606Z"
      fill="url(#paint28_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M19.8699 19.7975C19.9143 19.8419 20.3509 19.4756 20.8466 18.9836C21.3424 18.4878 21.705 18.0512 21.6606 18.0068C21.6162 17.9624 21.1796 18.3287 20.6838 18.8208C20.1881 19.3165 19.8255 19.7531 19.8699 19.7975Z"
      fill="url(#paint29_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M18.6122 20.3079C18.5789 20.2931 19.5112 19.3053 20.6951 18.1028C21.8791 16.9004 22.8632 15.9385 22.8928 15.9533C22.9261 15.9681 21.9938 16.9559 20.8098 18.1583C19.6259 19.3608 18.6418 20.3227 18.6122 20.3079Z"
      fill="url(#paint30_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M18.5754 15.9904C18.5902 15.9571 19.578 16.8894 20.7804 18.0733C21.9828 19.2572 22.9448 20.2414 22.93 20.271C22.9152 20.3043 21.9273 19.3719 20.7249 18.188C19.5225 17.0041 18.5606 16.02 18.5754 15.9904Z"
      fill="url(#paint31_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M24.0289 21.381C24.1362 21.2737 23.0152 19.9825 21.5279 18.4989C20.0406 17.0116 18.7494 15.8943 18.6458 15.9979C18.5385 16.1052 19.6595 17.3964 21.1468 18.88C22.6341 20.3636 23.9253 21.4846 24.0289 21.381Z"
      fill="url(#paint32_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M22.1681 19.5199C22.2014 19.4866 21.8536 19.087 21.3949 18.6282C20.9361 18.1695 20.5328 17.8217 20.5032 17.855C20.4736 17.8883 20.8177 18.2879 21.2765 18.7466C21.7353 19.2054 22.1385 19.5532 22.1681 19.5199Z"
      fill="url(#paint33_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M22.4713 19.8234C22.5157 19.779 22.0421 19.2351 21.4169 18.6062C20.7879 17.9772 20.2441 17.5073 20.1997 17.5517C20.1553 17.5961 20.6288 18.14 21.2541 18.7689C21.883 19.3979 22.4269 19.8678 22.4713 19.8234Z"
      fill="url(#paint34_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M18.6417 20.2414C18.749 20.3487 19.785 19.4829 20.9541 18.3101C22.1269 17.1373 22.9889 16.1014 22.8853 15.9978C22.7781 15.8905 21.7421 16.7562 20.573 17.929C19.4002 19.1018 18.5381 20.1378 18.6417 20.2414Z"
      fill="url(#paint35_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M20.1067 18.7762C20.14 18.8095 20.4619 18.5431 20.8245 18.1769C21.1871 17.8143 21.4534 17.4924 21.4238 17.4591C21.3942 17.4258 21.0687 17.6922 20.7061 18.0585C20.3435 18.421 20.0771 18.7429 20.1067 18.7762Z"
      fill="url(#paint36_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M19.8699 19.0131C19.9143 19.0575 20.3509 18.6912 20.8466 18.1991C21.3424 17.7071 21.705 17.2668 21.6606 17.2224C21.6162 17.178 21.1796 17.5443 20.6838 18.0363C20.1881 18.5284 19.8255 18.9687 19.8699 19.0131Z"
      fill="url(#paint37_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <g opacity="0.5" style={{ mixBlendMode: 'screen' }}>
      <path
        d="M21.2501 18.3951C20.9837 18.1287 20.5545 18.1287 20.2882 18.3951C20.0218 18.6615 20.0218 19.0907 20.2882 19.357C20.5545 19.6234 20.9837 19.6234 21.2501 19.357C21.5165 19.0907 21.5165 18.6615 21.2501 18.3951Z"
        fill="url(#paint38_radial_2067_38173)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M20.7728 20.1118C21.4553 20.1118 22.0085 19.5586 22.0085 18.8761C22.0085 18.1936 21.4553 17.6404 20.7728 17.6404C20.0904 17.6404 19.5371 18.1936 19.5371 18.8761C19.5371 19.5586 20.0904 20.1118 20.7728 20.1118Z"
        fill="url(#paint39_radial_2067_38173)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <g style={{ mixBlendMode: 'color-dodge' }}>
      <path
        d="M21.2501 17.7144C20.9837 17.4481 20.5545 17.4481 20.2882 17.7144C20.0218 17.9808 20.0218 18.41 20.2882 18.6764C20.5545 18.9427 20.9837 18.9427 21.2501 18.6764C21.5165 18.41 21.5165 17.9808 21.2501 17.7144Z"
        fill="url(#paint40_radial_2067_38173)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M20.7728 19.4311C21.4553 19.4311 22.0085 18.8779 22.0085 18.1954C22.0085 17.513 21.4553 16.9597 20.7728 16.9597C20.0904 16.9597 19.5371 17.513 19.5371 18.1954C19.5371 18.8779 20.0904 19.4311 20.7728 19.4311Z"
        fill="url(#paint41_radial_2067_38173)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <path
      d="M14.7533 30.0381C14.72 30.0233 15.6523 29.0355 16.8363 27.8331C18.0202 26.6307 19.0043 25.6687 19.0339 25.6835C19.0635 25.6983 18.1349 26.6861 16.951 27.8886C15.767 29.091 14.7829 30.0529 14.7533 30.0381Z"
      fill="url(#paint42_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M14.7165 25.7206C14.7313 25.6873 15.7191 26.6196 16.9215 27.8035C18.1239 28.9875 19.0859 29.9716 19.0711 30.0012C19.0563 30.0345 18.0684 29.1022 16.866 27.9182C15.6636 26.7343 14.7017 25.7502 14.7165 25.7206Z"
      fill="url(#paint43_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M19.0265 29.9715C19.1338 29.8642 18.268 28.8283 17.0952 27.6592C15.9224 26.4864 14.8864 25.6243 14.7829 25.7279C14.6793 25.8315 15.5413 26.8711 16.7141 28.0403C17.8869 29.2131 18.9229 30.0751 19.0265 29.9715Z"
      fill="url(#paint44_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M17.5616 28.5066C17.5949 28.4733 17.3285 28.1514 16.9623 27.7889C16.5997 27.4263 16.2778 27.1599 16.2445 27.1895C16.2112 27.2191 16.4776 27.5447 16.8439 27.9073C17.2064 28.2698 17.5283 28.5362 17.5616 28.5066Z"
      fill="url(#paint45_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M17.8016 28.7433C17.846 28.6989 17.4798 28.2624 16.9877 27.7666C16.4956 27.2708 16.0554 26.9083 16.011 26.9527C15.9666 26.997 16.3328 27.4336 16.8249 27.9294C17.3207 28.4252 17.7573 28.7877 17.8016 28.7433Z"
      fill="url(#paint46_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M14.7828 29.9716C14.8901 30.0789 15.926 29.2131 17.0952 28.0403C18.268 26.8675 19.13 25.8316 19.0264 25.728C18.9191 25.6207 17.8832 26.4864 16.7141 27.6592C15.545 28.8321 14.6792 29.868 14.7828 29.9716Z"
      fill="url(#paint47_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M16.2479 28.5064C16.2811 28.5397 16.603 28.2734 16.9656 27.9071C17.3282 27.5445 17.5946 27.2226 17.565 27.1893C17.5354 27.156 17.2098 27.4224 16.8472 27.7887C16.4846 28.1513 16.2183 28.4731 16.2479 28.5064Z"
      fill="url(#paint48_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M16.0076 28.7433C16.052 28.7877 16.4886 28.4214 16.9843 27.9294C17.4801 27.4336 17.8427 26.997 17.7983 26.9526C17.7539 26.9082 17.3173 27.2745 16.8215 27.7666C16.3258 28.2623 15.9632 28.6989 16.0076 28.7433Z"
      fill="url(#paint49_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M14.7533 29.2539C14.72 29.2391 15.6523 28.2513 16.8363 27.0489C18.0202 25.8465 19.0043 24.8845 19.0339 24.8993C19.0672 24.9141 18.1349 25.902 16.951 27.1044C15.767 28.3068 14.7829 29.2687 14.7533 29.2539Z"
      fill="url(#paint50_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M14.7165 24.9325C14.7313 24.8992 15.7191 25.8315 16.9215 27.0155C18.1239 28.1994 19.0859 29.1835 19.0711 29.2131C19.0563 29.2464 18.0684 28.3141 16.866 27.1302C15.6636 25.9462 14.7017 24.9621 14.7165 24.9325Z"
      fill="url(#paint51_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M20.1661 30.3268C20.2734 30.2195 19.1524 28.9283 17.6651 27.4447C16.1778 25.9574 14.8866 24.8401 14.783 24.9437C14.6757 25.051 15.7967 26.3422 17.284 27.8258C18.7713 29.3094 20.0625 30.4304 20.1661 30.3268Z"
      fill="url(#paint52_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M18.3088 28.4659C18.342 28.4326 17.9943 28.033 17.5355 27.5743C17.0767 27.1155 16.6735 26.7677 16.6439 26.801C16.6143 26.8343 16.9583 27.2339 17.4171 27.6927C17.8759 28.1514 18.2792 28.4992 18.3088 28.4659Z"
      fill="url(#paint53_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M18.6124 28.7692C18.6568 28.7248 18.1832 28.1809 17.558 27.552C16.929 26.923 16.3852 26.4531 16.3408 26.4975C16.2964 26.5419 16.7699 27.0858 17.3952 27.7147C18.0242 28.3437 18.568 28.8136 18.6124 28.7692Z"
      fill="url(#paint54_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M14.7829 29.1835C14.8901 29.2908 15.9261 28.4251 17.0952 27.2522C18.268 26.0794 19.1301 25.0435 19.0265 24.9399C18.9192 24.8326 17.8832 25.6983 16.7141 26.8712C15.5413 28.044 14.6793 29.0799 14.7829 29.1835Z"
      fill="url(#paint55_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M16.2479 27.7186C16.2811 27.7519 16.603 27.4855 16.9656 27.1192C17.3282 26.7567 17.5946 26.4348 17.565 26.4015C17.5354 26.3682 17.2098 26.6346 16.8472 27.0009C16.4846 27.3634 16.2183 27.6853 16.2479 27.7186Z"
      fill="url(#paint56_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M16.0076 27.9591C16.052 28.0035 16.4886 27.6372 16.9843 27.1452C17.4801 26.6531 17.8427 26.2128 17.7983 26.1684C17.7539 26.124 17.3173 26.4903 16.8215 26.9824C16.3258 27.4744 15.9632 27.9147 16.0076 27.9591Z"
      fill="url(#paint57_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <g opacity="0.5" style={{ mixBlendMode: 'screen' }}>
      <path
        d="M17.3917 27.3411C17.1253 27.0748 16.6961 27.0748 16.4298 27.3411C16.1634 27.6075 16.1634 28.0367 16.4298 28.3031C16.6961 28.5695 17.1253 28.5695 17.3917 28.3031C17.6581 28.0367 17.6581 27.6075 17.3917 27.3411Z"
        fill="url(#paint58_radial_2067_38173)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M16.9105 29.0579C17.593 29.0579 18.1462 28.5046 18.1462 27.8221C18.1462 27.1397 17.593 26.5864 16.9105 26.5864C16.2281 26.5864 15.6748 27.1397 15.6748 27.8221C15.6748 28.5046 16.2281 29.0579 16.9105 29.0579Z"
        fill="url(#paint59_radial_2067_38173)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <g style={{ mixBlendMode: 'color-dodge' }}>
      <path
        d="M17.3917 26.6605C17.1253 26.3941 16.6961 26.3941 16.4298 26.6605C16.1634 26.9269 16.1634 27.356 16.4298 27.6224C16.6961 27.8888 17.1253 27.8888 17.3917 27.6224C17.6581 27.356 17.6581 26.9269 17.3917 26.6605Z"
        fill="url(#paint60_radial_2067_38173)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M16.9105 28.3769C17.593 28.3769 18.1462 27.8237 18.1462 27.1412C18.1462 26.4588 17.593 25.9055 16.9105 25.9055C16.2281 25.9055 15.6748 26.4588 15.6748 27.1412C15.6748 27.8237 16.2281 28.3769 16.9105 28.3769Z"
        fill="url(#paint61_radial_2067_38173)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <path
      d="M7.82704 24.4886C7.79374 24.4738 8.72608 23.4859 9.90999 22.2835C11.0939 21.0811 12.078 20.1192 12.1076 20.134C12.1372 20.1488 11.2086 21.1366 10.0247 22.339C8.84077 23.5414 7.85663 24.5034 7.82704 24.4886Z"
      fill="url(#paint62_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M7.79021 20.171C7.80501 20.1377 8.79284 21.0701 9.99526 22.254C11.1977 23.4379 12.1596 24.422 12.1448 24.4516C12.13 24.4849 11.1422 23.5526 9.93976 22.3687C8.73734 21.1848 7.77541 20.2006 7.79021 20.171Z"
      fill="url(#paint63_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M12.1002 24.422C12.2075 24.3147 11.3417 23.2787 10.1689 22.1096C8.99611 20.9368 7.96018 20.0748 7.85658 20.1784C7.75299 20.282 8.61503 21.3216 9.78785 22.4907C10.9607 23.6635 11.9966 24.5256 12.1002 24.422Z"
      fill="url(#paint64_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M10.6349 22.957C10.6682 22.9238 10.4018 22.6019 10.0355 22.2393C9.67293 21.8767 9.35105 21.6103 9.31775 21.6399C9.28445 21.6695 9.55084 21.9951 9.91711 22.3577C10.2797 22.7203 10.6016 22.9866 10.6349 22.957Z"
      fill="url(#paint65_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M10.8754 23.1938C10.9198 23.1494 10.5535 22.7128 10.0614 22.217C9.56937 21.7213 9.1291 21.3587 9.0847 21.4031C9.0403 21.4475 9.40658 21.8841 9.89864 22.3798C10.3944 22.8756 10.831 23.2382 10.8754 23.1938Z"
      fill="url(#paint66_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M7.85655 24.422C7.96384 24.5293 8.99977 23.6636 10.1689 22.4908C11.3417 21.3179 12.2038 20.282 12.1002 20.1784C11.9929 20.0711 10.9569 20.9369 9.78782 22.1097C8.6187 23.2825 7.75296 24.3184 7.85655 24.422Z"
      fill="url(#paint67_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M9.32158 22.9571C9.35488 22.9904 9.67676 22.724 10.0393 22.3578C10.4019 21.9952 10.6683 21.6733 10.6387 21.64C10.6091 21.6067 10.2835 21.8731 9.92094 22.2394C9.55836 22.602 9.29198 22.9238 9.32158 22.9571Z"
      fill="url(#paint68_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M9.08473 23.1937C9.12913 23.2381 9.5657 22.8719 10.0615 22.3798C10.5572 21.884 10.9198 21.4475 10.8754 21.4031C10.831 21.3587 10.3944 21.7249 9.89868 22.217C9.40291 22.7128 9.04034 23.1493 9.08473 23.1937Z"
      fill="url(#paint69_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M7.82704 23.7044C7.79374 23.6896 8.72608 22.7017 9.90999 21.4993C11.0939 20.2969 12.078 19.335 12.1076 19.3498C12.1409 19.3646 11.2086 20.3524 10.0247 21.5548C8.84077 22.7572 7.85663 23.7192 7.82704 23.7044Z"
      fill="url(#paint70_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M7.79021 19.3829C7.80501 19.3496 8.79284 20.282 9.99526 21.4659C11.1977 22.6498 12.1596 23.634 12.1448 23.6635C12.13 23.6968 11.1422 22.7645 9.93976 21.5806C8.73734 20.3967 7.77541 19.4125 7.79021 19.3829Z"
      fill="url(#paint71_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M13.2398 24.7772C13.3471 24.6699 12.2261 23.3787 10.7388 21.8951C9.25152 20.4078 7.9603 19.2905 7.85671 19.3941C7.74942 19.5014 8.87044 20.7926 10.3577 22.2762C11.845 23.7598 13.1362 24.8808 13.2398 24.7772Z"
      fill="url(#paint72_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M11.3825 22.9164C11.4158 22.8831 11.068 22.4835 10.6092 22.0247C10.1505 21.566 9.74719 21.2182 9.7176 21.2515C9.688 21.2848 10.0321 21.6843 10.4908 22.1431C10.9496 22.6019 11.3529 22.9497 11.3825 22.9164Z"
      fill="url(#paint73_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M11.6861 23.2196C11.7305 23.1752 11.257 22.6314 10.6317 22.0024C10.0028 21.3734 9.4589 20.9036 9.4145 20.948C9.37011 20.9924 9.84368 21.5362 10.4689 22.1652C11.0979 22.7941 11.6418 23.264 11.6861 23.2196Z"
      fill="url(#paint74_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <path
      d="M7.85658 23.6339C7.96388 23.7412 8.9998 22.8755 10.1689 21.7027C11.3417 20.5299 12.2038 19.4939 12.1002 19.3903C11.9929 19.283 10.957 20.1488 9.78785 21.3216C8.61503 22.4944 7.75299 23.5304 7.85658 23.6339Z"
      fill="url(#paint75_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M9.32158 22.169C9.35488 22.2023 9.67676 21.936 10.0393 21.5697C10.4019 21.2071 10.6683 20.8852 10.6387 20.8519C10.6091 20.8186 10.2835 21.085 9.92094 21.4513C9.55836 21.8139 9.29198 22.1357 9.32158 22.169Z"
      fill="url(#paint76_radial_2067_38173)"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      d="M9.08473 22.4093C9.12913 22.4537 9.5657 22.0874 10.0615 21.5954C10.5572 21.1033 10.9198 20.663 10.8754 20.6186C10.831 20.5742 10.3944 20.9405 9.89868 21.4326C9.40291 21.9246 9.04034 22.3649 9.08473 22.4093Z"
      fill="url(#paint77_radial_2067_38173)"
      style={{ mixBlendMode: 'color-dodge' }}
    />
    <g opacity="0.5" style={{ mixBlendMode: 'screen' }}>
      <path
        d="M10.4654 21.7916C10.199 21.5252 9.76988 21.5252 9.5035 21.7916C9.23712 22.058 9.23712 22.4871 9.5035 22.7535C9.76988 23.0199 10.199 23.0199 10.4654 22.7535C10.7318 22.4871 10.7318 22.058 10.4654 21.7916Z"
        fill="url(#paint78_radial_2067_38173)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M9.98425 23.5083C10.6667 23.5083 11.22 22.955 11.22 22.2726C11.22 21.5901 10.6667 21.0369 9.98425 21.0369C9.30178 21.0369 8.74854 21.5901 8.74854 22.2726C8.74854 22.955 9.30178 23.5083 9.98425 23.5083Z"
        fill="url(#paint79_radial_2067_38173)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <g style={{ mixBlendMode: 'color-dodge' }}>
      <path
        d="M10.4654 21.1107C10.199 20.8443 9.76988 20.8443 9.5035 21.1107C9.23712 21.3771 9.23712 21.8062 9.5035 22.0726C9.76988 22.339 10.199 22.339 10.4654 22.0726C10.7318 21.8062 10.7318 21.3771 10.4654 21.1107Z"
        fill="url(#paint80_radial_2067_38173)"
        style={{ mixBlendMode: 'screen' }}
      />
      <path
        d="M9.98425 22.8274C10.6667 22.8274 11.22 22.2741 11.22 21.5917C11.22 20.9092 10.6667 20.356 9.98425 20.356C9.30178 20.356 8.74854 20.9092 8.74854 21.5917C8.74854 22.2741 9.30178 22.8274 9.98425 22.8274Z"
        fill="url(#paint81_radial_2067_38173)"
        style={{ mixBlendMode: 'color-dodge' }}
      />
    </g>
    <defs>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.8952 24.9881) scale(7.01472 7.01471)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(15.6151 11.1399) scale(12.4755)"
        gradientUnits="userSpaceOnUse"
        id="paint1_radial_2067_38173"
        r="1"
      >
        <stop offset="0.23" stopColor="#97CE41" />
        <stop offset="0.33" stopColor="#77CA33" />
        <stop offset="0.62" stopColor="#21C00E" />
        <stop offset="0.76" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.7251 17.5293) scale(9.46025)"
        gradientUnits="userSpaceOnUse"
        id="paint2_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(14.8012 15.3835) scale(11.9539)"
        gradientUnits="userSpaceOnUse"
        id="paint3_radial_2067_38173"
        r="1"
      >
        <stop offset="0.34" stopColor="white" stopOpacity="0" />
        <stop offset="0.46" stopColor="#F6E8D7" stopOpacity="0.2" />
        <stop offset="0.74" stopColor="#E2AF74" stopOpacity="0.69" />
        <stop offset="0.91" stopColor="#D58B36" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(17.8351 11.1399) rotate(-180) scale(12.4755 12.4755)"
        gradientUnits="userSpaceOnUse"
        id="paint4_radial_2067_38173"
        r="1"
      >
        <stop offset="0.23" stopColor="#97CE41" />
        <stop offset="0.33" stopColor="#77CA33" />
        <stop offset="0.62" stopColor="#21C00E" />
        <stop offset="0.76" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(18.6453 15.3835) rotate(-180) scale(11.9539 11.9539)"
        gradientUnits="userSpaceOnUse"
        id="paint5_radial_2067_38173"
        r="1"
      >
        <stop offset="0.34" stopColor="white" stopOpacity="0" />
        <stop offset="0.46" stopColor="#F6E8D7" stopOpacity="0.2" />
        <stop offset="0.74" stopColor="#E2AF74" stopOpacity="0.69" />
        <stop offset="0.91" stopColor="#D58B36" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(10.7465 16.5119) scale(11.9243 11.9243)"
        gradientUnits="userSpaceOnUse"
        id="paint6_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(22.6705 16.5119) rotate(180) scale(11.9243 11.9243)"
        gradientUnits="userSpaceOnUse"
        id="paint7_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="1" />
      </radialGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint8_linear_2067_38173"
        x1="6.86523"
        x2="16.7473"
        y1="17.5295"
        y2="17.5295"
      >
        <stop offset="0.23" stopColor="#97CE41" />
        <stop offset="0.33" stopColor="#77CA33" />
        <stop offset="0.62" stopColor="#21C00E" />
        <stop offset="0.76" stopColor="#00BD00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint9_linear_2067_38173"
        x1="26.581"
        x2="16.7471"
        y1="17.5295"
        y2="17.5295"
      >
        <stop offset="0.23" stopColor="#97CE41" />
        <stop offset="0.33" stopColor="#77CA33" />
        <stop offset="0.62" stopColor="#21C00E" />
        <stop offset="0.76" stopColor="#00BD00" />
      </linearGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.7251 27.5113) scale(15.6684)"
        gradientUnits="userSpaceOnUse"
        id="paint10_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(21.3092 28.44) scale(5.39423)"
        gradientUnits="userSpaceOnUse"
        id="paint11_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.24" stopColor="#F1DBC0" stopOpacity="0.31" />
        <stop offset="0.5" stopColor="#E5B885" stopOpacity="0.6" />
        <stop offset="0.72" stopColor="#DCA05A" stopOpacity="0.82" />
        <stop offset="0.88" stopColor="#D7903F" stopOpacity="0.95" />
        <stop offset="0.98" stopColor="#D58B36" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(12.1374 28.44) rotate(180) scale(5.39423)"
        gradientUnits="userSpaceOnUse"
        id="paint12_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.24" stopColor="#F1DBC0" stopOpacity="0.31" />
        <stop offset="0.5" stopColor="#E5B885" stopOpacity="0.6" />
        <stop offset="0.72" stopColor="#DCA05A" stopOpacity="0.82" />
        <stop offset="0.88" stopColor="#D7903F" stopOpacity="0.95" />
        <stop offset="0.98" stopColor="#D58B36" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.1298 19.7936) scale(5.31653 5.31653)"
        gradientUnits="userSpaceOnUse"
        id="paint13_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(13.8838 20.0712) scale(8.80168 8.80169)"
        gradientUnits="userSpaceOnUse"
        id="paint14_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(15.1306 17.7181) scale(4.02162 4.02162)"
        gradientUnits="userSpaceOnUse"
        id="paint15_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#9CCB55" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(14.7309 26.8269) scale(3.80334 3.80334)"
        gradientUnits="userSpaceOnUse"
        id="paint16_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.33" stopColor="#32741E" />
        <stop offset="1" stopColor="#21511E" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.6735 20.0157) scale(4.436 4.43599)"
        gradientUnits="userSpaceOnUse"
        id="paint17_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#58BC38" />
        <stop offset="1" stopColor="#2B6519" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(17.0805 18.5579) scale(6.69654 6.69654)"
        gradientUnits="userSpaceOnUse"
        id="paint18_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.33" stopColor="#32741E" />
        <stop offset="1" stopColor="#21511E" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.5552 20.578) scale(4.4027)"
        gradientUnits="userSpaceOnUse"
        id="paint19_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#9CCB55" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(11.8747 27.615) scale(3.26318 3.26317)"
        gradientUnits="userSpaceOnUse"
        id="paint20_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" stopOpacity="0.4" />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(21.5642 27.615) rotate(180) scale(3.30017 3.30017)"
        gradientUnits="userSpaceOnUse"
        id="paint21_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" stopOpacity="0.4" />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2993.23 -1389.59) rotate(24.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint22_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(972.729 -2018.2) rotate(114.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint23_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(33.9829 2.55356) rotate(135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint24_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(33.9827 2.55351) rotate(135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint25_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(33.983 2.55344) rotate(135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint26_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2.31984 -7.06526) rotate(-135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint27_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2.31992 -7.06529) rotate(-135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint28_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2.31998 -7.06524) rotate(-135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint29_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2993.48 -1390.49) rotate(24.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint30_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(972.684 -2018.9) rotate(114.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint31_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(28.131 -4.09709) rotate(135) scale(0.276112 3.81684)"
        gradientUnits="userSpaceOnUse"
        id="paint32_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(28.1312 -4.09725) rotate(135) scale(0.0855381 1.18244)"
        gradientUnits="userSpaceOnUse"
        id="paint33_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(28.131 -4.09714) rotate(135) scale(0.116357 1.60846)"
        gradientUnits="userSpaceOnUse"
        id="paint34_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2.32276 -7.86202) rotate(-135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint35_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2.32288 -7.86208) rotate(-135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint36_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2.32293 -7.862) rotate(-135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint37_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(20.7578 18.87) rotate(45) scale(0.680753)"
        gradientUnits="userSpaceOnUse"
        id="paint38_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(20.7578 18.8701) rotate(45) scale(1.23571)"
        gradientUnits="userSpaceOnUse"
        id="paint39_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(20.7578 18.1899) rotate(45) scale(0.680753)"
        gradientUnits="userSpaceOnUse"
        id="paint40_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(20.7578 18.19) rotate(45) scale(1.23571)"
        gradientUnits="userSpaceOnUse"
        id="paint41_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2993.85 -1379.13) rotate(24.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint42_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(968.79 -2009.11) rotate(114.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint43_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(30.0063 11.6115) rotate(135) scale(0.0314478 0.342226)"
        gradientUnits="userSpaceOnUse"
        id="paint44_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(30.0066 11.6117) rotate(135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint45_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(30.0061 11.6116) rotate(135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint46_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-6.13547 1.92512) rotate(-135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint47_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-6.13555 1.92504) rotate(-135) scale(0.0855382 0.930857)"
        gradientUnits="userSpaceOnUse"
        id="paint48_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-6.13533 1.92513) rotate(-135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint49_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-2994.09 -1380.03) rotate(24.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint50_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(968.744 -2009.8) rotate(114.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint51_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(24.1588 4.96515) rotate(135) scale(0.276112 3.81684)"
        gradientUnits="userSpaceOnUse"
        id="paint52_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(24.1587 4.96524) rotate(135) scale(0.0855381 1.18244)"
        gradientUnits="userSpaceOnUse"
        id="paint53_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(24.159 4.96513) rotate(135) scale(0.116357 1.60846)"
        gradientUnits="userSpaceOnUse"
        id="paint54_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-6.14323 1.13325) rotate(-135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint55_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-6.14335 1.13345) rotate(-135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint56_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-6.14312 1.13348) rotate(-135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint57_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.8969 27.8172) rotate(45) scale(0.184987)"
        gradientUnits="userSpaceOnUse"
        id="paint58_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.8967 27.8172) rotate(45) scale(1.23571)"
        gradientUnits="userSpaceOnUse"
        id="paint59_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.8969 27.1371) rotate(45.0001) scale(0.680754)"
        gradientUnits="userSpaceOnUse"
        id="paint60_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.8967 27.1369) rotate(45) scale(1.23571)"
        gradientUnits="userSpaceOnUse"
        id="paint61_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-3001.69 -1385.11) rotate(24.82) scale(0.014799 0.640056)"
        gradientUnits="userSpaceOnUse"
        id="paint62_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(960.548 -2011.84) rotate(114.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint63_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.0675 6.07305) rotate(135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint64_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.0672 6.07325) rotate(135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint65_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.0673 6.07317) rotate(135) scale(0.0314478 0.342226)"
        gradientUnits="userSpaceOnUse"
        id="paint66_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-13.1717 -3.73623) rotate(-135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint67_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-13.1718 -3.73601) rotate(-135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint68_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-13.1719 -3.7362) rotate(-135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint69_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#FF8705" />
        <stop offset="0.12" stopColor="#FF8909" stopOpacity="0.89" />
        <stop offset="0.38" stopColor="#FF8E15" stopOpacity="0.6" />
        <stop offset="0.77" stopColor="#FF9729" stopOpacity="0.14" />
        <stop offset="0.88" stopColor="#FF9A2F" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-3001.94 -1386.01) rotate(24.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint70_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(960.503 -2012.54) rotate(114.82) scale(0.0665954 2.88025)"
        gradientUnits="userSpaceOnUse"
        id="paint71_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#C0FF5B" />
        <stop offset="0.07" stopColor="#AFF953" stopOpacity="0.97" />
        <stop offset="0.21" stopColor="#85EA3F" stopOpacity="0.91" />
        <stop offset="0.4" stopColor="#41D31F" stopOpacity="0.8" />
        <stop offset="0.57" stopColor="#00BD00" stopOpacity="0.69" />
        <stop offset="0.64" stopColor="#03AE00" stopOpacity="0.62" />
        <stop offset="0.76" stopColor="#0D8901" stopOpacity="0.45" />
        <stop offset="0.91" stopColor="#1C4D03" stopOpacity="0.17" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(17.2117 -0.580563) rotate(135) scale(0.276112 3.81684)"
        gradientUnits="userSpaceOnUse"
        id="paint72_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(17.2116 -0.580469) rotate(135) scale(0.0855381 1.18244)"
        gradientUnits="userSpaceOnUse"
        id="paint73_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(17.2119 -0.580588) rotate(135) scale(0.116357 1.60846)"
        gradientUnits="userSpaceOnUse"
        id="paint74_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-13.1795 -4.52811) rotate(-135) scale(0.276112 3.00475)"
        gradientUnits="userSpaceOnUse"
        id="paint75_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.12" stopColor="#01B800" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="#04AD00" stopOpacity="0.89" />
        <stop offset="0.43" stopColor="#089A01" stopOpacity="0.76" />
        <stop offset="0.6" stopColor="#0F7F02" stopOpacity="0.58" />
        <stop offset="0.78" stopColor="#185C03" stopOpacity="0.35" />
        <stop offset="0.96" stopColor="#233304" stopOpacity="0.07" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-13.1796 -4.52789) rotate(-135) scale(0.0855381 0.930856)"
        gradientUnits="userSpaceOnUse"
        id="paint76_radial_2067_38173"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#FAFAF9" stopOpacity="0.98" />
        <stop offset="0.23" stopColor="#EDEEEB" stopOpacity="0.92" />
        <stop offset="0.36" stopColor="#D9D9D3" stopOpacity="0.83" />
        <stop offset="0.5" stopColor="#BBBCB1" stopOpacity="0.69" />
        <stop offset="0.65" stopColor="#969786" stopOpacity="0.52" />
        <stop offset="0.81" stopColor="#686A51" stopOpacity="0.3" />
        <stop offset="0.96" stopColor="#323513" stopOpacity="0.06" />
        <stop offset="1" stopColor="#262905" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-13.1797 -4.52812) rotate(-135) scale(0.116357 1.26624)"
        gradientUnits="userSpaceOnUse"
        id="paint77_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="0.16" stopColor="#00BD00" stopOpacity="0.85" />
        <stop offset="0.5" stopColor="#00BD00" stopOpacity="0.46" />
        <stop offset="0.88" stopColor="#00BD00" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(9.97169 22.2685) rotate(45) scale(0.184987)"
        gradientUnits="userSpaceOnUse"
        id="paint78_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(9.97147 22.2685) rotate(45) scale(1.23571)"
        gradientUnits="userSpaceOnUse"
        id="paint79_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(9.97169 21.5881) rotate(45) scale(0.680753)"
        gradientUnits="userSpaceOnUse"
        id="paint80_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(9.97147 21.5882) rotate(45) scale(1.23571)"
        gradientUnits="userSpaceOnUse"
        id="paint81_radial_2067_38173"
        r="1"
      >
        <stop stopColor="#00BD00" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default Elite;
