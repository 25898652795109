const Vip = () => (
  <svg fill="none" height="45" viewBox="0 0 35 45" width="35" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 9.87832V33.3162L17.1113 43.1982L34.2226 33.3162V9.87832L17.1113 0L0 9.87832Z" fill="#011800" />
    <path
      d="M17.1112 37.6816L1.59131 28.7209V10.7995L17.1112 1.83883L32.6311 10.7995V28.7209L17.1112 37.6816Z"
      stroke="#00BD00"
    />
    <path d="M0 33.3162L17.1113 43.1982L34.2226 33.3162V35.1216L17.1113 45L0 35.1216V33.3162Z" fill="#00BD00" />
    <path d="M17.6592 30.7372V19.2014H27.1638L17.6592 30.7372Z" fill="url(#paint0_radial_2067_37955)" />
    <path
      d="M21.2775 17.692L17.8774 11.9019C19.2167 11.9315 22.232 11.9278 23.575 11.9019L27.4487 17.6957H21.2775V17.692Z"
      fill="url(#paint1_radial_2067_37955)"
    />
    <path
      d="M13.5711 17.6918C13.6488 17.5179 16.8158 11.9646 16.8158 11.9646L20.2454 17.6918H13.5674H13.5711Z"
      fill="url(#paint2_radial_2067_37955)"
    />
    <path d="M16.1643 30.7372V19.2014H6.65967L16.1643 30.7372Z" fill="url(#paint3_radial_2067_37955)" />
    <path
      d="M12.5423 17.692L15.8498 11.9019C14.5105 11.9315 11.4952 11.9278 10.1522 11.9019L6.37109 17.6957H12.5423V17.692Z"
      fill="url(#paint4_radial_2067_37955)"
    />
    <defs>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(25.8615 25.1987) scale(11.8022)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_2067_37955"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(25.8023 9.21584) scale(12.4422 12.4422)"
        gradientUnits="userSpaceOnUse"
        id="paint1_radial_2067_37955"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(17.6593 12.0904) scale(12.3757 12.3756)"
        gradientUnits="userSpaceOnUse"
        id="paint2_radial_2067_37955"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(11.4101 24.9693) scale(7.64367)"
        gradientUnits="userSpaceOnUse"
        id="paint3_radial_2067_37955"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(8.54284 10.5995) scale(9.43435 9.43435)"
        gradientUnits="userSpaceOnUse"
        id="paint4_radial_2067_37955"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
    </defs>
  </svg>
);

export default Vip;
