export const mockBadgeData = [
  {
    title: 'Login Legend', // database,
    type: 'login_streak',
    badgeData: {
      textLocked: 'Consistency is key! Log in each week to keep your streak alive and unlock the next badge.',
      textUnlocked:
        'Consistency is key! Log into the platform each week to keep your streak alive. The longer the streak, the bigger the badge.',
      style: {
        glowColorCode: '#00b000',
      },
      ctaUrl: '',
      ctaText: 'View your progress',
      progressive: true, // false
    },
  },
  {
    title: 'Summit Road', // database,
    type: 'payout_milestone',
    badgeData: {
      textLocked: 'Increase your all-time earnings to unlock the next badge.',
      textUnlocked: 'Congratulations! You’ve reached the $%s all-time payout milestone. Keep up the fantastic work!',
      style: {
        glowColorCode: '#00b000',
      },
      ctaUrl: '/offers',
      ctaText: 'Select an offer',
      progressive: true, // false
    },
  },
  {
    title: 'Aspiring Ace', // database,
    type: 'rank', // database,
    level: 1, // database,
    badgeData: {
      textLocked:
        'Increase your earnings to improve your ranking and reach the Rising Star category (Top 400) by the end of the year',
      textUnlocked:
        'Attaboy! You’ve ended the last quarter as a Rising Star Affiliate (Top 400). May your new perks help you ascend to the VIP level.',
      style: {
        glowColorCode: '#EAEAEA',
      },
      ctaUrl: 'https://www.crakrevenue.com/affiliate/#achievement-program',
      ctaText: 'Learn more',
      progressive: false,
    },
  },
  {
    title: 'Power Player', // database,
    type: 'rank', // database,
    level: 2, // database,
    badgeData: {
      textLocked: 'Increase your earnings to improve your ranking and reach the VIP category (Top 200) by the end of the year.',
      textUnlocked:
        'You’re unstoppable! You’ve ended the last quarter as a VIP Affiliate (Top 200). New perks unlocked! Keep doing what you do and you might reach the Elite soon.',
      style: {
        glowColorCode: '#FEF258',
      },
      ctaUrl: 'https://www.crakrevenue.com/affiliate/#achievement-program',
      ctaText: 'Learn more',
      progressive: false,
    },
  },
  {
    title: 'OG Whale', // database,
    type: 'rank', // database,
    level: 3, // database,
    badgeData: {
      textLocked: 'Increase your earnings to improve your ranking and reach the Elite category (Top 50) by the end of the year.',
      textUnlocked:
        'Respect! You’ve ended the last quarter as an Elite Affiliate (Top 50). You are now part of a select club along with Nick, the founder of CrakRevenue. Enjoy all the new perks on the way to your next success!',
      style: {
        glowColorCode: '#01FC00',
      },
      ctaUrl: 'https://www.crakrevenue.com/affiliate/#achievement-program',
      ctaText: 'Learn more',
      progressive: false,
    },
  },
  {
    title: 'Level Up FTW', // database,
    type: 'lvl_up_contest', // database,
    level: 2, // database,
    badgeData: {
      textLocked: 'Join this year’s Level Up contest and win one of the prizes to unlock this badge after the contest ends.',
      textUnlocked:
        'Congratulations! You’ve earned one of the rarest badges. Wear it proudly and stay tuned for the next edition of our Level Up affiliate contest!',
      style: {
        glowColorCode: '#0028DC',
      },
      ctaUrl: 'https://www.crakrevenue.com/contest/',
      ctaText: 'Open contest page',
      progressive: false,
    },
  },
  {
    title: 'Challenge Accepted', // database,
    type: 'lvl_up_contest', // database,
    level: 1, // database,
    badgeData: {
      textLocked: 'Join this year’s Level Up contest and improve your ranking to unlock this badge after the contest ends.',
      textUnlocked:
        'You should be proud of your ranking improvement! You gave it your all, and that’s what counts. Great things are built on challenges. Next time is yours!',
      style: {
        glowColorCode: '#00b000',
      },
      ctaUrl: 'https://www.crakrevenue.com/contest/',
      ctaText: 'Open contest page',
      progressive: false,
    },
  },
  {
    title: 'Review Rock Star', // database,
    type: 'trust_pilot', // database,
    level: 1, // database,
    badgeData: {
      textLocked: (
        <>
          Leave us a{' '}
          <a href="https://ca.trustpilot.com/evaluate/crakrevenue.com?stars=5" rel="noreferrer" target="_blank">
            Trustpilot review
          </a>{' '}
          to unlock this exclusive badge. Then, send us proof of your review.
        </>
      ),
      textUnlocked:
        'Thanks for the love! We are blessed to have you on board and always happy to have your feedback. Let this badge be a token of our gratitude!',
      style: {
        glowColorCode: '# 01FC00',
      },
      ctaUrl: '',
      ctaText: 'Send proof',
      progressive: false,
    },
  },
  {
    title: 'Ka-Ching!', // database,
    type: 'first_payment', // database,
    level: 1, // database,
    badgeData: {
      textLocked: 'You will earn this badge when the status of your first payment is PAID.',
      textUnlocked: 'Way to go! Your first payment is on its way to you. May the next one be even bigger!',
      style: {
        glowColorCode: '#F149FD',
      },
      ctaUrl: '/payment-history',
      ctaText: 'View your payments',
      progressive: false,
    },
  },
  {
    title: 'Trophy Room Hero',
    type: 'beta_tester_trophy_room',
    level: 1,
    badgeData: {
      textLocked: '',
      textUnlocked: 'This badge marks the contribution of Affiliates who volunteered to test our new Achievements section.',
      style: {
        glowColorCode: '#F149FD',
      },
      progressive: false,
      onlyUnlocked: true,
    },
  },
];

export const upcomingBadge = {
  disabled: true,
  badgeData: {
    subtype: 'upcoming',
  },
  type: 'upcoming',
  id: 0,
  title: 'Upcoming Badge',
  isUnlocked: true,
};

export const upcomingBadgeProgressive = {
  disabled: true,
  badgeData: {
    subtype: 'upcoming_progressive',
  },
  type: 'upcoming',
  id: 0,
  title: 'Upcoming Badge',
  isUnlocked: true,
};
