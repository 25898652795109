import { mapToSignupQuestion } from '../pages/access/BusinessSignupFlow/businessSignupFormHelper';
import consts from '../utils/consts';

const {
  ACTIONS: { SSIGN_UP },
} = consts;

const initialState = {
  saved: false,
  isFetching: false,
  err: null,
  signupToken: null,
  userInfo: {},
  hoQuestions: {},
  hoQuestionsIsFetching: false,
  countryIsFetching: false,
  countryCode: null,
};

export const signup = (state = initialState, action = {}) => {
  switch (action.type) {
    case SSIGN_UP.REQUEST_VALIDATE_USER:
    case SSIGN_UP.REQUEST_CREATE_LEAD:
    case SSIGN_UP.REQUEST_CREATE_USER:
      return {
        ...state,
        isFetching: true,
        err: null,
      };
    case SSIGN_UP.REQUEST_GEO_COUNTRY:
      return {
        ...state,
        countryIsFetching: true,
      };
    case SSIGN_UP.RECEIVE_GEO_COUNTRY:
      return {
        ...state,
        countryCode: action.data?.country,
        countryIsFetching: false,
      };
    case SSIGN_UP.FAILED_TO_FETCH_GEO_COUNTRY:
      return {
        ...state,
        countryIsFetching: false,
      };
    case SSIGN_UP.RECEIVE_CREATE_LEAD:
    case SSIGN_UP.RECEIVE_CREATE_USER:
      return {
        ...state,
        saved: true,
        isFetching: false,
        err: null,
      };

    case SSIGN_UP.RECEIVED_VALIDATE_USER:
      return {
        ...state,
        isFetching: false,
        err: null,
        userInfo: {
          ...action.data,
        },
      };

    case SSIGN_UP.FAILED_VALIDATE_USER:
    case SSIGN_UP.FAILED_TO_CREATE_LEAD:
    case SSIGN_UP.FAILED_TO_CREATE_USER:
      return {
        ...state,
        isFetching: false,
        err: action.err,
      };

    case SSIGN_UP.SET_SIGNUP_TOKEN:
      return {
        ...state,
        signupToken: action.data,
      };

    case SSIGN_UP.REQUEST_HO_QUESTIONS: {
      return {
        ...state,
        hoQuestionsIsFetching: true,
      };
    }

    case SSIGN_UP.RECEIVE_HO_QUESTIONS: {
      let questions = action.data;
      if (action?.data?.signupQuestionKeys?.length) {
        questions = {
          name: action.data?.name,
          signupQuestionKeys: action.data.signupQuestionKeys.map((el) => mapToSignupQuestion(el, action.data?.name)),
        };
      }
      return {
        ...state,
        hoQuestionsIsFetching: false,
        hoQuestions: questions,
      };
    }
    case SSIGN_UP.FAILED_TO_FETCH_HO_QUESTIONS: {
      return {
        ...state,
        hoQuestionsIsFetching: false,
      };
    }

    default:
      return state;
  }
};
