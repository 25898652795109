import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputText } from '../../../components/reusables';
import SelectCustom from '../../../components/reusables/form/SelectCustom';

const StatisticsSecondaryFilters = ({
  browsersList,
  categories,
  fields,
  goalsList,
  intl,
  isDisabled,
  statisticsSelectedFilters,
}) => {
  const payoutList = [
    { value: 'cpa_flat', text: 'CPA' },
    { value: 'cpa_both', text: 'CPA + REV' },
    { value: 'cpa_percentage', text: 'REV' },
  ];
  return (
    <div className="secondaryFilters">
      {statisticsSelectedFilters.filterGoals ? (
        <div className="filterInput">
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                dataCategory="Goal"
                dataFiltername="Goal.id"
                disabled={isDisabled}
                id="goal"
                isMulti
                label={messages.genericTextGoals.description}
                maxSelected={goalsList.length}
                onChange={fields.goals.onChange}
                options={goalsList.map((goal) => ({ value: goal.id, text: goal.name }))}
                showMaxSelectedNumber={false}
                showSelectedAtTop={false}
                showSelectedFieldCheckmark={false}
                tabIndex={70}
                value={fields.goals.value}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {statisticsSelectedFilters.filterNiche ? (
        <div className="filterInput">
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                dataCategory="Niche"
                dataFiltername="OfferCategory.id"
                disabled={isDisabled}
                id="niche"
                isMulti
                label={messages.genericTextNiche.description}
                maxSelected={categories.filter((category) => category.name.indexOf('Niche') === 0).length}
                onChange={fields.niche.onChange}
                options={categories
                  .filter((category) => category.name.indexOf('Niche') === 0)
                  .map((category) => ({ value: category.id, text: category.name }))}
                showMaxSelectedNumber={false}
                showSelectedAtTop={false}
                showSelectedFieldCheckmark={false}
                tabIndex={80}
                value={fields.niche.value}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {statisticsSelectedFilters.filterBrowser ? (
        <div className="filterInput">
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                dataCategory="Device"
                dataFiltername="Browser.id"
                disabled={isDisabled}
                id="browser"
                isMulti
                label={messages.statisticsColumns['Browser.display_name'].description}
                maxSelected={browsersList.length}
                onChange={fields.browser.onChange}
                options={browsersList.map((browser) => ({ value: browser.id, text: browser.name }))}
                showMaxSelectedNumber={false}
                showSelectedAtTop={false}
                showSelectedFieldCheckmark={false}
                tabIndex={90}
                value={fields.browser.value}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {statisticsSelectedFilters.filterPayoutType ? (
        <div className="filterInput">
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                dataCategory="Payout Type"
                dataFiltername="Stat.payout_type"
                disabled={isDisabled}
                id="payoutType"
                isMulti
                label={messages.statisticsColumns['Stat.payout_type'].description}
                maxSelected={payoutList.length}
                onChange={fields.payoutType.onChange}
                options={payoutList.map((payout) => ({ value: payout.value, text: payout.text }))}
                showMaxSelectedNumber={false}
                showSelectedAtTop={false}
                showSelectedFieldCheckmark={false}
                tabIndex={100}
                value={fields.payoutType.value}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {statisticsSelectedFilters.filterSubId1 ? (
        <InputText
          category="Sub ID 1"
          className="filterInputText"
          compare="like"
          field={fields.subId1}
          filtername="Stat.affiliate_info1"
          id="subId1"
          label={messages.statisticsColumns['Stat.affiliate_info1']}
          type="text"
        />
      ) : null}
      {statisticsSelectedFilters.filterSubId2 ? (
        <InputText
          category="Sub ID 2"
          className="filterInputText"
          compare="like"
          field={fields.subId2}
          filtername="Stat.affiliate_info2"
          id="subId2"
          label={messages.statisticsColumns['Stat.affiliate_info2']}
          type="text"
        />
      ) : null}
      {statisticsSelectedFilters.filterSubId3 ? (
        <InputText
          category="Sub ID 3"
          className="filterInputText"
          compare="like"
          field={fields.subId3}
          filtername="Stat.affiliate_info3"
          id="subId3"
          label={messages.statisticsColumns['Stat.affiliate_info3']}
          type="text"
        />
      ) : null}
      {statisticsSelectedFilters.filterSubId4 ? (
        <InputText
          category="Sub ID 4"
          className="filterInputText"
          compare="like"
          field={fields.subId4}
          filtername="Stat.affiliate_info4"
          id="subId4"
          label={messages.statisticsColumns['Stat.affiliate_info4']}
          type="text"
        />
      ) : null}
      {statisticsSelectedFilters.filterSubId5 ? (
        <InputText
          category="Sub ID 5"
          className="filterInputText"
          compare="like"
          field={fields.subId5}
          filtername="Stat.affiliate_info5"
          id="subId5"
          label={messages.statisticsColumns['Stat.affiliate_info5']}
          type="text"
        />
      ) : null}
      {statisticsSelectedFilters.filterSource ? (
        <InputText
          category="Source"
          className="filterInputText"
          compare="like"
          field={fields.source}
          filtername="Stat.source"
          id="source"
          label={messages.statisticsColumns['Stat.source']}
          type="text"
        />
      ) : null}
    </div>
  );
};

StatisticsSecondaryFilters.propTypes = {
  browsersList: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  goalsList: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  statisticsSelectedFilters: PropTypes.object.isRequired,
};

export default injectIntl(StatisticsSecondaryFilters);
