function SignupQuestion(key, parameters) {
  this.key = key;
  this.parameters = parameters;
}

const addOptionalToAnythingToAddTier1 = (signupQuestion, questionName) => {
  if (questionName === process.env.REACT_APP_QUESTION_TIER1_FORM_NAME && signupQuestion?.key === 'anythingToAdd') {
    signupQuestion.parameters = { ...signupQuestion.parameters, optional: true };
  }
};

export const mapToSignupQuestion = (signupQuestionElement, questionFormName) => {
  let signupQuestion = undefined;
  if (typeof signupQuestionElement === 'string') {
    signupQuestion = {
      key: signupQuestionElement,
      parameters: {},
    };
  } else if (signupQuestionElement instanceof SignupQuestion) {
    signupQuestion = signupQuestionElement;
  }
  if (signupQuestion) {
    addOptionalToAnythingToAddTier1(signupQuestion, questionFormName);
  }
  return signupQuestion;
};

export const findSignupQuestion = (signupQuestionKeys, key) => {
  if (signupQuestionKeys && signupQuestionKeys.length) {
    return signupQuestionKeys.find((el) => el.key === key);
  }
};

export const addSignupQuestion = (signupQuestionKeys, signupQuestion) => {
  if (signupQuestionKeys) {
    if (!findSignupQuestion(signupQuestionKeys, signupQuestion.key)) {
      if (signupQuestionKeys[0] instanceof String) {
        signupQuestionKeys = [signupQuestion.key, ...signupQuestionKeys];
      } else if (signupQuestionKeys[0] instanceof SignupQuestion) {
        signupQuestionKeys = [signupQuestion, ...signupQuestionKeys];
      }
    }
  }
};

export const addLastNameQuestion = (signupQuestionKeys) => {
  addSignupQuestion(signupQuestionKeys, { key: 'lastName', parameters: {} });
};

export const addAffiliateTypeQuestion = (signupQuestionKeys) => {
  addSignupQuestion(signupQuestionKeys, { key: 'affiliateType', parameters: {} });
};
