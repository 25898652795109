const RisingStar = () => (
  <svg fill="none" height="45" viewBox="0 0 35 45" width="35" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 9.87832V33.3162L17.1113 43.1982L34.2226 33.3162V9.87832L17.1113 0L0 9.87832Z" fill="#011800" />
    <path
      d="M17.1112 37.6816L1.59131 28.7209V10.7995L17.1112 1.83883L32.6311 10.7995V28.7209L17.1112 37.6816Z"
      stroke="#00BD00"
    />
    <path d="M0 33.3162L17.1113 43.1982L34.2226 33.3162V35.1216L17.1113 45L0 35.1216V33.3162Z" fill="#00BD00" />
    <path
      clipRule="evenodd"
      d="M16.7363 7L19.4556 15.3688H28.2536V16.956L21.5533 21.8249L23.8546 28.9062V30.4971L16.7363 25.3248L9.62165 30.4971V28.9062L11.9229 21.8249L5.22266 16.956V15.3688H14.0207L16.7363 7Z"
      fill="#000F11"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.7363 7L19.4556 15.3688H28.2536L21.1353 20.5411L23.8546 28.9062L16.7363 23.7376L9.62165 28.9062L12.3373 20.5411L5.22266 15.3688H14.0207L16.7363 7Z"
      fill="url(#paint0_radial_2067_37737)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.7363 7L14.017 15.3688H5.22266L12.341 20.5411L11.6047 22.8016C11.6047 22.8016 17.9239 15.3688 28.2536 15.3688H19.4556L16.7363 7Z"
      fill="url(#paint1_radial_2067_37737)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'screen' }}
    />
    <path
      clipRule="evenodd"
      d="M28.2535 15.3689L20.78 19.705L22.3635 26.9787L16.4291 22.8017L9.62158 28.9063L16.7362 23.7377L23.8545 28.9063L21.1352 20.5411L28.2535 15.3689Z"
      fill="url(#paint2_radial_2067_37737)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      clipRule="evenodd"
      d="M16.7365 7.99146L14.3539 15.6832H8.10498L15.2159 16.8264L16.7365 7.99146Z"
      fill="url(#paint3_radial_2067_37737)"
      fillRule="evenodd"
      style={{ mixBlendMode: 'screen' }}
    />
    <defs>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(7.24272 9.61572) scale(24.7439)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_2067_37737"
        r="1"
      >
        <stop stopColor="#97CE41" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(10.2025 6.46354) scale(19.7677 19.7677)"
        gradientUnits="userSpaceOnUse"
        id="paint1_radial_2067_37737"
        r="1"
      >
        <stop />
        <stop offset="1" stopColor="#9DBF43" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.8213 15.4133) scale(16.1864)"
        gradientUnits="userSpaceOnUse"
        id="paint2_radial_2067_37737"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.11" stopColor="#FEFCF9" />
        <stop offset="0.26" stopColor="#FDF4E9" />
        <stop offset="0.42" stopColor="#FBE8CE" />
        <stop offset="0.6" stopColor="#F8D6A9" />
        <stop offset="0.78" stopColor="#F4BF7A" />
        <stop offset="0.98" stopColor="#F0A440" />
        <stop offset="1" stopColor="#F0A139" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(11.4422 8.02105) scale(9.40475)"
        gradientUnits="userSpaceOnUse"
        id="paint3_radial_2067_37737"
        r="1"
      >
        <stop />
        <stop offset="1" stopColor="#9DBF43" />
      </radialGradient>
    </defs>
  </svg>
);

export default RisingStar;
